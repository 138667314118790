import {DashboardComponent} from "../components/dashboard/Dashboard";
import React, {useEffect, useState} from "react";
import {Task} from "../../functions/src/RequestTypes";
import {nationalTemplate} from "./nationalTemplate";
import {ElaboratedTask, elaborateTasks} from "../model/Tasks";
import {ToggleTasksDesiredEndState} from "../model/RequestTypes";
import {Button, Modal} from "react-bootstrap";
import "./page.css";
import {Link} from "react-router-dom";
import {logEvent} from "firebase/analytics";
import {googleAnalytics} from "../model/firebaseConnection";
import Joyride, {CallBackProps} from "react-joyride";
import {TaskTemplate} from "../model/databaseSchema";
import {DemoCTASubscribeNow} from "./DemoCTASubscribeNow";
import {DemoCTAStress} from "./DemoCTAStress";

const steps = [
    {
        target: '.joyride-dashboard-first-step',
        content: 'We collapse tasks that are complete or due in the future to reduce visual clutter, expand to see them.',
    },
    {
        target: '.joyride-dashboard-second-step',
        content: 'You can add tasks to customise the dashboard for your practice.',
    },
    {
        target: '.joyride-dashboard-third-step-foo',
        content: "We've identified many common tasks to get you started, and you can customise them during your simple onboarding.",
    },
    {
        target: '.joyride-dashboard-forth-step',
        content: 'You can mark tasks as complete with a single click.',
    },
];


export const DemoPage = () => {

    const [tasksAddedDuringDemo, setTasksAddedDuringDemo] = useState(false);

    const taskTemplates : TaskTemplate[] = nationalTemplate.subjects.map((subject) => {
        return subject.fixedTasks.concat(subject.userInputTasks).map((task) => {
            if (task.id === 'semiAnnualFireExtinguisher2' && task.type === 'recurring' && task.frequency.type === 'yearly') {
                return {
                    ...task,
                    frequency: {
                        ...task.frequency,
                        month: (new Date().getMonth() - 1),
                        day: Math.max(new Date().getDate(), 27)
                    },
                    tags: task.tags ?? [subject.name],

                }
            }
            return {
                ...task,
                tags: task.tags ?? (subject.name !== 'Demo' ? [subject.name] : []),
            }
        });
    }).flat();

    const convertedTasks: Task[] = taskTemplates.map((task) => {
        if (task.type === 'oneOff') {
            return {
                id: task.id,
                name: task.name,
                details: task.details,
                dueDate: task.dueDate,
                type: task.type,
                tags: task.tags,
                owner: Math.random() > 0.5 ? 'practice.manager@nhs.net' : 'deputy.manager@nhs.net'
            }
        } else {
            return {
                id: task.id,
                name: task.name,
                details: task.details,
                frequency: task.frequency,
                type: task.type,
                tags: task.tags,
                owner: Math.random() > 0.5 ? 'practice.manager@nhs.net' : 'deputy.manager@nhs.net'
            }
        }
    });

    //const tasks = elaborateTasks(convertedTasks, new Date());

    const today = new Date();

    const elaboratedTasks = elaborateTasks(convertedTasks, today);

    for (const task of elaboratedTasks) {
        if (task.dueDate < today && task.task.id !== 'semiAnnualFireExtinguisher2') {
            if (task.task.type === 'oneOff') {
                task.task.completionDate = today.getTime();
            } else {
                task.task.lastCompleted = today.getTime();
            }
        }
    }


    const [tasks, setTasks] = useState<ElaboratedTask[]>(elaborateTasks(convertedTasks, today));

    const addTasks = (newTasks: Task[]) => {
        setTasks([...tasks, ...elaborateTasks(newTasks, today)]);
    }

    const editTask = (task: Task) => {
        const newTasks = tasks.map((t) => {
            if (t.task.id === task.id) {
                return {
                    ...t,
                    task: task,
                    name: task.name,
                    details: task.details

                }
            } else {
                return t;
            }
        });
        setTasks(newTasks);
    }

    const handleMarkTasksComplete = (tasksToMark: Task[], desiredEndState: ToggleTasksDesiredEndState) => {

        const unWrappedTasks = tasks.map((t) => t.task);
        const newUnwrappedTasks = unWrappedTasks.map((task) => {
            if (tasksToMark.find((t) => t.id === task.id)) {
                if (task.type === 'oneOff') {
                    return {
                        ...task,
                        completionDate: desiredEndState === 'complete' ? today.getTime() : undefined
                    }
                } else {
                    return {
                        ...task,
                        lastCompleted: desiredEndState === 'complete' ? today.getTime() : undefined
                    }
                }
            } else {
                return task;
            }
        });

        setTasks(elaborateTasks(newUnwrappedTasks, today));
    }

    const handleMarkTaskDeleted = (taskToDelete: Task) => {
        const newTasks = tasks.filter((t) => t.task.id !== taskToDelete.id);
        setTasks(newTasks);
    }

    const logEventInternal = (name: string, params: any) => {
        console.log(name, params);
    }

    const generateTaskSuggestions = async (prompt: string | null, base64String: {
        data: string;
        mimeType: string
    } | null) => {
        console.log(prompt, base64String);
    }

    const handleJoyrideCallback = (data: CallBackProps) => {
        const {index, lifecycle} = data;
        if (lifecycle === 'complete' && index === 1 && !tasksAddedDuringDemo) {
            setTimeout(() => {
                addTasks([{
                    id: 'new-task-1',
                    type: 'oneOff',
                    name: "Christmas gift vouchers for staff",
                    dueDate: new Date().getTime() + 1000 * 60 * 60 * 24
                }, {
                    id: 'new-task-2',
                    type: 'oneOff',
                    name: "Order more spill kits",
                    dueDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 2
                }])
                setTasksAddedDuringDemo(true);
            }, 10);

        }

        if(lifecycle === 'complete'){
            logEvent(googleAnalytics, 'joyride_complete', {index});
        }
    };

    const users = ['practice.manager@nhs.net', 'deputy.manager@nhs.net']

    return <>
        <Joyride
            run={true}
            steps={steps}
            callback={handleJoyrideCallback}
            styles={{
                options: {
                    arrowColor: '#019b91',
                    backgroundColor: '#019b91',
                    overlayColor: 'rgb(74,74,74)',
                    primaryColor: '#5919C1',
                    textColor: '#ffffff',
                    width: 400,
                    zIndex: 1000,
                },
            }}
        />

        <DashboardComponent tasks={tasks}
                            taskSuggestions={[]}
                            error={undefined}
                            practiceName={"Demo Practice"}
                            addTasks={addTasks}
                            today={today}
                            changeTasksCompletionState={handleMarkTasksComplete}
                            editTask={editTask}
                            deleteTask={handleMarkTaskDeleted}
                            logEventInternal={logEventInternal}
                            generateTaskSuggestions={generateTaskSuggestions}
                            dynamicTaskSuggestions={[]}
                            loading={false}
                            isFoundingCustomer={false}
                            hideNoPatientDataAlert
                            users={users}
        />
        <DemoCTAStress timeout={30000}/>

    </>
}