import {
    BlockTypeSelect, BoldItalicUnderlineToggles,
    headingsPlugin, InsertTable,
    listsPlugin, ListsToggle,
    MDXEditor,
    quotePlugin, tablePlugin, thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo
} from "@mdxeditor/editor";
import '@mdxeditor/editor/style.css'

import "./MarkDownEditor.css"

export type MarkDownEditorWithPDFDownloadProps = {
    markdownContent: string
    setMarkdownContent: (md: string) => void
}

export const MarkDownEditor = ({
                                                  markdownContent,
                                                  setMarkdownContent,
                                              }: MarkDownEditorWithPDFDownloadProps) => {

    return <div className={'w-100'}>
        <MDXEditor markdown={markdownContent}
                   className={'ds-markdown-editor'}
                   contentEditableClassName={'ds-markdown-editor-content'}
                   plugins={[headingsPlugin(),
                       listsPlugin(),
                       quotePlugin(),
                       tablePlugin(),
                       thematicBreakPlugin(),
                       toolbarPlugin({
                       toolbarContents: () => (
                           <>
                               {' '}
                               <UndoRedo/>
                               <BlockTypeSelect/>
                               <BoldItalicUnderlineToggles/>
                               <ListsToggle/>
                               <InsertTable />
                           </>
                       )
                   })]}
                   onChange={setMarkdownContent}/>

    </div>
}