import {Col, Container, Row} from "react-bootstrap";
import {AddTasksRequest, AddTasksResponse, OnboardingSubject, Task} from "../../functions/src/RequestTypes";
import {TaskTemplateWithAssignee} from "../onboarding/TaskTemplateWithAssignee";
import {PracticeUsersLoadable, usePracticeUsers} from "../model/PracticeUsers";
import {useNavigate, useParams} from "react-router-dom";
import {cqcAuditTemplate} from "./AuditTemplate";
import {callAPI} from "../model/API";
import {OnboardingSubjectComponent} from "../onboarding/OnboardingSubjectComponent";

type CQCAuditSectionPageComponentProps = {
    subject: OnboardingSubject,
    addTasks: (tasks: TaskTemplateWithAssignee[]) => Promise<void>,
    next: () => void,
    practiceUsers: PracticeUsersLoadable
}

const CQCAuditSectionPageComponent = ({subject, addTasks, next, practiceUsers}: CQCAuditSectionPageComponentProps) => {
    return <Container className={'mt-3 mb-5'}>
        <Row>
            <Col>
                <OnboardingSubjectComponent subject={subject} addTasks={addTasks} next={next}
                                            practiceUsers={practiceUsers}
                                            defaultTaskFrequency={'oneOff'}
                />
            </Col>
        </Row>
    </Container>
}


export const CQCAuditSectionPage = () => {

    const [practiceUsers] = usePracticeUsers();
    const subjectName = useParams<{ subjectName: string }>().subjectName;
    const navigate = useNavigate();

    if (!subjectName) {
        return <div>Subject not found</div>
    }

    const subject = cqcAuditTemplate.subjects.find(s => s.name === subjectName);

    if (!subject) {
        return <div>Subject not found</div>
    }

    const addTasks = async (tasks: Task[]) => {
        await callAPI<AddTasksRequest, AddTasksResponse>({type: 'addTasks', tasks});
    }

    const addTaskTemplates = async (taskTemplates: TaskTemplateWithAssignee[]) => {
        const convertedTasks: Task[] = taskTemplates.map((task) => {
            if (task.type === 'oneOff') {
                return {
                    id: task.id,
                    name: task.name,
                    details: task.details,
                    dueDate: task.dueDate,
                    type: task.type,
                    tags: [subjectName],
                    owner: task.assignee
                }
            } else {
                return {
                    id: task.id,
                    name: task.name,
                    details: task.details,
                    frequency: task.frequency,
                    type: task.type,
                    tags: [subjectName],
                    owner: task.assignee
                }
            }
        });

        await addTasks(convertedTasks);
    }

    const templateSubject : OnboardingSubject = {
        ...subject,
        completed: false
    }


    return <CQCAuditSectionPageComponent practiceUsers={practiceUsers} next={() => navigate("/cqc/imminent-inspection")}
                                         addTasks={addTaskTemplates} subject={templateSubject}/>
}