import {httpsCallable} from "firebase/functions";
import {functions} from "./firebaseConnection";
import {APIRequest, APIResponse} from "./RequestTypes";


export const callAPI =
    async <RequestType extends APIRequest, ResponseType extends APIResponse>
    (data: RequestType) => {
        const apiFunc = httpsCallable<RequestType, ResponseType>(functions, 'internalAPI');
        const response = await apiFunc(data);
        if (response.data.type === 'error') {
            throw new Error(response.data.error);
        }
        if (response.data.type !== data.type) {
            throw new Error('Unexpected response from server');
        }
        return response.data;
    }
