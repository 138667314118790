import {LoadableValue} from "../model/LoadableValue";
import {AvailableReferral, PracticeReferral} from "../../functions/src/databaseSchema";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {useState} from "react";
import * as yup from "yup";
import "./Referrals.css";
import {Formik} from "formik";

const ReferralForm = ({onSubmit}: { onSubmit: (email: string) => void }) => {
    const schema = yup.object().shape({
        email: yup.string().email().required()
    });

    return (
        <Formik initialValues={{
            email: ''
        }} validationSchema={schema}
                onSubmit={(values) => onSubmit(values.email)}>
            {({values, handleChange, handleSubmit, errors}) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name='email' value={values.email} onChange={handleChange} isInvalid={!!errors.email}/>
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Button className={"mt-2"} onClick={() => handleSubmit()}>Refer</Button>
                </Form>
            )}
        </Formik>
    )
}


export type ReferralsProps = {
    referrals: LoadableValue<{
        availableReferrals: AvailableReferral[]
        referralsMade: PracticeReferral[]
    }>
    referAFriend: (email: string, referralType: string) => void
}

export const Referrals = ({referrals, referAFriend}: ReferralsProps) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedRefereralType, setSelectedReferalType] = useState<string | undefined>(undefined);

    const availableReferrals: { description: string, count: number, referralType: string }[] =
        referrals.type === 'loaded' ? Object.values(referrals.availableReferrals.reduce((acc, referral) => {
            if (acc[referral.description]) {
                acc[referral.description].count++;
            } else {
                acc[referral.description] = {description: referral.description, count: 1, referralType: referral.type};
            }
            return acc;
        }, {} as { [key: string]: { description: string, count: number, referralType: string } })) : [];


    return (
        <div>
            {referrals.type === "loading" ? <div>Loading...</div> : referrals.type === "error" ?
                <div>Error loading referrals</div> :
                <div>
                    <h3 className={''}>Available Referrals</h3>
                    <Table>
                        <thead>
                        <tr>
                            <th>Description</th>
                            <th>Count</th>
                            <th>Refer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {availableReferrals.map((referral, index) => (
                            <tr key={index}>
                                <td>{referral.description}</td>
                                <td>{referral.count}</td>
                                <td>
                                    <Button onClick={() => {
                                        setSelectedReferalType(referral.referralType)
                                        setShowModal(true)
                                    }}>Refer</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <h3>Referrals Made</h3>
                    <ul>
                        {referrals.referralsMade.map((referral, index) => (
                            <li key={index}>
                                {referral.email}
                            </li>
                        ))}
                    </ul>
                </div>
            }
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Refer a practice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReferralForm onSubmit={(email) => {
                        setShowModal(false);
                        referAFriend(email, selectedRefereralType!);
                    }}/>
                </Modal.Body>
            </Modal>
        </div>

    )
}