import {Link, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import {applyActionCode} from "firebase/auth";
import {auth} from "../../model/firebaseConnection";

type EmailVerificationAuthOperationProps = {
    verificationState: undefined | 'success' | string,
    oobCodeUsed: string,
    continueUrl: string
}


const EmailVerificationAuthOperationComponent = ({verificationState, oobCodeUsed, continueUrl} : EmailVerificationAuthOperationProps) => {

    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
            <Col xs={12} md={10} lg={8} xl={6}>
                {verificationState && verificationState !== 'success' &&
                    <Alert variant={"danger"}>
                        {verificationState}
                    </Alert>
                }
            </Col>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
        </Row>
        <Row>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
            <Col xs={12} md={10} lg={8} xl={6}>
                <div className={'ds-panel'}>
                    {verificationState === 'success' ?
                        <>
                            <h2 className={'ds-panel-header'}>Email Verified</h2>
                            <p>Your email has been verified successfully.</p>
                            <Link to={continueUrl}><Button>Continue</Button></Link>
                        </>
                        : verificationState ?
                            <>
                                <h2 className={'ds-panel-header'}>Email Verification Failed</h2>
                                <p>{verificationState}</p>
                                <p>Please contact support on contact@mypracticemanager.co.uk</p>
                                <p>OOBCode: {oobCodeUsed}</p>
                            </>
                            :
                            <>
                                <h2 className={'ds-panel-header'}>Email Verification</h2>
                                <p>Verifying your email...</p>
                            </>
                    }
                </div>
            </Col>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
        </Row>
    </Container>
}


export const VerifyEmailAuthOperation = () => {
    const [searchParams] = useSearchParams();
    const oobCode = searchParams.get('oobCode');
    const continueUrl = searchParams.get('continueUrl') ?? '/';

    const [verificationState, setVerificationState] = useState<undefined | 'success' | string>(undefined);
    const [ooCodeUsed, setOobCodeUsed] = useState<string>('');

    useEffect(() => {
        const f = async () => {
            try {
                setOobCodeUsed(oobCode ?? '');
                if(!auth.currentUser?.emailVerified)
                {
                    await applyActionCode(auth, oobCode ?? "");
                    auth.currentUser?.reload();
                }
                setVerificationState('success');
            } catch (e) {
                setVerificationState((e as any).message);
            }
        }
        f();
    }, [oobCode]);

    return <EmailVerificationAuthOperationComponent verificationState={verificationState} oobCodeUsed={ooCodeUsed} continueUrl={continueUrl}/>
}