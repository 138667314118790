import {Button, Form, FormGroup, Modal, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import React, {useState} from "react";
import './TaskComponent.css';
import {TaskForm} from "./TaskForm";
import {Task, ToggleTasksDesiredEndState} from "../../model/RequestTypes";
import {ElaboratedTask} from "../../model/Tasks";
import {TaskNotesEditor} from "./TaskNotesEditor";

type TaskOwnerProps = {
    ownerEmail?: string
    changeOwnership: (newOwner: string | undefined) => void
    users: string[]
}

// task owner with the same popout mechanism as task tags
const TaskOwner = ({ownerEmail, changeOwnership, users}: TaskOwnerProps) => {

    const [showChangePopover, setShowChangePopover] = useState(false);

    const ownerInitials = ownerEmail?.split('@')[0].split('.').map((name) => name[0].toUpperCase()).join('') ?? '';

    const handleChange = (newOwner: string) => {
        if (newOwner !== '') {
            changeOwnership(newOwner)
        } else {
            changeOwnership(undefined);
        }
        setShowChangePopover(false)
    }

    const changeOwnerPopover = (
        <Popover id="change-owner-popover" show={showChangePopover}>
            <Popover.Header
                className={"d-flex flex-row w-100 justify-content-between align-content-center align-items-center"}>Change
                Owner <div><i className={'bi bi-x add-tag-close'} onClick={() => {
                    setShowChangePopover(false);
                }}/></div></Popover.Header>
            <Popover.Body>
                <div className={'d-flex flex-row align-items-center align-content-center'}>
                    <Form.Select onChange={(e) => handleChange(e.currentTarget.value)} value={ownerEmail ?? ''}>
                        {users.map((user) => <option value={user}>{user}</option>)}
                        <option value={''}>None</option>
                    </Form.Select>
                </div>
            </Popover.Body>
        </Popover>);

    return (<OverlayTrigger overlay={changeOwnerPopover} placement={'auto'} show={showChangePopover}>
        <span className={'task-owner'} onClick={() => setShowChangePopover(true)}><i
            className="bi bi-person"></i>{ownerInitials}</span>
    </OverlayTrigger>)
}


type TaskFooterProps = {
    task: ElaboratedTask
    changeTaskCompletionState: (desiredEndState: ToggleTasksDesiredEndState) => void
    editTask: (task: Task) => void
    today: Date
    deleteTask: (task: Task) => void
    logEventInternal: (eventName: string, eventParams: any) => void
    users: string[]
}

const TaskFooter = ({
                        task,
                        changeTaskCompletionState,
                        editTask,
                        today,
                        deleteTask,
                        logEventInternal,
                        users
                    }: TaskFooterProps) => {

    const [showTaskEditor, setShowTaskEditor] = useState(false);
    const [showNotesEditor, setShowNotesEditor] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const setShowNotesEditorWrapper = (show: boolean) => {
        setShowNotesEditor(show);
        logEventInternal('taskNotesEdit', {});
    }

    const editTaskWrapper = (task: Task) => {
        editTask(task);
        setShowTaskEditor(false);
    }

    const saveNotes = (notes: string) => {
        editTask({...task.task, notes: notes});
        setShowNotesEditor(false);
    }

    const deleteTaskWrapper = () => {
        deleteTask(task.task);
        setShowDeleteConfirmation(false);
    }

    const changeTaskOwnership = (newOwner: string | undefined) => {
        task.task.owner = newOwner;
        editTask(task.task);
    }

    const formatter = new Intl.DateTimeFormat('en-GB', {day: '2-digit', month: 'short'});


    return (
        <div className={'task-footer'}>
            <div className={'task-due-date'}>
                {formatter.format(task.dueDate)}
            </div>
            <div className={'task-buttons'}>

                <TaskOwner changeOwnership={changeTaskOwnership} ownerEmail={task.task.owner} users={users}/>
                <i className="bi bi-trash" onClick={() => setShowDeleteConfirmation(true)}></i>
                <i className="bi bi-sticky" onClick={() => setShowNotesEditorWrapper(true)}></i>
                {/*<i className="bi bi-list-check" onClick={() => alert("TODO: implement view audit")}></i>*/}
                <i className="bi bi-pencil-square" onClick={() => setShowTaskEditor(true)}></i>
                {task.taskState === 'complete' ?
                    <i className='bi bi-check-circle-fill task-complete'
                       onClick={() => changeTaskCompletionState("incomplete")}></i> :
                    <i className={'bi bi-check-circle task-not-complete ' + (task.task.type === 'oneOff' ? " joyride-dashboard-forth-step" : "")}
                       onClick={() => changeTaskCompletionState("complete")}></i>}

            </div>
            <Modal show={showTaskEditor} onHide={() => setShowTaskEditor(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TaskForm submitTask={editTaskWrapper} initialTask={task.task} submitText={'Edit Task'}
                              today={today}/>
                </Modal.Body>
            </Modal>
            <Modal show={showNotesEditor} onHide={() => setShowNotesEditor(false)} size={'xl'}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TaskNotesEditor notes={task.task.notes ?? ''} onCancel={() => setShowNotesEditor(false)}
                                     onSave={saveNotes}/>
                </Modal.Body>
            </Modal>
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this task?</p>
                    <div className={'task-delete-confirm'}>
                        <Button variant={'danger'} onClick={deleteTaskWrapper}>Yes</Button>
                        <Button variant={'primary'} onClick={() => setShowDeleteConfirmation(false)}>No</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const TaskDetails = ({task}: { task: ElaboratedTask }) => {
    const formatter = new Intl.DateTimeFormat('en-GB', {day: '2-digit', month: 'short', year: 'numeric'});

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{show: 250, hide: 400}}
            overlay={(props) =>
                <Tooltip id="task-tooltip" {...props}>
                    Due {formatter.format(task.dueDate)}
                </Tooltip>
            }
        >
            <div className={'task-content'}>
                <div className={'task-name'}>{task.task.name}</div>
                <div className={'task-details'}>{task.task.details}</div>
            </div>
        </OverlayTrigger>
    );
}

export type TaskTagProps = {
    tag: string
    deleteTag: (tag: string) => void
}

const TaskTag = ({tag, deleteTag}: TaskTagProps) => {
    return (
        <div className={'task-tag'}>
            {tag}
            <i className={'bi bi-x'} onClick={() => deleteTag(tag)}></i>
        </div>
    )
}

export type TaskTagsProps = {
    tags: string[]
    deleteTag: (tag: string) => void
    addTag: (tag: string) => void
}

const TaskTags = ({tags, deleteTag, addTag}: TaskTagsProps) => {

    const [newTag, setNewTag] = useState('');
    const [showAddPopover, setShowAddPopover] = useState(false);

    const handleAdd = () => {
        if (newTag !== '') {
            addTag(newTag)
        }
        setNewTag("");
        setShowAddPopover(false)
    }

    const popover = (
        <Popover id="add-tag-popover">
            <Popover.Header
                className={"d-flex flex-row w-100 justify-content-between align-content-center align-items-center"}>Add
                Tag <div><i className={'bi bi-x add-tag-close'} onClick={() => {
                    setShowAddPopover(false);
                    setNewTag('');
                }}/></div></Popover.Header>
            <Popover.Body>
                <div className={'d-flex flex-row align-items-center align-content-center'}>
                    <Form.Control type="text" placeholder={'Tag name'} value={newTag}
                                  onChange={e => setNewTag(e.target.value)}
                                  onKeyUp={e => {
                                      if (e.code === "Enter") {
                                          handleAdd()
                                      }
                                  }}
                    />
                    <i className={'bi bi-arrow-return-left add-tag-button'} onClick={handleAdd}></i>
                </div>
            </Popover.Body>
        </Popover>);


    return (
        <div className={'task-tags'}>
            {tags.map((tag) => <TaskTag tag={tag} deleteTag={deleteTag}/>)}

            <OverlayTrigger overlay={popover} placement={'auto'} show={showAddPopover}>
                <i className={'bi bi-plus-square-dotted task-tag-add'} aria-label={'add task tag'}
                   onClick={() => setShowAddPopover(true)}></i>
            </OverlayTrigger>
            {tags.length === 0 && <div className={'task-tag-none'}>add tag</div>}
        </div>
    )
}


export type TaskComponentProps = {
    task: ElaboratedTask
    today: Date
    changeTaskCompletionState: (task: Task, desiredEndState: ToggleTasksDesiredEndState) => void,
    editTask: (task: Task) => void,
    deleteTask: (task: Task) => void,
    logEventInternal: (eventName: string, eventParams: any) => void
    users: string[]
}


export const TaskComponent = ({
                                  task,
                                  today,
                                  changeTaskCompletionState,
                                  editTask,
                                  deleteTask,
                                  logEventInternal,
                                  users
                              }: TaskComponentProps) => {

    const wrappedLogEventInternal = (eventName: string, eventParams: any) => {
        logEventInternal(eventName, {taskType: task.task.type, taskName: task.task.name, ...eventParams});
    }

    const addTag = (newTag: string) => {
        if (task.task?.tags?.includes(newTag)) {
            return;
        }
        editTask({...task.task, tags: [...(task.task.tags ?? []), newTag]});
    }

    const removeTag = (tagToRemove: string) => {
        editTask({...task.task, tags: (task.task.tags ?? []).filter(tag => tag !== tagToRemove)});
    }


    return (
        <div className={'task-card'}>
            <TaskDetails task={task}/>

            <TaskTags tags={task.task.tags ?? []} deleteTag={removeTag} addTag={addTag}/>

            <TaskFooter task={task}
                        changeTaskCompletionState={(desiredEndState) => changeTaskCompletionState(task.task, desiredEndState)}
                        editTask={editTask}
                        today={today}
                        deleteTask={deleteTask}
                        logEventInternal={wrappedLogEventInternal}
                        users={users}
            />
        </div>
    )
}