import {User} from "firebase/auth";
import {auth} from "./firebaseConnection";
import {ListenableValueImpl, useListenableValue} from "./ListenableValue";


export type LoginState =
    | {state: "initialising"}
    | {state: "logged-out"}
    | {state: "unverified", firebaseUser: User}
    | {state: "logged-in", firebaseUser: User}

const loginState = new ListenableValueImpl({state: "initialising"} as LoginState);

let emailVerificationInterval: NodeJS.Timeout | null = null;

const updateLoginState = () => {
    const user = auth.currentUser;
    if (user === null) {
        loginState.set({state: "logged-out"});
        return;
    }

    if (!user.emailVerified) {
        loginState.set({state: "unverified", firebaseUser: user});
        if(emailVerificationInterval === null) {
            emailVerificationInterval = setInterval(() => {
                user.reload().then(() => {
                    if (user.emailVerified) {
                        clearInterval(emailVerificationInterval!);
                        emailVerificationInterval = null;
                        updateLoginState();
                    }
                });
            }, 1000);
        }
        return;
    }

    loginState.set({state: "logged-in", firebaseUser: user});
}

auth.authStateReady().then(() => {
    updateLoginState();
});

auth.onAuthStateChanged(() => {
    updateLoginState();
});

export const useLoginState = () => {
    const ls = useListenableValue(loginState);
    return ls ?? {state: "initialising"};
}


export const isAdministrator = async (user: User) => {
    const token = await user.getIdTokenResult();
    return token.claims.admin === true;
}

export const showInDevelopmentFeature = () => {
    return auth.currentUser?.email === 'tom@mypracticemanager.co.uk' || auth.currentUser?.email?.startsWith("jermaine");
}
