import {Outlet, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";


export const ComplianceLayout = () => {

    const path = useLocation().pathname;

    const pathElements = path.split('/').filter((el) => el !== '');

    return <div className={'w-100'}>
        <Breadcrumb className={'mx-3 mt-1'}>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            {pathElements.length > 0 && <Breadcrumb.Item active={path === '/compliance'} href={'/compliance'}>Compliance</Breadcrumb.Item>}
            {path.startsWith('/compliance/risk-assessment') &&
                <Breadcrumb.Item active={path === '/compliance/risk-assessment'} href={'/compliance/risk-assessment'}>Risk
                    Assessment</Breadcrumb.Item>
            }
            {path.startsWith('/compliance/audit-log') &&
                <Breadcrumb.Item active={path === '/compliance/audit-log'} href={'/compliance/audit-log'}>Audits</Breadcrumb.Item>
            }
            { path.startsWith('/compliance/policy') &&
                <Breadcrumb.Item active={path === '/compliance/policy'} href={'/compliance/policy'}>Policies</Breadcrumb.Item>
            }
        </Breadcrumb>
        <Outlet></Outlet>
    </div>
}