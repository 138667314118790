import {Col, Container, Row} from "react-bootstrap";
import './guidePage.css';
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Markdown from 'react-markdown'
import {getPageContent} from "./storageFunctions";


type GuidePageComponentProps = {
    mdContent: string,
}

const GuidePageComponent = ({mdContent}: GuidePageComponentProps) => {

    return (
        <div className="d-flex flex-column w-100 align-items-center p-3">
            <Container>
                <Row>
                    <Col xs={0} lg={2}/>
                    <Col xs={12} lg={8}>
                        <div className={'ds-panel w-100'}>
                            <Markdown>
                                {mdContent}
                            </Markdown>
                        </div>
                    </Col>
                    <Col xs={0} lg={2}/>
                </Row>
            </Container>
        </div>
    );
}




export function GuidePage() {

    const params = useParams();
    const navigate = useNavigate();
    const guideId = params.guideId;

    const [mdContent, setMdContent] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(!guideId) {
            navigate('/404');
        }
        const fetchContent = async () => {
            const pageContent = await getPageContent(guideId!);
            if (!pageContent) {
                //navigate('/404');
                return
            }
            setMdContent(pageContent!);
        }
        fetchContent();
    }, [guideId, navigate]);

    if(!mdContent) {
        return <div>Loading...</div>
    }


    return <GuidePageComponent mdContent={mdContent}/>
}