import {Outlet, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";


const subRoutes : Record<string, string>= {
    'email': "Emails"
}


export const AssistantLayout = () => {

    const path = useLocation().pathname;

    const pathElements = path.split('/').filter((el) => el !== '');

    return <div className={'w-100'}>
        <Breadcrumb className={'mx-3 mt-1'}>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            {pathElements.length > 0 && <Breadcrumb.Item active={path === '/assistant'} href={'/assistant'}>Assistant</Breadcrumb.Item>}
            {pathElements.length > 1 && pathElements[1] in subRoutes && <Breadcrumb.Item active={path === `/assistant/${pathElements[1]}`} href={`/assistant/${pathElements[1]}`}>{subRoutes[pathElements[1]]}</Breadcrumb.Item>}
        </Breadcrumb>
        <Outlet></Outlet>
    </div>
}