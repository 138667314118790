import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import {Formik} from "formik";
import {verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../model/firebaseConnection";
import {useState} from "react";


type PasswordResetProps = {
    setNewPassword: (password: string) => void
    passwordResetState: undefined | 'success' | string
}

export const PasswordReset = ({setNewPassword, passwordResetState}: PasswordResetProps) => {

    const schema = yup.object().shape({
        password: yup.string().required().min(8),
        rePassword: yup.string().required().min(8).oneOf([yup.ref('password')], 'Passwords must match')
    });


    if(passwordResetState === 'success') {
        return <Container className={'mt-3'}>
            <Row>
                <Col xs={0} md={1} lg={2} xl={3}></Col>
                <Col xs={12} md={10} lg={8} xl={6}>
                    <div className={'ds-panel'}>
                        <h2 className={'ds-panel-header'}>Password Reset</h2>
                        <p>Your password has been reset successfully.</p>
                    </div>
                </Col>
                <Col xs={0} md={1} lg={2} xl={3}></Col>
            </Row>
        </Container>
    }


    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
            <Col xs={12} md={10} lg={8} xl={6}>
                {passwordResetState &&
                    <Alert variant={"danger"}>
                        {passwordResetState}
                    </Alert>
                }
            </Col>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
        </Row>
        <Row>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
            <Col xs={12} md={10} lg={8} xl={6}>
                <div className={'ds-panel'}>
                    <h2 className={'ds-panel-header'}>Reset Password</h2>


                    <Formik
                        validationSchema={schema}
                        validateOnChange={false}
                        onSubmit={(values) => {
                            setNewPassword(values.password);
                        }}
                        initialValues={{
                            password: '',
                            rePassword: ''
                        }}
                    >
                        {({handleSubmit, handleChange, values, touched, errors}) => {
                            return (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="validationFormik02">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isValid={touched.password && !errors.password}
                                            isInvalid={!!errors.password}
                                        />
                                        <Form.Control.Feedback type={'valid'}>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="validationFormik03">
                                        <Form.Label>Re-enter New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="rePassword"
                                            value={values.rePassword}
                                            onChange={handleChange}
                                            isValid={touched.rePassword && !errors.rePassword}
                                            isInvalid={!!errors.rePassword}
                                        />
                                        <Form.Control.Feedback type={'valid'}>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.rePassword}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button type="submit">Update Password</Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </Col>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
        </Row>
    </Container>
}


export const ResetPasswordAuthOperation = () => {
    const navigate  = useNavigate();
    const [searchParams] = useSearchParams();
    const oobCode = searchParams.get('oobCode');

    const [passwordResetState, setPasswordResetState] = useState<undefined | 'success' | string>(undefined);

    const setNewPassword = async (password: string) => {
        try {
            const userEmail = await verifyPasswordResetCode(auth, oobCode ?? "");
            await confirmPasswordReset(auth, oobCode ?? "", password);
            await signInWithEmailAndPassword(auth, userEmail, password);
            navigate('/');
        }
        catch (error) {
            setPasswordResetState((error as any).message ?? 'An error occurred');
        }
    }


    return <PasswordReset setNewPassword={setNewPassword} passwordResetState={passwordResetState}/>
}