import {FormCheck, Form, FormGroup, Col, Row} from "react-bootstrap";
import {PracticeUser, TaskTemplate} from "../model/databaseSchema";
import {daysOfWeek, monthsOfTheYearFirstCap} from "../components/tasks/Dates";
import './TemplateTaskList.css';
import {TaskTemplateWithAssignee} from "./TaskTemplateWithAssignee";
import {PracticeUsersLoadable} from "../model/PracticeUsers";


type TaskCardFrequencyProps = {
    task: TaskTemplate
    editTask: (task: TaskTemplate) => void
}

const TaskCardFrequency = ({task, editTask}: TaskCardFrequencyProps) => {
    if (task.type === 'oneOff') {

        const dueDate = new Date(task.dueDate);

        return <div>
            <FormGroup as={Row}>
                <Form.Label column={true} sm={4}>Due Date</Form.Label>
                <Col sm={8}>
                    <Form.Control type="date" value={new Date(dueDate).toISOString().split('T')[0]} onChange={(e) => {
                        editTask({...task, dueDate: new Date(e.target.value).getTime()});
                    }}/>
                </Col>
            </FormGroup>

        </div>
    } else {
        if (task.frequency.type === 'daily') {
            return <div>Daily</div>
        } else if (task.frequency.type === 'weekly') {
            return <div>
                <div className={'task-template-card-frequency-label'}>Weekly</div>
                <FormGroup as={Row}>
                    <Form.Label column={true} sm={4}>Day of Week</Form.Label>
                    <Col sm={8}>
                        <Form.Control as="select" value={task.frequency.dayOfWeek} onChange={(e) => {
                            editTask({
                                ...task,
                                frequency: {
                                    type: 'weekly',
                                    dayOfWeek: e.target.value as ('sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday')
                                }
                            });
                        }}>
                            {daysOfWeek.map((day) => <option key={day}
                                                             value={day}>{day.replace(/^\w/, c => c.toUpperCase())}</option>)}
                        </Form.Control>
                    </Col>
                </FormGroup>
            </div>
        } else if (task.frequency.type === 'monthly') {
            return <div>
                <FormGroup as={Row}>
                    <Form.Label column={true} sm={4}>Day of Month</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="number" value={task.frequency.day} onChange={(e) => {
                            editTask({...task, frequency: {type: 'monthly', day: parseInt(e.target.value)}});
                        }}/>
                    </Col>
                </FormGroup>
            </div>
        } else if (task.frequency.type === 'yearly') {
            return <div className={''}>
                <FormGroup as={Row}>
                    <Form.Label column={true} sm={4}>Month</Form.Label>
                    <Col sm={8}>

                        <Form.Control as="select" value={task.frequency.month} onChange={(e) => {
                            if (task.frequency.type !== 'yearly') {
                                console.log('Error: Task frequency is not yearly');
                                return;
                            }
                            editTask({...task, frequency: {...task.frequency, month: parseInt(e.target.value)}});
                        }}>
                            {monthsOfTheYearFirstCap.map((month, index) => <option key={month}
                                                                                   value={index}>{month} </option>)}
                        </Form.Control>
                    </Col>
                </FormGroup>
                <FormGroup as={Row} className={'mt-2'}>
                    <Form.Label column={true} sm={4}>Day of Month</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="number" value={task.frequency.day} onChange={(e) => {
                            if (task.frequency.type !== 'yearly') {
                                console.log('Error: Task frequency is not yearly');
                                return;
                            }
                            editTask({
                                ...task,
                                frequency: {type: "yearly", month: task.frequency.month, day: parseInt(e.target.value)}
                            });
                        }}/>
                    </Col>
                </FormGroup>

            </div>
        } else if (task.frequency.type === 'quarterly') {
            return <div>
                <FormGroup as={Row}>
                    <Form.Label column={true} sm={4}>Month</Form.Label>
                    <Col sm={8}>
                        <Form.Control as="select" value={task.frequency.monthNumber} onChange={(e) => {
                            if (task.frequency.type !== 'quarterly') {
                                console.log('Error: Task frequency is not quarterly');
                                return;
                            }
                            editTask({
                                ...task,
                                frequency: {
                                    type: 'quarterly',
                                    monthNumber: parseInt(e.target.value),
                                    day: task.frequency.day
                                }
                            });
                        }}>
                            <option value={0}>First (eg Jan/Apr/Jul/Oct)</option>
                            <option value={1}>Second (eg Feb/May/Aug/Nov)</option>
                            <option value={2}>Third (eg Mar/Jun/Sep/Dec)</option>
                        </Form.Control>
                    </Col>
                </FormGroup>
                <FormGroup as={Row} className={'mt-2'}>
                    <Form.Label column={true} sm={4}>Day of Month</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="number" value={task.frequency.day} onChange={(e) => {
                            if (task.frequency.type !== 'quarterly') {
                                console.log('Error: Task frequency is not quarterly');
                                return;
                            }
                            editTask({
                                ...task,
                                frequency: {
                                    type: 'quarterly',
                                    monthNumber: task.frequency.monthNumber,
                                    day: parseInt(e.target.value)
                                }
                            });
                        }}/>
                    </Col>
                </FormGroup>

            </div>
        } else {
            return <div>Unknown task type</div>
        }
    }
}


export type TemplateTasksListProps = {
    taskTemplates: TaskTemplateWithAssignee[],
    selectTask: (task: TaskTemplate, selected: boolean) => void,
    selectedTasks: Record<string, TaskTemplate>
    editTask: (task: TaskTemplateWithAssignee) => void,
    assignTasksToUser?: string
    practiceUsers: PracticeUsersLoadable
}

export const TemplateTasksList = ({
                                      taskTemplates,
                                      selectTask,
                                      selectedTasks,
                                      editTask,
                                      practiceUsers
                                  }: TemplateTasksListProps) => {

    return (
        <div className={''}>
            {taskTemplates.map(task => {
                    return (
                        <div key={task.id} className={'task-template-card'}>
                            <div className={'w-100'}>
                                <div className={'d-flex flex-row justify-content-between mb-2'}>
                                    <div className={'task-template-card-name'}>{task.name}</div>
                                    <div className={'task-template-card-frequency-label'}>{
                                        task.type === 'oneOff' ? 'One Off' :
                                            task.frequency.type.replace(/^\w/, c => c.toUpperCase())
                                    }</div>
                                </div>
                                <div className={'task-template-card-details'}>{task.details}</div>
                                <div className={'task-template-card-frequency'}>
                                    <TaskCardFrequency task={task} editTask={editTask}/>
                                </div>
                                {practiceUsers.type === 'loaded' && practiceUsers.users.length > 1 &&
                                    <div className={'task-template-card-assignee'}>
                                        <Form.Group as={Row} className={'mt-2'}>
                                            <Form.Label column={true} sm={4}>Assign to:</Form.Label>
                                            <Col sm={8}>
                                                <Form.Control as="select" value={task.assignee} onChange={(e) => {
                                                    editTask({...task, assignee: e.target.value});
                                                }}>
                                                    <option value={''}>Unassigned</option>
                                                    {practiceUsers.users.map(user => <option key={user.email}
                                                                                             value={user.email}>{user.email}</option>)}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </div>}
                            </div>
                            <FormCheck size={50}
                                       type={'checkbox'}
                                       className={'task-template-card-check'}
                                       checked={!!selectedTasks[task.id]}
                                       onChange={(e) => {
                                           selectTask(task, e.target.checked);
                                       }}

                            />
                        </div>
                    )
                }
            )}
        </div>);
}