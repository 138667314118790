import React, {useMemo, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {PracticeNameIdLookup, PracticeNameIdPair} from "../model/databaseSchema";
import './PracticeAutoComplete.css';

// TypeScript interfaces
interface TrieNode {
    children: { [key: string]: TrieNode };
    endOfWord: boolean;
    wordList: string[];
}

class Trie {
    root: TrieNode;

    constructor() {
        this.root = this.createNode();
    }

    private createNode(): TrieNode {
        return {
            children: {},
            endOfWord: false,
            wordList: [],
        };
    }

    insert(word: string): void {
        let node: TrieNode = this.root;
        for (let char of word) {
            if (!node.children[char]) {
                node.children[char] = this.createNode();
            }
            node = node.children[char];
            node.wordList.push(word);
        }
        node.endOfWord = true;
    }

    searchSubstring(substring: string): string[] {
        const normalizedSubstring = normalizeInput(substring);
        const results: Set<string> = new Set();

        // Traverse each node in the trie, looking for any word containing the substring
        const searchTrie = (node: TrieNode, currentWord: string) => {
            if (node.endOfWord && currentWord.includes(normalizedSubstring)) {
                results.add(currentWord);
            }

            for (const [char, childNode] of Object.entries(node.children)) {
                searchTrie(childNode, currentWord + char);
            }
        };

        searchTrie(this.root, '');
        return Array.from(results);
    }
}

// Utility function to normalize input (removes "the", ignores case, etc.)
function normalizeInput(input: string): string {
    return input
        .toLowerCase()
}

// Fuzzy matching (Levenshtein distance)
function levenshtein(a: string, b: string): number {
    const matrix: number[][] = Array.from({length: a.length + 1}, () => Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            matrix[i][j] = Math.min(
                matrix[i - 1][j] + 1, // Deletion
                matrix[i][j - 1] + 1, // Insertion
                matrix[i - 1][j - 1] + cost // Substitution
            );
        }
    }
    return matrix[a.length][b.length];
}

function fuzzyMatch(word: string, candidates: string[], threshold: number = 2): string[] {
    return candidates.filter((candidate) => levenshtein(word, candidate) <= threshold);
}


export type PracticeAutoCompleteProps = {
    practiceNameIdLookup: PracticeNameIdLookup
    practiceSelected: (practice : PracticeNameIdPair) => void
}


export const PracticeAutoComplete = ({practiceNameIdLookup, practiceSelected}: PracticeAutoCompleteProps) => {
    const [input, setInput] = useState<string>('');
    const [suggestions, setSuggestions] = useState<string[]>([]);


    const lookupMap = practiceNameIdLookup.practices.reduce((acc, practice) => {
        const key = normalizeInput(practice.name) + " (" + normalizeInput(practice.id) + ")";
        acc[key] = practice
        return acc;
    }, {} as Record<string, PracticeNameIdPair>);

    const combinedNormalisedNames = Object.keys(lookupMap);

    const practiceTrie = useMemo(() => {
        const trie = new Trie();

        combinedNormalisedNames.forEach((normalizedPractice) => {
            trie.insert(normalizedPractice);
        });
        return trie;
    }, [combinedNormalisedNames]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setInput(inputValue);

        const normalizedInput = normalizeInput(inputValue);
        let newSuggestions = practiceTrie.searchSubstring(normalizedInput);

        if (newSuggestions.length === 0) {
            newSuggestions = fuzzyMatch(normalizedInput, combinedNormalisedNames);
        }

        setSuggestions(newSuggestions.slice(0, 3)); // Show top 3 suggestions
    };

    return (
        <div className={'w-100'}>
            <Form.Control
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Type a practice name"
            />

            <div className={'practice-auto-complete-suggestions'}>
                {suggestions.length > 0 && suggestions.map((suggestion, index) => {

                    const pair = lookupMap[suggestion];

                    return <div className={'practice-auto-complete-suggestion'}
                                key={index}
                    >
                        <div className={'practice-auto-complete-suggestion-details'}>
                            <div>{pair.name}</div>
                            <div>National Code {pair.id}</div>
                        </div>
                        <Button variant={'primary'} onClick={() => practiceSelected(pair)}>Select</Button>
                    </div>
                })}
            </div>
        </div>
    );
};
