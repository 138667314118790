import {Task, ToggleTasksDesiredEndState} from "../../model/RequestTypes";
import {Badge, Button, Form, FormGroup, InputGroup, Modal, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {CommonTasksList} from "./CommonTasksList";
import {TaskForm} from "./TaskForm";
import './TaskColumn.css';
import {TaskComponent} from "./TaskComponent";
import {addDateNumberSuffix, daysOfWeek, getStartOfMonth} from "./Dates";
import InputGroupText from "react-bootstrap/InputGroupText";
import {ElaboratedTask} from "../../model/Tasks";
import {LabelledGroup} from "./LabelledGroup";


type AddTaskModalProps = {
    frequency: 'oneoff' | 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'yearly';
    show: boolean;
    onHide: () => void;
    addTasks: (tasks: Task[]) => void;
    taskSuggestions: Task[];
    today: Date;
    logEventInternal: (eventName: string, eventParams: any) => void;
}

const AddTaskModal = ({
                          frequency,
                          show,
                          onHide,
                          addTasks,
                          taskSuggestions,
                          today,
                          logEventInternal
                      }: AddTaskModalProps) => {


    let initialTask: Task;
    if (frequency === 'oneoff') {
        initialTask = {type: 'oneOff', id: '', name: '', details: '', dueDate: new Date().getTime()};
    } else if (frequency === 'daily') {
        initialTask = {type: 'recurring', id: '', name: '', details: '', frequency: {type: 'daily'}};
    } else if (frequency === 'weekly') {
        initialTask = {
            type: 'recurring',
            id: '',
            name: '',
            details: '',
            frequency: {type: 'weekly', dayOfWeek: 'wednesday'}
        };
    } else if (frequency === 'monthly') {
        initialTask = {type: 'recurring', id: '', name: '', details: '', frequency: {type: 'monthly', day: 15}};
    } else if (frequency === 'quarterly') {
        initialTask = {
            type: 'recurring',
            id: '',
            name: '',
            details: '',
            frequency: {type: 'quarterly', monthNumber: 1, day: 15}
        };
    } else {
        initialTask = {
            type: 'recurring',
            id: '',
            name: '',
            details: '',
            frequency: {type: 'yearly', month: 5, day: 15}
        };
    }

    const addTaskWrapper = (task: Task) => {
        addTasks([{
            ...task,
            id: (new Date()).getTime().toString()
        }])
    }

    return (
        <Modal show={show} onHide={onHide} size={'lg'} contentClassName={'add-task-modal'}>
            <Modal.Header closeButton>
                <Modal.Title>Add Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey={'customTask'}>
                    <Tab title={"Custom Task"} eventKey={"customTask"} className={'custom-task-tab'}>
                        <TaskForm submitTask={addTaskWrapper} initialTask={initialTask} submitText={'Add Task'}
                                  today={today}/>
                    </Tab>
                    <Tab title={<span>Common Tasks<Badge className={'task-column-task-suggestion-available'}
                                                            bg="secondary">{taskSuggestions.length}</Badge></span>}
                         eventKey={'commonTasks'} className={'common-task-list-tab'}>
                        <CommonTasksList taskSuggestions={taskSuggestions} addTasks={addTasks}
                                         logEventInternal={logEventInternal}/>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    )
}

type AdhocTaskQuickAddProps = {
    addTask: (task: Task) => void;
}

const AdhocTaskQuickAdd = ({addTask}: AdhocTaskQuickAddProps) => {

    const [taskName, setTaskName] = useState('');

    const onAddTask = () => {
        addTask({
            type: 'oneOff',
            id: (new Date()).getTime().toString(),
            name: taskName,
            details: '',
            dueDate: new Date().getTime()
        });
        setTaskName('');
    }

    return (
        <div className={'adhoc-task-quick-add joyride-dashboard-second-step'}>
            <FormGroup>
                <Form.Label column={false}>Add Adhoc Task</Form.Label>
                <InputGroup>
                    <Form.Control type={'text'} placeholder={''} value={taskName}
                                  onChange={(e) => setTaskName(e.target.value)}
                                  onKeyUp={(e) => {
                                      e.key === 'Enter' && onAddTask()
                                  }}
                    />
                    <InputGroupText>
                        <i className={'bi bi-chevron-right'} onClick={onAddTask}/>
                    </InputGroupText>
                </InputGroup>
            </FormGroup>
        </div>
    )
}

export type TaskFrequency = 'oneoff' | 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'yearly';


type TaskListProps = {
    tasks: ElaboratedTask[],
    today: Date,
    changeTaskCompletionState: (task: Task, desiredEndState: ToggleTasksDesiredEndState) => void;
    editTask: (task: Task) => void;
    frequency: TaskFrequency;
    deleteTask: (task: Task) => void;
    logEventInternal: (eventName: string, eventParams: any) => void;
    users : string[]
}

const TaskList = ({
                      tasks,
                      today,
                      changeTaskCompletionState,
                      editTask,
                      frequency,
                      deleteTask,
                      logEventInternal,
    users
                  }: TaskListProps) => {

    const wrappedLogEventInternal = logEventInternal;


    if (frequency === 'weekly') {

        const lateTasks = tasks.filter(task => task.taskState === 'late');
        const dueTodayTasks =
            tasks.filter(task => task.dueDate.getDate() === today.getDate())
                .filter(task => task.taskState === 'pending');
        const dueLaterTasks =
            tasks.filter(task => task.dueDate.getDate() > today.getDate())
                .filter(task => task.taskState === 'pending');
        const completedTasks = tasks.filter(task => task.taskState === 'complete');


        return (
            <div className={'task-panel-tasks'}>
                {lateTasks.length > 0 &&
                    <LabelledGroup label={'Late'} className={'task-panel-task-group late'} itemCount={lateTasks.length}
                                   logEventInternal={wrappedLogEventInternal}>
                        {lateTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
                {dueTodayTasks.length > 0 &&
                    <LabelledGroup label={'Due Today'} className={'task-panel-task-group today'}
                                   itemCount={dueTodayTasks.length}
                                   logEventInternal={wrappedLogEventInternal}
                    >
                        {dueTodayTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>
                }
                {dueLaterTasks.length > 0 &&
                    <LabelledGroup label={'Due Later'} className={'task-panel-task-group later'}
                                   itemCount={dueLaterTasks.length} logEventInternal={wrappedLogEventInternal}
                                   collapsed>
                        {dueLaterTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>
                }
                {completedTasks.length > 0 &&
                    <LabelledGroup label={'Completed'} className={'task-panel-task-group completed'}
                                   itemCount={completedTasks.length} collapsed
                                   logEventInternal={wrappedLogEventInternal}>
                        {completedTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
            </div>
        )
    }
    if (frequency === 'oneoff') {
        const completedTasks = tasks.filter(task => task.taskState === 'complete');
        const incompleteTasks = tasks.filter(task => task.taskState !== 'complete');

        return (
            <div className={'task-panel-tasks'}>
                {incompleteTasks.length > 0 &&
                    <LabelledGroup label={'Pending'} className={'task-panel-task-group today'}
                                   itemCount={incompleteTasks.length} logEventInternal={wrappedLogEventInternal}>
                        {incompleteTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}

                            />)}
                    </LabelledGroup>
                }
                {completedTasks.length > 0 &&
                    <LabelledGroup label={'Completed'} className={'task-panel-task-group completed'}
                                   itemCount={completedTasks.length} collapsed
                                   logEventInternal={wrappedLogEventInternal}>
                        {completedTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>
                }
            </div>
        )
    }

    if (frequency === 'monthly') {
        const lateTasks = tasks.filter(task => task.taskState === 'late');
        const startOfWeek = new Date(today.getTime() - (today.getDay() * 24 * 60 * 60 * 1000));
        const endOfWeek = new Date(startOfWeek.getTime() + (7 * 24 * 60 * 60 * 1000));

        const dueThisWeek = tasks
            .filter(task => task.dueDate >= startOfWeek && task.dueDate < endOfWeek)
            .filter(task => task.taskState === 'pending');

        const dueLaterTasks =
            tasks.filter(task => task.dueDate >= endOfWeek)
                .filter(task => task.taskState === 'pending');
        const completedTasks = tasks.filter(task => task.taskState === 'complete');


        return (
            <div className={'task-panel-tasks'}>
                {lateTasks.length > 0 &&
                    <LabelledGroup label={'Late'} className={'task-panel-task-group late'} itemCount={lateTasks.length}
                                   logEventInternal={wrappedLogEventInternal}>
                        {lateTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
                {dueThisWeek.length > 0 &&
                    <LabelledGroup label={'Due This Week'} className={'task-panel-task-group today'}
                                   itemCount={dueThisWeek.length}
                                   logEventInternal={wrappedLogEventInternal}
                    >
                        {dueThisWeek.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
                {dueLaterTasks.length > 0 &&
                    <LabelledGroup label={'Due Later'} className={'task-panel-task-group later'}
                                   itemCount={dueLaterTasks.length}
                                   logEventInternal={wrappedLogEventInternal} collapsed>
                        {dueLaterTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
                {completedTasks.length > 0 &&
                    <LabelledGroup label={'Completed'} className={'task-panel-task-group completed'}
                                   itemCount={completedTasks.length} collapsed
                                   logEventInternal={wrappedLogEventInternal}>
                        {completedTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
            </div>
        )
    }

    if (frequency === 'quarterly' || frequency === 'yearly') {
        const lateTasks = tasks.filter(task => task.taskState === 'late');

        const startOfMonth = getStartOfMonth(today);
        const tasksThisMonth =
            tasks.filter(task => getStartOfMonth(task.dueDate).getTime() === startOfMonth.getTime())
                .filter(task => task.taskState === 'pending');

        const dueLaterTasks =
            tasks.filter(task => getStartOfMonth(task.dueDate).getTime() > startOfMonth.getTime())
                .filter(task => task.taskState === 'pending');
        const completedTasks = tasks.filter(task => task.taskState === 'complete');

        return (
            <div className={'task-panel-tasks'}>
                {lateTasks.length > 0 &&
                    <LabelledGroup label={'Late'} className={'task-panel-task-group late'} itemCount={lateTasks.length}
                                   logEventInternal={wrappedLogEventInternal}>
                        {lateTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
                {tasksThisMonth.length > 0 &&
                    <LabelledGroup label={'Due This Month'} className={'task-panel-task-group today'}
                                   itemCount={tasksThisMonth.length}
                                   logEventInternal={wrappedLogEventInternal}>
                        {tasksThisMonth.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>
                }
                {dueLaterTasks.length > 0 &&
                    <LabelledGroup label={'Due Later'} className={'task-panel-task-group later'}
                                   itemCount={dueLaterTasks.length}
                                   logEventInternal={wrappedLogEventInternal} collapsed>
                        {dueLaterTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
                {completedTasks.length > 0 &&
                    <LabelledGroup label={'Completed'} className={'task-panel-task-group completed ' + (frequency === 'yearly' ? 'joyride-dashboard-third-step-foo' : '')}
                                   itemCount={completedTasks.length} collapsed
                                   logEventInternal={wrappedLogEventInternal}>
                        {completedTasks.map(task =>
                            <TaskComponent task={task} key={task.task.id} today={today}
                                           changeTaskCompletionState={changeTaskCompletionState}
                                           editTask={editTask}
                                           deleteTask={deleteTask}
                                           logEventInternal={wrappedLogEventInternal}
                                           users={users}
                            />)}
                    </LabelledGroup>}
            </div>
        )
    }

    return (
        <div className={'task-panel-tasks'}>
            {tasks.map(task =>
                <TaskComponent task={task} key={task.task.id} today={today}
                               changeTaskCompletionState={changeTaskCompletionState}
                               editTask={editTask}
                               deleteTask={deleteTask}
                               logEventInternal={wrappedLogEventInternal}
                               users={users}
                />)}
        </div>
    )
}


export type TaskColumnProps = {
    title: string;
    tasks: ElaboratedTask[];
    taskSuggestions: Task[];
    addTasks: (tasks: Task[]) => void;
    editTask: (task: Task) => void;
    frequency: TaskFrequency;
    today: Date;
    changeTaskCompletionState: (task: Task, desiredEndState: ToggleTasksDesiredEndState) => void;
    deleteTask: (task: Task) => void;
    logEventInternal: (eventName: string, eventParams: any) => void;
    users: string[]
}


export const TaskColumn = ({
                               title,
                               tasks,
                               taskSuggestions,
                               addTasks,
                               frequency,
                               today,
                               changeTaskCompletionState,
                               editTask,
                               deleteTask,
                               logEventInternal,
    users
                           }: TaskColumnProps) => {

    const wrappedLogEventInternal = (eventName: string, eventParams: any) => {
        logEventInternal(eventName, {frequency: frequency, ...eventParams});
    }

    const [showAddTaskModal, setShowAddTaskModal] = useState(false);

    const wrappedAddTasks = (tasks: Task[]) => {
        addTasks(tasks);
        setShowAddTaskModal(false);
    }

    let groundingInformation = '';
    switch (frequency) {
        case 'daily':
            groundingInformation = '';
            break;
        case 'weekly':
            groundingInformation = daysOfWeek[today.getDay()];
            break;
        case 'monthly':
            groundingInformation = addDateNumberSuffix(today.getDate()) + ' of the month';
            break;
        case 'quarterly':
            groundingInformation = `${addDateNumberSuffix((today.getMonth() % 3)+1)} month of quarter ${Math.floor(today.getMonth() / 3)}`;
            break;
        case 'yearly':
            groundingInformation = today.toDateString();
            break
        case 'oneoff':
            groundingInformation = tasks.length + ' pending Tasks';
            break;
    }

    return (
        <div className={'task-column ' + frequency}>
            <div className={'task-column-header'}>
                <h2 className={'task-column-title'}>{title}</h2>
                <div className={'task-grounding-information'}>{groundingInformation}</div>
            </div>

            {frequency === 'oneoff' &&
                <AdhocTaskQuickAdd addTask={(t) => wrappedAddTasks([t])}/>
            }

            {frequency !== 'oneoff' &&
                <Button variant={'secondary'} className="task-column-add-task-button"
                        onClick={() => setShowAddTaskModal(true)}>Add Task {taskSuggestions.length > 0 &&
                    <Badge className={'task-column-task-suggestion-available'}
                           bg="secondary">{taskSuggestions.length}</Badge>}</Button>
            }


            <TaskList tasks={tasks} today={today} changeTaskCompletionState={changeTaskCompletionState}
                      editTask={editTask} deleteTask={deleteTask} frequency={frequency}
                      logEventInternal={wrappedLogEventInternal}
                      users={users}
            />

            <AddTaskModal frequency={frequency} show={showAddTaskModal} onHide={() => setShowAddTaskModal(false)}
                          addTasks={wrappedAddTasks}
                          taskSuggestions={taskSuggestions}
                          today={today}
                          logEventInternal={logEventInternal}
            />
        </div>
    );
}