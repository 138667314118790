import {PracticeUser} from "../model/databaseSchema";
import {useState} from "react";
import {Button, Form, Modal, Table} from "react-bootstrap";
import * as yup from "yup";
import {Formik} from "formik";


export const AddUserForm = ({onSubmit}: { onSubmit: (email: string) => void }) => {
    const schema = yup.object().shape({
        email: yup.string().email().required()
    });

    return (
        <Formik initialValues={{
            email: ''
        }} validationSchema={schema}
                onSubmit={(values) => onSubmit(values.email)}>
            {({values, handleChange, handleSubmit, errors}) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label column={false}>Email</Form.Label>
                        <Form.Control type="email" name='email' value={values.email} onChange={handleChange} isInvalid={!!errors.email}/>
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Button className={"mt-2"} onClick={() => handleSubmit()}>Add User</Button>
                </Form>
            )}
        </Formik>
    )
}


export type PracticeUsersEditorProps = {
    currentUserEmail: string
    users: PracticeUser[]
    deleteUser: (user: PracticeUser) => void
    addUser: (email: string) => void
}

export const PracticeUsersEditor = ({currentUserEmail, users, deleteUser, addUser}: PracticeUsersEditorProps) => {

    const [showAddUser, setShowAddUser] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);

    const [userToDelete, setUserToDelete] = useState<PracticeUser | undefined>(undefined);


    return (<div>
            <Table striped bordered>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Delete User</th>
                </tr>
                </thead>
                <tbody>
                {users.map(user => (
                    <tr key={user.email}>
                        <td>{user.email}</td>
                        <td>
                            {user.email !== currentUserEmail ?
                            <Button onClick={() => {
                                setUserToDelete(user);
                                setShowDeleteUser(true);
                            }}>Delete
                            </Button> : <span>Can't delete yourself</span>}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Button onClick={() => setShowAddUser(true)}>Add User</Button>
            <Modal show={showAddUser} onHide={() => setShowAddUser(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddUserForm onSubmit={(email) => {
                        addUser(email);
                        setShowAddUser(false);
                    }}/>
                </Modal.Body>
            </Modal>
            <Modal show={showDeleteUser} onHide={() => setShowDeleteUser(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {userToDelete?.email}?</p>
                    <Button onClick={() => {
                        deleteUser(userToDelete!);
                        setShowDeleteUser(false);
                    }}>Delete</Button>
                </Modal.Body>
            </Modal>
        </div>
    )

}