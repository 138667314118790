import {ReactNode, useState} from "react";
import {InvoicedManuallyPurchaseOptions} from "./InvoicedManuallyPurchaseOptions";
import {PackageOffer} from "../../../functions/src/databaseSchema";
import {PayWithStripe} from "./PayWithStripe";
import {UserState} from "./LayoutState";
import {User} from "../../model/databaseSchema";


export type PaymentIsRequiredWrapperProps = {
    children: ReactNode
    pathname: string
    userState: UserState
    buyNow: (offer: PackageOffer) => void
}

const noPaymentPaths = [
    "/_paymentReturn",
    "/settings"
]

const shouldUseStripe = (userConfig: User) => {
    return !!userConfig.featureFlags?.enableStripe;

}

export const PaymentIsRequiredWrapper = ({children, userState, pathname, buyNow}: PaymentIsRequiredWrapperProps) => {

    const [selectedPackage, setSelectedPackage] = useState<PackageOffer | undefined>(undefined);

    if (noPaymentPaths.includes(pathname)) {
        return <>{children}</>
    }

    console.log('userState', userState);

    // if the user is not logged in, we don't need to worry about payment
    if (userState.type === 'loading') {
        return <>{children}</>
    }

    if (userState.type === 'error') {
        return <div>Error loading user state</div>
    }

    if (userState.subscription.type !== "inactive") {
        return <>{children}</>
    }

    const useStripe = userState.userConfig?.id ? shouldUseStripe(userState.userConfig) : false;

    const buyNowWrapper = (offer: PackageOffer) => {
        if (useStripe) {
            setSelectedPackage(offer);
        } else {
            console.log('buyNow', offer);
            buyNow(offer);
        }
    }

    if (useStripe && selectedPackage) {
        return <><PayWithStripe selectedPackage={selectedPackage}/></>
    } else {
        return <InvoicedManuallyPurchaseOptions buyNow={buyNowWrapper} packageOffers={userState.packageOffers} useStripe={false}/>
    }
}