import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {useState} from "react";
import "./Login.css";

export type LoginProps = {
    login: (email: string, password: string) => Promise<void>
    resetPassword: (email: string) => Promise<void>
}

export const Login = ({login, resetPassword}: LoginProps) => {
    const [showResetPassword, setShowResetPassword] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required()
    });

    const resetPasswordSchema = yup.object().shape({
        email: yup.string().email().required()
    });

    return (
        <div>
            {
                showResetPassword ?
                    <div>
                        <div onClick={() => setShowResetPassword(false)} className={'back-to-login'}>Back to Login</div>
                        <Formik
                            validationSchema={resetPasswordSchema}
                            onSubmit={(values) => {
                                resetPassword(values.email);
                            }}
                            initialValues={{
                                email: ''
                            }}
                        >
                            {({handleSubmit, handleChange, values, touched, errors}) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="resetPasswordEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            data-testid="reset-password-email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button onClick={() => handleSubmit()}>Reset Password</Button>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    :
                    <div>
                        <Formik
                        validationSchema={schema}
                        validateOnChange={false}
                        onSubmit={(values) => {
                            login(values.email, values.password);
                        }}
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                    >
                        {({handleSubmit, handleChange, values, touched, errors}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="loginEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        data-testid="login-email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="loginPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        data-testid="login-password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isValid={touched.password && !errors.password}
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div onClick={() => setShowResetPassword(true)} className={'reset-password-link'}>Reset Password?</div>
                                <Button onClick={() => handleSubmit()}>Login</Button>
                            </Form>
                        )}
                        </Formik>

                    </div>
                    }
        </div>
    );
}

export type LoginWithMagicLinkProps = {
    loginWithMagicLink: (email: string) => Promise<void>
}


export const LoginWithMagicLink = ({loginWithMagicLink}: LoginWithMagicLinkProps) => {

    const [emailSent, setEmailSent] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email().required(),
    });


    return <div>
        {emailSent ? <div>Please check your email for a login link</div> :
            <Formik
                validationSchema={schema}
                onSubmit={async (values) => {
                    await loginWithMagicLink(values.email);
                    setEmailSent(true);
                }}
                initialValues={{
                    email: ''
                }}
            >
                {({handleSubmit, handleChange, values}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="loginWithMagicLinkEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                data-testid="login-email-with-magic-link"
                                value={values.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Button onClick={() => handleSubmit()}>Send Magic Link Email</Button>
                    </Form>
                )}
            </Formik>
        }
    </div>
}