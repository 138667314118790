import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";


export const ComplianceHomeComponent = () => {

    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} xl={1}/>
            <Col xs={12} xl={10}>
                <div className={'ds-panel'}>

                    <h1 className={'ds-panel-header'}>Compliance</h1>

                    <Container fluid className={'mt-4'}>
                        <Row>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-radioactive card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Risk Assessment</Card.Title>
                                        <Link to={`./risk-assessment`}>
                                            <Button className={'w-100 mt-3'}>Create Risk Assessment</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-file-earmark-medical card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Policies</Card.Title>
                                        <Link to={`./policy`}>
                                            <Button className={'w-100 mt-3'}>Create Policy</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-file-text card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Complaint Response</Card.Title>
                                        <Link to={`./complaint`}>
                                            <Button className={'w-100 mt-3'}>Write Complaint Response</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-envelope-check card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Support Letter</Card.Title>
                                        <Link to={`./support-letter`}>
                                            <Button className={'w-100 mt-3'}>Write Support Letter</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-bandaid card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Significant Event Analysis</Card.Title>
                                        <Link to={`./significant-event-analysis`}>
                                            <Button className={'w-100 mt-3'}>Document SEA</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-search card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Inclusivity Checker</Card.Title>
                                        <Link to={`./inclusivity-checker`}>
                                            <Button className={'w-100 mt-3'}>Open Inclusivity Checker</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
            <Col xs={0} xl={1}/>
        </Row>
    </Container>
}



export const ComplianceHome = () => {
    return <ComplianceHomeComponent />
}