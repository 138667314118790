import {DBOnboardingTemplate} from "../model/databaseSchema";

export const nationalTemplate : DBOnboardingTemplate = {
    "name": "national",
    "subjects": [
        {
            name: "Demo",
            fixedTasks: [
                {
                    type: 'oneOff',
                    id: 'arrangeRepairsToEmergencyLighting',
                    name: 'Arrange Repairs to Emergency Lighting',
                    dueDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    type: "oneOff",
                    id: "arrangeLunchForPCNMeeting",
                    name: "Arrange Lunch for PCN Meeting",
                    dueDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 4
                },
                {
                    type: "oneOff",
                    id: "replaceFailedThermometerFromCalibrationReport",
                    name: "Replace Failed Thermometer from Calibration Report",
                    dueDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 2
                },
                {
                    type: "recurring",
                    id: "cmr1FormDue",
                    name: "CMR1 Form Due",
                    details: "Inform Will at BWH surveyors",
                    frequency: {
                        type: "yearly",
                        month: 2,
                        day: 1
                    }
                },
                {
                    type: "oneOff",
                    id: "passNhseNotionalRentReviewToBWH",
                    name: "Pass NHSE Notional Rent Review to Will at BWH",
                    dueDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 3
                }
            ],
            userInputTasks: [
            ]
        },
        {
            "name": "HR",
            "fixedTasks": [],
            "userInputTasks": [
                {
                    "type": "recurring",
                    "id": "yearlyReceptionStaffAppraisals",
                    "name": "Reception Staff Appraisals",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "yearlyAdminStaffAppraisals",
                    "name": "Admin Staff Appraisals",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                }
                ,
                {
                    "type": "recurring",
                    "id": "yearlyClinicalStaffAppraisals",
                    "name": "Clinical Staff Appraisals",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "partnersIndemnity",
                    "name": "Partners Indemnity",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "mandatoryTrainingModulesReset",
                    "name": "Manual Training Modules Reset",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "hrSupportAndInsuranceRenewal",
                    "name": "HR Support and Insurance Renewal",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                }
            ]
        },
        {
            "name": "Finance",
            "fixedTasks":[
                {
                    "type": "recurring",
                    "id": "annualPayPartnersTax1",
                    "name": "Pay Partners Tax 1",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualType2CertPensionEstimates",
                    "name": "Pension Estimates – Type 2 Certs",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "staffPensionsBudgetCalculator",
                    "name": "Staff Pensions Budget Calculator",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "qofEOY",
                    "name": "QOF EOY",
                    "frequency": {
                        "type": "yearly",
                        "month": 3,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "staffSD55AndEOYWages",
                    "name": "Staff SD55 and EOY Wages",
                    "frequency": {
                        "type": "yearly",
                        "month": 3,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "claimCQCBusinessAndWaterRatesBack",
                    "name": "Claim CQC, Business, and Water Rates Back",
                    "frequency": {
                        "type": "yearly",
                        "month": 3,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "submitAccountsToAccountant",
                    "name": "Submit Accounts to Accountant",
                    "frequency": {
                        "type": "yearly",
                        "month": 5,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "payPartnersTax2",
                    "name": "Pay Partners Tax 2",
                    "frequency": {
                        "type": "yearly",
                        "month": 6,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "bankingAndOverdraftRequirementsReviewAndRenew",
                    "name": "Banking and Overdraft Requirements Review and Renew",
                    "frequency": {
                        "type": "yearly",
                        "month": 11,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "weeklyPayInvoicesAndFileInReceipts_scanOntoXERO",
                    "name": "Pay invoices and file in receipts/scan onto XERO",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                },
                {
                    "type": "recurring",
                    "id": "weeklyXEROReconciliations",
                    "name": "XERO reconciliations",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                },
                {
                    "type": "recurring",
                    "id": "pettyCashXero",
                    "name": "Petty Cash on XERO and Monthly Spreadsheet",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "creditCardReconciliation",
                    "name": "Credit Card Spreadsheet Reconciliation",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "privateIncomeReconciliation",
                    "name": "Pay in Private Income and Reconcile XERO and Spreadsheet with Paying-in Slip Cash and Cheques",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "privateIncomeCardBACS",
                    "name": "Card Payments and BACS Private Income Download and Scan into XERO",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "roomHireClaims",
                    "name": "Room Hire Claims from Previous Month",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "minorSurgeryClaims",
                    "name": "Minor Surgery Claims",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                }
            ],
            "userInputTasks": [
                {
                    "type": "recurring",
                    "id": "payrollSubmission",
                    "name": "Payroll Submission",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "staffPayday",
                    "name": "Staff Pay Day",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "financialYearEnd",
                    "name": "Fiancial Year end",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "taxInvestigationInsurance",
                    "name": "Tax Investigation Insurance Renwal",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                }
            ]
        },
        {
            "name": "Health and Safety",
            "fixedTasks":[
                {
                    "type": "recurring",
                    "id": "weeklyTestFireAlarms",
                    "name": "Test Fire Alarms",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                },
                {
                    "type": "recurring",
                    "id": "weeklyCleanersSpotChecks",
                    "name": "Cleaners Spot Checks",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                },
                {
                    "type": "recurring",
                    "id": "weeklyHealthAndSafetyWalkAround",
                    "name": "Health and Safety Walk Around",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                },
                {
                    "type": "recurring",
                    "id": "weeklyMedicinesDeviceMedicationAlerts",
                    "name": "Medicines Device/Medication Alerts",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                }
            ],
            "userInputTasks": [
                {
                    "type": "recurring",
                    "id": "patTestingDue",
                    "name": "Pat Testing Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualFireSystemPanelCheck",
                    "name": "Annual Fire System Panel Check Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualFireRiskAccessment",
                    "name": "Fire Risk Assesment Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualFixedWireTesting",
                    "name": "Fix Wire Testing Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualFridgeServiceAndCalibration",
                    "name": "Annual Fridge Service And Calibration Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualCalibrationOfEquipment",
                    "name": "Annual Calibration of Equipment Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualCouchService",
                    "name": "Annual Couch Service Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualAlarmService",
                    "name": "Annual Alarm Service Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualCCTVService",
                    "name": "Annual CCTV Service Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualLiftService",
                    "name": "Annual Lift Service Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "semiAnnualFireExtinguisher1",
                    "name": "Semi Annual Fire Extinghisher Check 1 Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "semiAnnualFireExtinguisher2",
                    "name": "Semi Annual Fire Extinghisher Check 2 Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualGasBoilerService",
                    "name": "Annual Gas Boiler Service Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                }
            ]
        },
        {
            "name": "Reporting",
            "fixedTasks":[
                {
                    "type": "recurring",
                    "id": "annualCDReportingToolkit",
                    "name": "CD Reporting Toolkit",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "extendedAccessSubmissionOnPrimaryCareDashboard",
                    "name": "Extended Access Submission on Primary Care Dashboard",
                    "frequency": {
                        "type": "yearly",
                        "month": 2,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "eoyPreparationForEnhancedServices",
                    "name": "EOY Preparation for Enhanced Services",
                    "frequency": {
                        "type": "yearly",
                        "month": 3,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "k041bComplaintsSubmission",
                    "name": "K041b Complaints Submission",
                    "frequency": {
                        "type": "yearly",
                        "month": 4,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "extendedAccessSubmissionViaPCD",
                    "name": "Extended Access Submission via PCD",
                    "frequency": {
                        "type": "yearly",
                        "month": 8,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "workforceDataSubmission",
                    "name": "Workforce Data Submission",
                    "frequency": {
                        "type": "yearly",
                        "month": 8,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "practiceSelfDeclarationViaPCD",
                    "name": "Practice Self Declaration via PCD",
                    "frequency": {
                        "type": "yearly",
                        "month": 10,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "ppaSubmission",
                    "name": "PPA Submission",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "cqrsSubmission",
                    "name": "CQRS Submission",
                    "frequency": {
                        "type": "monthly",
                        "day": 12
                    }
                },
                {
                    "type": "recurring",
                    "id": "chronicDiseaseSpreadsheet",
                    "name": "Chronic Disease Spreadsheet for Phlebotomists",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "gpAppointmentWorkloadAnalysis",
                    "name": "GP Appointment Workload Analysis on EMIS",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                }
            ],
            "userInputTasks":[]
        },
        {
            "name": "Infection Control",
            "fixedTasks": [
                {
                    "type": "recurring",
                    "id": "infectionControlPoliciesAndProceduresReview",
                    "name": "Infection Control Policies and Procedures Review, Annual Statement and Review",
                    "frequency": {
                        "type": "yearly",
                        "month": 2,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "legionellaCertificateEvery3Years",
                    "name": "Legionella Certificate (Every 3 Years)",
                    "frequency": {
                        "type": "yearly",
                        "month": 2,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "weeklyInfectionControlBuildingWalkAround",
                    "name": "Infection Control Building Walk Around",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                },
                {
                    "type": "recurring",
                    "id": "weeklyLegionellaTapFlush",
                    "name": "Legionella Tap Flush",
                    "frequency": {
                        "type": "weekly",
                        "dayOfWeek": "monday"
                    }
                }
            ],
            "userInputTasks": [
                {
                    "type": "recurring",
                    "id": "annualDeepClean",
                    "name": "Annual Deep Clean Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualCurtainChange",
                    "name": "Annual Curtain Change Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualBlindsDeepClean",
                    "name": "Annual Blinds Deep Clean Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualLegionellaTesting",
                    "name": "Annual Legionella Testing Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualLegionellaRiskAssesment",
                    "name": "Annual Legionella Risk Assesment Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                }
            ]
        },
        {
            "name": "Policy Management",
            "fixedTasks": [
                
            ],
            "userInputTasks": [
                {
                    "type": "recurring",
                    "id": "annualReviewChildAndAdultSafeguardingPolicyAndProcedures",
                    "name": "Review Child and Adult Safeguarding Policies and Procedures",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualReviewAndRenewGDPRPolicies",
                    "name": "Renew and Review GDPR Policies and Procedures",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "reviewAndRenewAllPoliciesAndProceduresOutstanding",
                    "name": "Review and Renew All Policies and Procedures Outstanding (Overhaul + Review)",
                    "frequency": {
                        "type": "yearly",
                        "month": 5,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "updateHRAndHealthAndSafetyPoliciesAndProcedures",
                    "name": "Update HR and Health and Safety Policies and Procedures",
                    "frequency": {
                        "type": "yearly",
                        "month": 8,
                        "day": 1
                    }
                }
            ]
        },
        {
            "name": "Practice Managment",
            "fixedTasks": [
                {
                    "type": "recurring",
                    "id": "annualRenewShareDriveFolders",
                    "name": "Renew Folders on Shared Drive",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualUpdatePracticeLeaflet",
                    "name": "Update Practice Leaflet",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualDataProtectionSecurityToolkit",
                    "name": "Data Protection Security Toolkit",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "gpPatientSurveyResultsAndActionPlan",
                    "name": "GP Patient Survey Results and Action Plan",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "cprTrainingForAllStaff",
                    "name": "CPR Training for all Staff",
                    "frequency": {
                        "type": "yearly",
                        "month": 1,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "renewPracticeBusinessPlanAndKeyAims",
                    "name": "Renew Practice Business Plan and Key Aims",
                    "frequency": {
                        "type": "yearly",
                        "month": 3,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "reviewPracticeTrainingPlan",
                    "name": "Review Practice Training Plan",
                    "frequency": {
                        "type": "yearly",
                        "month": 3,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "nurseVaccineStockCheck",
                    "name": "Nurse Vaccine Stock Check",
                    "frequency": {
                        "type": "yearly",
                        "month": 5,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "planFluClinics",
                    "name": "Plan Flu Clinics",
                    "frequency": {
                        "type": "yearly",
                        "month": 7,
                        "day": 1
                    }
                },{
                    "type": "recurring",
                    "id": "reviewFluPandemicPlan",
                    "name": "Review Flu Pandemic Plan",
                    "frequency": {
                        "type": "yearly",
                        "month": 8,
                        "day": 1
                    }
                },{
                    "type": "recurring",
                    "id": "fluClinicsOnMJogAndWebsite",
                    "name": "Flu Clinics on MJog and Website",
                    "frequency": {
                        "type": "yearly",
                        "month": 8,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualSmearAudit",
                    "name": "Annual Smear Audit",
                    "frequency": {
                        "type": "yearly",
                        "month": 8,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "orderFluForNextYear",
                    "name": "Order Flu for Next Year",
                    "frequency": {
                        "type": "yearly",
                        "month": 9,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "businessContinuityPlanUpdate",
                    "name": "Business Continuity Plan Update",
                    "frequency": {
                        "type": "yearly",
                        "month": 9,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "itStrategicPlan",
                    "name": "IT Strategic Plan",
                    "frequency": {
                        "type": "yearly",
                        "month": 9,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "planMeetingsForFollowingYearAndInformTeamAndZionCentre",
                    "name": "Plan Meetings for the Following Year and Inform Team and Zion Centre",
                    "frequency": {
                        "type": "yearly",
                        "month": 10,
                        "day": 1
                    }
                }, 
                {
                    "type": "recurring",
                    "id": "renewHolidayChartForNextYear",
                    "name": "Renew Holiday Chart for Next Year",
                    "frequency": {
                        "type": "yearly",
                        "month": 10,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "cleaningCompanyAudit",
                    "name": "Meet with Cleaning Company Manager – Building Audit/Spot Check",
                    "frequency": {
                        "type": "monthly",
                        "day": 1
                    }
                }
            ],
            "userInputTasks":[
                {
                    "type": "recurring",
                    "id": "annualSpirometerServiceAndCalibration",
                    "name": "Annual Spirometer Service & Calibration Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualDefib_Oxygen_NebuliserService",
                    "name": "Annual Defib/Oxygen/Nebuliser Service Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualPracticeInsurance_Employer_PublicLiabilityRenewal",
                    "name": "Practice Insurance (Employer, Public Liability) Renewal Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualLocumInsuranceRenewal",
                    "name": "Locum Insurance Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualKeyPersonInsuranceRenewal",
                    "name": "Key Person Insurance Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualICORenewal",
                    "name": "ICO Renewal Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                },
                {
                    "type": "recurring",
                    "id": "annualCyberInsurancePolicyRenewal",
                    "name": "Cyber Insurance Policy Due",
                    "frequency": {
                        "type": "yearly",
                        "month": 0,
                        "day": 1
                    }
                }
            ]
        }
    ]
}