import {isEqual} from "lodash";
import {Subject, Unsubscribable, UpdateHandler} from "./Subscriber";
import {useEffect, useMemo, useState} from "react";

export interface ListenableValue<T> {
    get(): T;

    addListener(listener: UpdateHandler<T>): Unsubscribable;
}

export interface WritableListenableValue<T> extends ListenableValue<T> {
    set(value: T): boolean;
}


export class ListenableValueImpl<T> implements WritableListenableValue<T>{
    private value: T;

    private updateSubject: Subject<T> = new Subject<T>();

    constructor(value: T) {
        this.value = value;
    }

    get(): T {
        return this.value;
    }

    set(value: T) {
        if(isEqual(this.value, value))
        {
            return false;
        }
        this.value = value;
        this.updateSubject.next(value);
        return true;
    }

    addListener(listener: UpdateHandler<T>) {
        return this.updateSubject.subscribe(listener);
    }
}

export const useListenableValue = <T>(listenable : ListenableValue<T>) => {
    const [rawValue, setRawValue] = useState<T | undefined>(listenable.get());

    const listener: UpdateHandler<T> = useMemo(() => {
        return {
            onUpdate: (latest: T) => {
                setRawValue(latest);
            }
        }
    }, []);

    useEffect(() => {
        const unsubscribe = listenable.addListener(listener);
        setRawValue((prev) => {
            if (prev === listenable.get()) {
                return prev;
            }
            return listenable.get();
        })
        return () => {
            unsubscribe.unsubscribe();
        }
    }, [listenable, listener]);

    return rawValue;
}