import {loadStripe} from '@stripe/stripe-js';
import {useCallback, useEffect, useState} from "react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../model/firebaseConnection";
import {CreateCheckoutSessionRequest, CreateCheckoutSessionResponse} from "../../model/RequestTypes";
import {PackageOffer} from "../../model/databaseSchema";
import {
    EmbeddedCheckout,
    EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import {Col, Container, Row} from "react-bootstrap";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe('pk_test_51QAf0yRqRIdrabZ8umaO4xfbSj8Yj4gB5QbWk8L1Bed3OvtaxhWcfcKdC4Gh4skyd4Yrze8ecTae8137lHElrVfJ00NffuCaHR');


export type PayWithStripeProps = {
    selectedPackage: PackageOffer
}

export const PayWithStripe = ({selectedPackage}: PayWithStripeProps) => {
    const fetchClientSecret = useCallback(async () => {
        const paymentAPIFunc = httpsCallable<CreateCheckoutSessionRequest, CreateCheckoutSessionResponse>(functions, 'paymentAPI');
        const response = await paymentAPIFunc({
            type: "createCheckoutSession",
            packageOfferId: selectedPackage.id,
            returnUrl: window.location.origin + "/_paymentReturn?session_id={CHECKOUT_SESSION_ID}&continue_url=" + window.location.href
        });
        return response.data.clientSecret;
    }, [selectedPackage.id]);

    return (
        <Container>
            <Row>
                <Col>
                    <div className={'ds-panel mt-3'}>
                        <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={{fetchClientSecret}}
                        >
                            <EmbeddedCheckout/>
                        </EmbeddedCheckoutProvider>
                    </div>
                </Col>
            </Row>
        </Container>
    )
};
