import {Button, Form, Table} from "react-bootstrap";
import React from "react";
import {RegularEmailSubscriptions} from "../model/RequestTypes";

export type EmailEventsProps = {
    sendRegularEmailNow: (emailId: string) => void
    updateRegularEmailPreferences: (emailId: string, subscription: boolean) => void
    regularEmailPreferences: RegularEmailSubscriptions,
}



export const EmailEvents = ({regularEmailPreferences, sendRegularEmailNow, updateRegularEmailPreferences} : EmailEventsProps) => {
    return <Table striped bordered hover>
        <thead>
        <tr>
            <th>Email</th>
            <th>Event</th>
            <th>Send Immediately</th>
            <th>Subscribe</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className={'email-setting-name'}>Late Tasks</td>
            <td>When tasks are late</td>
            <td className={'email-setting-sendNow'}><Button onClick={() => sendRegularEmailNow('lateTasks')}>Send
                Now</Button></td>
            <td className={'align-content-center email-settings-subscribe'}><Form.Check
                checked={regularEmailPreferences.lateTasks}
                onChange={(e) => updateRegularEmailPreferences('lateTasks', e.currentTarget.checked)}></Form.Check>
            </td>
        </tr>
        </tbody>
    </Table>
}