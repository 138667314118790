import {Accordion, Alert, Button, Col, Container, Form, Modal, ModalFooter, Row} from "react-bootstrap";
import {MarkDownEditor} from "./MarkDownEditor";
import {useState} from "react";
import {ProgressIndicator} from "../common/ProgressIndicator";
import {howToTemplates, instructionTemplates} from "./instructionTemplates";
import {Link} from "react-router-dom";
import "./AIDocumentEditor.css";

export type GenerateDocumentProps = {
    documentName: string,
    documentTemplate: string
    input: string
}

export type ModifyExistingDocProps = {
    documentName: string,
    documentTemplate: string,
    input: string,
    originalDocument: string
}

export type AIDocumentEditorProps = {
    generateDocument: (props: GenerateDocumentProps) => Promise<string>
    downloadPDF: (riskAssessment: string, name: string, templateName: string) => Promise<void>
    modifyDocument: (props: ModifyExistingDocProps) => Promise<string>
    templateName: string,
    hideNameInput?: boolean
}


export const AIDocumentEditor = ({
                                     generateDocument,
                                     downloadPDF,
                                     modifyDocument,
                                     templateName,
                                     hideNameInput = false
                                 }: AIDocumentEditorProps) => {

    const [name, setName] = useState<string>(hideNameInput ? templateName : "");
    const [instructions, setInstructions] = useState<string>(instructionTemplates[templateName] ?? "");
    const [showModification, setShowModification] = useState<boolean>(false);
    const [modificationInstructions, setModificationInstructions] = useState<string>("");

    const [markdownContent, setMarkdownContent] = useState<string>("");

    const [generating, setGenerating] = useState<boolean>(false);

    const wrapGenerateDoc = async () => {
        setGenerating(true);
        const newDocument = await generateDocument({
            documentName: name,
            documentTemplate: templateName,
            input: instructions
        });
        setMarkdownContent(newDocument);
        setGenerating(false);
    }

    const wrapModifyDocument = async () => {
        setGenerating(true);
        const newDocument = await modifyDocument({
            documentName: name,
            documentTemplate: templateName,
            input: modificationInstructions,
            originalDocument: markdownContent
        })
        setMarkdownContent(newDocument);
        setGenerating(false);
    }


    return <Container className={'mt-3'}>
        <Row>
            <Col>
                <Alert variant={'danger'} className={'mt-3'} dismissible>
                    <Alert.Heading>Privacy Alert</Alert.Heading>
                    <p>
                        Entering personal data below (patients, staff, etc) is a data breach.  Anonymise
                        inputs before using this tool.  See our <Link to={'/data/sensitive-data-guide'}>Sensitive Data Guide</Link> for more information.
                    </p>
                </Alert>
                <Alert variant={'warning'} className={'mt-3'}>
                    <Alert.Heading>Disclaimer</Alert.Heading>
                    <p>
                        This tool is provided to assist competent professionals in generating documents.
                        It remains your responsibility to ensure that the generated content is accurate, complete, and
                        fit for purpose.
                        All generated content is provided without warranty, and we make no guarantees regarding its
                        suitability or correctness.
                    </p>
                </Alert>
                <Alert variant={'warning'} className={'mt-3'}>
                    <Alert.Heading>Feature Preview</Alert.Heading>
                    <p>
                        AI Document Editor is currently in "Preview" status. Features may change or be removed as we
                        iterate towards a final version. This warning will be removed when the feature is considered
                        complete
                    </p>
                </Alert>
                <div className={'ds-panel mt-3'}>
                    <h2 className={'ds-panel-header'}>{hideNameInput ? templateName : `Document: ${name}`}</h2>
                    {!hideNameInput && <Form.Group className={'mt-2'}>
                        <Form.Label>Document Name</Form.Label>
                        <Form.Control placeholder={'Title for the document'}
                                      type={'text'} value={name}
                                      onChange={(e) => setName(e.target.value)}/>
                    </Form.Group>}
                    {howToTemplates[templateName] && <div className={'d-flex align-items-center align-content-center justify-content-center flex-row ai-document-editor-how-to-use-accordion'}> <Accordion className={'mt-3 w-100'}>
                        <Accordion.Header>How to use this tool</Accordion.Header>
                        <Accordion.Body>{howToTemplates[templateName]}</Accordion.Body>
                    </Accordion></div>}
                    <Form.Group className={'mt-3'}>
                        <Form.Label>Instructions for the AI</Form.Label>
                        <MarkDownEditor markdownContent={instructions}
                                        setMarkdownContent={setInstructions}
                        />
                    </Form.Group>
                    <Button className={'mt-3'} onClick={wrapGenerateDoc}
                            disabled={generating}>Generate {templateName}</Button>
                </div>
                <div className={'ds-panel mt-3'}>
                    <h2 className={'ds-panel-header'}>{templateName}</h2>
                    {generating ? <ProgressIndicator text={`Generating ${templateName}`} maxSeconds={300}/> :
                        <MarkDownEditor markdownContent={markdownContent}
                                        setMarkdownContent={setMarkdownContent}
                        />}
                    <div className={'d-flex flex-row gap-2'}>
                        <Button variant={'primary'}
                                className={'mt-3'}
                                onClick={() => downloadPDF(markdownContent, name, templateName)}>Download PDF</Button>
                        <Button variant={'secondary'}
                                className={'mt-3'}
                                onClick={() => setShowModification(true)}>Modify With AI</Button>
                    </div>
                </div>
                <Modal show={showModification} size={'lg'}>
                    <Modal.Header>
                        Modify using AI
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className={'mt-3'}>
                            <Form.Label>Instructions for the AI</Form.Label>
                            <Form.Control type={'text'} as={'textarea'} rows={10} value={modificationInstructions}
                                          onChange={(e) => setModificationInstructions(e.target.value)}/>
                        </Form.Group>
                        <ModalFooter>
                            <Button variant={'primary'} onClick={() => {
                                wrapModifyDocument()
                                setShowModification(false)
                            }}>Modify</Button>
                            <Button variant={'secondary'} onClick={() => setShowModification(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal.Body>
                </Modal>
            </Col>
        </Row>
    </Container>
}