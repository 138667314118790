import {FinanceTrackingDataPoint, MonthlyDataGrid} from "./MonthlyDataGrid";
import {Button, OverlayTrigger, Popover, Form} from "react-bootstrap";
import {useState} from "react";
import "./ConfigurableMonthlyDataGrid.css";


export type ConfigurableMonthlyDataGridProps = {
    data: FinanceTrackingDataPoint[];
    onDataChange: (source: string, month: string, newValue: number) => void;
    addSource: (source: string) => void
    deleteSource: (source : string) => void
}

export const ConfigurableMonthlyDataGrid = ({data, onDataChange, addSource, deleteSource} : ConfigurableMonthlyDataGridProps) => {

    const [enabled, setEnabled] = useState<{[name:string] : boolean}>(data.reduce((acc, dataPoint) => {
        acc[dataPoint.source] = true;
        return acc;
    }, {} as Record<string, boolean>));

    const [newItem, setNewItem] = useState<string>('');

    const enabledData = data.filter((dataPoint) => enabled[dataPoint.source]);

    const handleAdd = () => {
        addSource(newItem);
        setNewItem('');
        setEnabled({...enabled, [newItem]: true});
    }


    const popover= (
        <Popover>
            <Popover.Header as="h3">Configure</Popover.Header>
            <Popover.Body>
                <div className={'monthly-data-grid-config-wrapper'}>
                {
                    data.map((dataPoint) => {
                        return <div key={dataPoint.source}>
                            <Form.Check className={'monthly-data-grid-config-check'} >
                                <Form.Check.Input type="checkbox" checked={enabled[dataPoint.source]} onChange={(e) => setEnabled({
                                    ...enabled,
                                    [dataPoint.source]: e.currentTarget.checked
                                })}/>
                                <Form.Check.Label>{dataPoint.source}</Form.Check.Label>
                            </Form.Check>
                        </div>
                    })
                }
                </div>
                <div className={'d-flex gap-2'}>
                <Form.Control type={'text'} placeholder={"New Item"} value={newItem} onChange={(e) => setNewItem(e.target.value)}></Form.Control>
                    <Button disabled={!newItem || newItem === ''} onClick={handleAdd}><i className={'bi bi-plus-square'}></i></Button>
                </div>
            </Popover.Body>
        </Popover>
    )


    return <div>
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant={'primary'}>Configure</Button>
        </OverlayTrigger>
        <MonthlyDataGrid data={enabledData} onDataChange={onDataChange}/>
    </div>
}