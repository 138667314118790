import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";
import "./OnboardingFirstPage.css";
import {PracticeUsersEditor} from "../settings/PracticeUsersEditor";
import {PracticeUsersLoadable} from "../model/PracticeUsers";
import {PracticeUser} from "../model/databaseSchema";

type OnboardingFirstPageProps = {
    currentUserEmail: string,
    onboardSection: (sectionName: string) => void
    practiceUsers: PracticeUsersLoadable
    addUser: (email: string) => void
    deleteUser: (user: PracticeUser) => void
    finishOnboarding: () => void
    sectionNames: string[]
}

export const OnboardingFirstPage = ({
                                        currentUserEmail,
                                        practiceUsers,
                                        addUser,
                                        deleteUser,
                                        onboardSection,
                                        finishOnboarding,
                                        sectionNames
                                    }: OnboardingFirstPageProps) => {


    return <div className={'mb-5'}>
        <div className={'ds-panel'}>
            <h2 className={'ds-panel-header'}>Onboarding - Continue any time</h2>
            <p>You're busy. Do a little onboarding now, and restart where you left off later.</p>
            <p>You'll find the onboarding button in the top right corner.</p>
        </div>

        <div className={'ds-panel mt-3'}>
            <h3 className={'ds-panel-header'}>Setup Deputy/Others</h3>
            <p>You can add other users at any time in the settings section (top right), but if you want to add them now,
                you
                can</p>
            {practiceUsers.type === 'loaded' &&
                <PracticeUsersEditor users={practiceUsers.users} deleteUser={deleteUser}
                                     addUser={addUser}
                                     currentUserEmail={currentUserEmail}/>}

        </div>


        <div className={'ds-panel mt-3'}>
            <h3 className={'onboarding-first-section-subheading'}>Onboarding sections</h3>
            <p>We’ve preloaded common tasks for most practices, organized by section. You can start with one section
                today
                and add more later.</p>
            <Container fluid>
                <Row>
                    <Col>

                    </Col>
                </Row>
                {sectionNames.map((sectionName) => {
                    return <Row className={'mt-2'}>
                        <Col>
                            {sectionName}
                        </Col>
                        <Col>
                            <Button onClick={() => onboardSection(sectionName)} className={'w-75 align-self-center'}>Start {sectionName} Onboarding</Button>
                        </Col>
                    </Row>
                })}
            </Container>
            <div>

            </div>
        </div>

        <div className={'ds-panel mt-3'}>
            <h3 className={'ds-panel-header'}>Finish Onboarding (for now)</h3>
            <Button onClick={finishOnboarding}>Finish Onboarding</Button>
        </div>

    </div>
}