import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {OnboardingSubject} from "../../functions/src/RequestTypes";
import {OnboardingSubjectComponent} from "./OnboardingSubjectComponent";
import {PracticeUser, TaskTemplate} from "../model/databaseSchema";
import {
    AddTasksRequest,
    AddTasksResponse,
    GetOnboardingInfoRequest,
    GetOnboardingInfoResponse,
    Task,
    ToggleTasksCompleteRequest,
    ToggleTasksCompleteResponse
} from "../model/RequestTypes";
import {useLocation, useNavigate} from "react-router-dom";
import "./page.css";
import {elaborateTasks} from "../model/Tasks";
import {callAPI} from "../model/API";
import {OnboardingFirstPage} from "./OnboardingFirstPage";
import {LoadableValue} from "../model/LoadableValue";
import {auth} from "../model/firebaseConnection";
import {PracticeUsersLoadable, usePracticeUsers} from "../model/PracticeUsers";
import {TaskTemplateWithAssignee} from "./TaskTemplateWithAssignee";


type OnboardingPageProps = {
    onboardingTemplate: Record<string, OnboardingSubject>,
    addTasks: (tasks: TaskTemplateWithAssignee[]) => Promise<void>
    goToHome: () => void,
    currentUserEmail: string,
    practiceUsers: PracticeUsersLoadable
    addUser: (email: string) => void
    deleteUser: (user: PracticeUser) => void
}

const OnboardingPageComponent =
    ({
         onboardingTemplate,
         addTasks,
         goToHome,
         currentUserEmail,
         practiceUsers,
         addUser,
         deleteUser
     }: OnboardingPageProps) => {

        const [onboardingSection, setOnboardingSection] = useState<string>('introduction');

        const subjectNames =
            onboardingTemplate ?
                Object
                    .values(onboardingTemplate)
                    .filter((s) => (s.fixedTasks.length + s.userInputTasks.length) > 0)
                    .map((s) => s.name).sort() : [];

        if (onboardingSection === 'introduction') {
            return <Container className={'mt-4'}>
                <Row>
                    <Col xs={0} xl={1}/>
                    <Col xs={12} xl={10}>
                        <OnboardingFirstPage currentUserEmail={currentUserEmail}
                                             onboardSection={setOnboardingSection}
                                             practiceUsers={practiceUsers}
                                             addUser={addUser}
                                             deleteUser={deleteUser}
                                             finishOnboarding={goToHome}
                                             sectionNames={subjectNames}
                        />
                    </Col>
                    <Col xs={0} xl={1}/>
                </Row>
            </Container>
        } else {
            return <Container className={'mt-4'}>
                <Row>
                    <Col xs={0} xl={1}/>
                    <Col xs={12} xl={10}>
                        <OnboardingSubjectComponent addTasks={addTasks}
                                                    subject={onboardingTemplate[onboardingSection]}
                                                    next={() => {
                                                        setOnboardingSection('introduction');
                                                    }}
                                                    practiceUsers={practiceUsers}/>
                    </Col>
                    <Col xs={0} xl={1}/>
                </Row>
            </Container>
        }
    }
type OnboardingPageState = {
    onboardingTemplate: Record<string, OnboardingSubject>,
}

export const OnboardingPage = () => {

    const navigate = useNavigate();
    const path = useLocation();

    const [onboardingState, setOnboardingState] = useState<LoadableValue<OnboardingPageState>>({type: "loading"});

    const [practiceUsers, addUser, deleteUser] = usePracticeUsers();


    const fetchData = async () => {
        try {
            const onboardingInfo = await callAPI<GetOnboardingInfoRequest, GetOnboardingInfoResponse>({
                type: 'getOnboardingInfo',
                onboardingTemplateId: path.pathname === '/dental' ? 'dental' : 'national'
            });

            setOnboardingState({type: "loaded", onboardingTemplate: onboardingInfo.template.subjects});
        } catch (e: any) {
            setOnboardingState({type: "error", error: e.message});
        }
    }


    useEffect(() => {
        fetchData();
    }, []);

    const addTasks = async (tasks: Task[]) => {
        await callAPI<AddTasksRequest, AddTasksResponse>({type: 'addTasks', tasks});
        await fetchData();
    }

    const toggleTasksComplete = async (tasks: Task[]) => {
        await callAPI<ToggleTasksCompleteRequest, ToggleTasksCompleteResponse>({
            type: 'toggleTasksComplete',
            tasks,
            desiredEndState: "complete"
        });
        await fetchData();
    }

    const addTaskTemplates = async (taskTemplates: TaskTemplateWithAssignee[]) => {
        const convertedTasks: Task[] = taskTemplates.map((task) => {
            if (task.type === 'oneOff') {
                return {
                    id: task.id,
                    name: task.name,
                    details: task.details,
                    dueDate: task.dueDate,
                    type: task.type,
                    tags: task.tags,
                    owner: task.assignee
                }
            } else {
                return {
                    id: task.id,
                    name: task.name,
                    details: task.details,
                    frequency: task.frequency,
                    type: task.type,
                    tags: task.tags,
                    owner: task.assignee
                }
            }
        });

        await addTasks(convertedTasks);
        const elaboratedTasks = elaborateTasks(convertedTasks, new Date());
        const lateTasks = elaboratedTasks.filter(task => task.dueDate.getTime() < new Date().getTime());
        if (lateTasks.length > 0) {
            await toggleTasksComplete(lateTasks.map(task => task.task));
        }
    }

    if (onboardingState.type === "loading") {
        return <div>Loading...</div>
    }

    if (onboardingState.type === "error") {
        return <div>Error: {onboardingState.error.message}</div>
    }

    return <OnboardingPageComponent onboardingTemplate={onboardingState.onboardingTemplate}
                                    addTasks={addTaskTemplates}
                                    goToHome={() => {
                                        navigate('/');
                                    }}
                                    currentUserEmail={auth.currentUser?.email!}
                                    practiceUsers={practiceUsers}
                                    addUser={addUser}
                                    deleteUser={deleteUser}
    />

}