import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    DropdownItem,
    Form,
    OverlayTrigger,
    Popover,
    Row
} from "react-bootstrap";
import "./MonthlyTracker.css";
import {FinanceTrackingDataPoint} from "./MonthlyDataGrid";
import {ConfigurableMonthlyDataGrid} from "./ConfigurableMonthlyDataGrid";
import {DBIncomeAndExpensesVersion} from "../../functions/src/databaseSchema";
import {LoadableValue} from "../model/LoadableValue";
import {callAPI} from "../model/API";
import {SaveIncomeAndExpensesRequest} from "../../functions/src/RequestTypes";
import {
    GetIncomeAndExpensesRequest,
    GetIncomeAndExpensesResponse,
    IncomeAndExpenses,
    SaveIncomeAndExpensesResponse
} from "../model/RequestTypes";
import {ProgressIndicator} from "../components/common/ProgressIndicator";


type MonthlyTrackerState =
    {
        editsPending: true,
        currentVersion: DBIncomeAndExpensesVersion,
        previousVersions: DBIncomeAndExpensesVersion[]

    } |
    {
        editsPending: false,
        currentVersion: DBIncomeAndExpensesVersion,
        previousVersions: DBIncomeAndExpensesVersion[]
    };


type MonthlyTrackerProps = {
    state: MonthlyTrackerState,
    edit: (data: DBIncomeAndExpensesVersion) => void
    save: () => Promise<void>
    year: number,
    setYear: (year: number) => void
}

export const MonthlyTrackerComp = ({state, save, edit, year, setYear}: MonthlyTrackerProps) => {

    console.log("State", state);

    const incomeDataPoints: FinanceTrackingDataPoint[] = Object.values(state.currentVersion.incomeItems).map((source) => ({
        source: source.name,
        data: Object.entries(source.monthlyAmount).reduce((acc, [name, am]) => {
            acc[name] = am.amount;
            return acc;
        }, {} as Record<string, number | undefined>)
    }));

    const setIncomeDataPoints = (newDataPoints: FinanceTrackingDataPoint[]) => {
        const newIncomeItems = newDataPoints.reduce((acc, dataPoint) => {
            acc[dataPoint.source] = {
                name: dataPoint.source,
                monthlyAmount: Object.entries(dataPoint.data).reduce((acc, [month, amount]) => {
                    if (amount) {
                        acc[month] = {amount};
                    }
                    return acc;
                }, {} as Record<string, { amount: number }>)
            }
            return acc;
        }, {} as Record<string, { name: string, monthlyAmount: Record<string, { amount: number }> }>);
        edit({...state.currentVersion, incomeItems: newIncomeItems});
    }

    const expensesDataPoints: FinanceTrackingDataPoint[] = Object.values(state.currentVersion.expenseItems).map((source) => ({
        source: source.name,
        data: Object.entries(source.monthlyAmount).reduce((acc, [name, am]) => {
            acc[name] = am.amount;
            return acc;
        }, {} as Record<string, number | undefined>)
    }));

    const setExpensesDataPoints = (newDataPoints: FinanceTrackingDataPoint[]) => {
        const newExpenseItems = newDataPoints.reduce((acc, dataPoint) => {
            acc[dataPoint.source] = {
                name: dataPoint.source,
                monthlyAmount: Object.entries(dataPoint.data).reduce((acc, [month, amount]) => {
                    if (amount) {
                        acc[month] = {amount};
                    }
                    return acc;
                }, {} as Record<string, { amount: number }>)
            }
            return acc;
        }, {} as Record<string, { name: string, monthlyAmount: Record<string, { amount: number }> }>);
        edit({...state.currentVersion, expenseItems: newExpenseItems});
    };

    const years = [2023, 2024];
    for (let i = 0; i < (new Date().getFullYear() - 2024); i++) {
        years.push(2025 + i);
    }

    console.log("Previous Versions", state.previousVersions);


    const versionPopOver = (
        <Popover>
            <Popover.Header>Previous Versions</Popover.Header>
            <Popover.Body>
                <div>
                    {state.previousVersions.map((version) => {
                        return <Button key={version.timestamp} disabled={state.currentVersion.timestamp === version.timestamp} className={'mb-2 w-100'} onClick={() => edit(version)} variant={state.currentVersion.timestamp === version.timestamp ? 'primary' : 'secondary'}>{state.currentVersion.timestamp === version.timestamp ? 'Current' : "Load"} {new Date(version.timestamp).toLocaleString('en-GB')}</Button>
                    })}
                </div>
            </Popover.Body>
        </Popover>
    );


    return (
        <Container fluid className={'mt-3'}>
            <Row>
                <Col>
                    <div className={'ds-panel'}>
                        <h1 className={'ds-panel-header'}>GP Practice Monthly Income & Expenses Tracker</h1>
                        <div
                            className={'d-flex flex-row gap-3 align-items-center align-content-center justify-content-end'}>
                            <Form.Group className={'d-flex flex-row gap-3 align-content-center align-items-center'}>
                                <Form.Label>Year</Form.Label>

                                <Form.Select value={year} onChange={(e) => setYear(parseInt(e.target.value))}>
                                    {years.map((year) => {
                                        return <option key={year} value={year}>{year}/{year - 1999}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <OverlayTrigger trigger="click" placement="left" overlay={versionPopOver}>
                                <Button>Previous Versions</Button>
                            </OverlayTrigger>

                            <Button className={''} onClick={save}
                                    disabled={!state.editsPending}>Save {state.editsPending && "*"}</Button>
                        </div>
                    </div>
                    <div className={'ds-panel mt-3'}>
                        <h1 className={'ds-panel-header'}>Income</h1>
                        <ConfigurableMonthlyDataGrid data={incomeDataPoints}
                                                     onDataChange={(source, month, newValue) => {
                                                         console.log("Changing ", source, month)
                                                         const newDataPoints = [...incomeDataPoints];
                                                         const dataPoint = newDataPoints.find((dataPoint) => dataPoint.source === source);
                                                         if (dataPoint) {
                                                             dataPoint.data[month] = newValue;
                                                             setIncomeDataPoints(newDataPoints);
                                                         }
                                                     }}
                                                     addSource={(source) => {
                                                         setIncomeDataPoints([...incomeDataPoints, {source, data: {}}]);
                                                     }}
                                                     deleteSource={(source) => {
                                                         setIncomeDataPoints(incomeDataPoints.filter((dataPoint) => dataPoint.source !== source));
                                                     }}

                        />
                    </div>
                    <div className={'ds-panel mt-3'}>
                        <h1 className={'ds-panel-header'}>Expenses</h1>
                        <ConfigurableMonthlyDataGrid data={expensesDataPoints}
                                                     onDataChange={(source, month, newValue) => {
                                                         console.log("Changing ", source, month)
                                                         const newDataPoints = [...incomeDataPoints];
                                                         const dataPoint = newDataPoints.find((dataPoint) => dataPoint.source === source);
                                                         if (dataPoint) {
                                                             dataPoint.data[month] = newValue;
                                                             setExpensesDataPoints(newDataPoints);
                                                         }
                                                     }}
                                                     addSource={(source) => {
                                                         setExpensesDataPoints([...incomeDataPoints, {
                                                             source,
                                                             data: {}
                                                         }]);
                                                     }}
                                                     deleteSource={(source) => {
                                                         setExpensesDataPoints(incomeDataPoints.filter((dataPoint) => dataPoint.source !== source));
                                                     }}

                        />
                    </div>
                </Col>
            </Row>

        </Container>
    );
};

export const MonthlyTracker = () => {

    const [pageState, setPageState] = useState<LoadableValue<{ state: MonthlyTrackerState }>>({type: "loading"});
    const [year, setYear] = useState<number>(new Date().getFullYear());


    const setPageStateWithIncomeAndExpenses = (incomeAndExpenses: IncomeAndExpenses) => {
        const sortedVersions = Object.values(incomeAndExpenses.versions).sort((a, b) => {
            return b.timestamp - a.timestamp;
        })
        const currentVersion = sortedVersions.length > 0 ? sortedVersions[0] : {
            incomeItems: {},
            expenseItems: {},
            timestamp: Date.now()
        };
        setPageState({
            type: "loaded",
            state: {editsPending: false, currentVersion, previousVersions: sortedVersions}
        });
    }

    useEffect(() => {
        const load = async () => {
            try {
                const response = await callAPI<GetIncomeAndExpensesRequest, GetIncomeAndExpensesResponse>({
                    type: "getIncomeAndExpenses",
                    year
                });
                setPageStateWithIncomeAndExpenses(response.incomeAndExpenses);
            } catch (e: any) {
                setPageState({type: "error", error: e});
            }
        }
        load();
    }, [year]);

    if (pageState.type === "loading") {
        return <ProgressIndicator text={"Loading Tracker Information"} maxSeconds={60}/>
    }

    if (pageState.type === "error") {
        return <div>{pageState.error.message}</div>
    }

    const save = async () => {
        try {
            const response = await callAPI<SaveIncomeAndExpensesRequest, SaveIncomeAndExpensesResponse>({
                type: "saveIncomeAndExpenses",
                year,
                version: pageState.state.currentVersion
            });
            setPageStateWithIncomeAndExpenses(response.incomeAndExpenses);
        } catch (e: any) {
            setPageState({type: "error", error: e});
        }
    }

    const edit = (data: DBIncomeAndExpensesVersion) => {
        setPageState({
            type: "loaded",
            state: {
                editsPending: true,
                currentVersion: data,
                previousVersions: pageState.state.previousVersions
            }
        });
    }

    return <MonthlyTrackerComp state={pageState.state} save={save} year={year} setYear={setYear} edit={edit}/>;
}
