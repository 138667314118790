import {useEffect, useState} from "react";
import {LoadableValue} from "./LoadableValue";
import {PracticeUser} from "../../functions/src/databaseSchema";
import {callAPI} from "./API";
import {
    AddPracticeUserRequest, AddPracticeUserResponse, DeletePracticeUserRequest, DeletePracticeUserResponse,
    GetPracticeUsersRequest,
    GetPracticeUsersResponse
} from "../../functions/src/RequestTypes";


export type PracticeUsersLoadable = LoadableValue<{users: PracticeUser[]}>;

export type UsePracticeUsersReturnType = [PracticeUsersLoadable, (email: string) => void, (user: PracticeUser) => void];


export const usePracticeUsers = () : UsePracticeUsersReturnType => {
    const [practiceUsers, setPracticeUsers] = useState<LoadableValue<{ users: PracticeUser[] }>>({type: "loading"});
    useEffect(() => {
        const loadPracticeUsers = async () => {
            try {
                const getPracticeUsers = await callAPI<GetPracticeUsersRequest, GetPracticeUsersResponse>({type: "getPracticeUsers"});
                setPracticeUsers({type: "loaded", users: getPracticeUsers.users});
            } catch (e : any) {
                setPracticeUsers({type: "error", error: e});
            }
        }
        loadPracticeUsers();
    }, []);

    const addUser = async (email: string) => {
        try {
            const response = await callAPI<AddPracticeUserRequest, AddPracticeUserResponse>({
                type: "addPracticeUser",
                email
            });
            setPracticeUsers({type: "loaded", users: response.users});
        } catch (e : any) {
            setPracticeUsers({type: "error", error: e});
        }
    }

    const deleteUser = async (user: PracticeUser) => {
        try {
            const response = await callAPI<DeletePracticeUserRequest, DeletePracticeUserResponse>({
                type: "deletePracticeUser",
                email: user.email
            });
            setPracticeUsers({type: "loaded", users: response.users});
        } catch (e : any) {
            setPracticeUsers({type: "error", error: e});
        }
    }

    return [practiceUsers, addUser, deleteUser];
}