import {DBOnboardingTemplate} from "../../functions/src/databaseSchema";

export const cqcAuditTemplate : DBOnboardingTemplate = {
    "name": "cqcAuditTemplate",
    "subjects": [
        {
            name: "CQC - General",
            fixedTasks: [
                {
                    "type": "oneOff",
                    "id": "cqcAuditDeclutterAndTidyPractice",
                    "name": "De-clutter and tidy all areas of the practice.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckDoctorsBagContents",
                    "name": "Check the contents of the doctor’s bag.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckEmergencyDrugs",
                    "name": "Check the emergency drugs and their contents.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckFridgeTemperatures",
                    "name": "Check fridge temperatures and ensure logs are up to date.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                }
            ],
            userInputTasks: [
            ]
        },
        {
            name: "CQC - Health and Safety",
            fixedTasks: [
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewFireEscapeProcedures",
                    "name": "Ensure fire escape and fire evacuation procedures are prominently displayed and complete.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckFirePrecautionSignage",
                    "name": "Check that fire precaution signage is in place and easy to see.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckFireExtinguishers",
                    "name": "Ensure fire extinguishers are in position and within their 'use by' date.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckFireExitRoutes",
                    "name": "Ensure fire exit routes are clear, sufficiently lit, openable, and free from obstruction.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditMonthlyEmergencyLightingCheck",
                    "name": "Carry out emergency lighting monthly checks.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditFireRiskAssessment",
                    "name": "Identify areas that present a fire risk due to poor housekeeping.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditNoSmokingPolicyCheck",
                    "name": "Ensure the No Smoking Policy is adhered to across the site.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditFirePrecautionsRepair",
                    "name": "Identify fire precautions in need of repair, such as defective emergency lighting.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckTrainedFireWardens",
                    "name": "Ensure there are sufficient numbers of trained fire wardens.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckFirstAidKits",
                    "name": "Ensure First Aid kits are available and complete.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckFirstAidContentsExpiry",
                    "name": "Ensure contents of First Aid kits are within their 'use by' date.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditDisplayFirstAiders",
                    "name": "Ensure names of trained first aiders are displayed and up to date.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReportAccidentsAndIncidents",
                    "name": "Ensure staff are reporting accidents and incidents.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditHazardAssessment",
                    "name": "Assess workplace for hazards due to poor housekeeping or lack of storage space.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckWalkways",
                    "name": "Ensure walkways are clear and free from slip or trip hazards.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckCoatsAndBags",
                    "name": "Ensure coats and bags are not presenting trip hazards on chair backs.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckOfficeTemperature",
                    "name": "Ensure the office temperature is acceptable (19–22°C).",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditInspectDaisyChainedLeads",
                    "name": "Check for 'daisy chained' extension leads in the workplace.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditInspectKitchenAreas",
                    "name": "Ensure kitchen areas are clean and hygienic.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditInspectToilets",
                    "name": "Ensure toilets are clean and stocked.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditCheckWasteBins",
                    "name": "Ensure bins for different types of waste are used correctly and cleaned regularly.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewEmergencyDrugBags",
                    "name": "Review Emergency Drug bags.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                }
            ],
            userInputTasks: [

            ]
        },
        {
            name: "CQC - Patient Information",
            fixedTasks: [
                {
                    "type": "oneOff",
                    "id": "cqcAuditPlaceForComments",
                    "name": "Ensure there is an appropriate place for people to comment (positive and negative) about the practice’s care and service.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEasyToComplain",
                    "name": "Ensure it is easy for people to complain by displaying notices about the process.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditIdentifiedIsolationRoom",
                    "name": "Ensure there is an identified isolation room and policies for its use.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditConfidentialArea",
                    "name": "Ensure there is a confidential or private area for patients to use, including a breastfeeding area where possible.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditPatientParticipationGroupInfo",
                    "name": "Display information about the patient participation group.",
                    "dueDate": new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                }
            ],
            userInputTasks : [
                ]
        },
        {
            name: "CQC - Policies",
            fixedTasks : [
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveHealthandSafetyDocumentation",
                    "name": "Review and improve Health and Safety Documentation, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandHealthandSafetyDocumentation",
                    "name": "Ensure all staff have read and understand the Health and Safety Documentation.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveInfectionControlandCleanlinessPolicy",
                    "name": "Review and improve Infection Control and Cleanliness Policy, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandInfectionControlandCleanlinessPolicy",
                    "name": "Ensure all staff have read and understand the Infection Control and Cleanliness Policy.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveSignificantEventsandSeriousAdverseEventsRecords",
                    "name": "Review and improve Significant Events and Serious Adverse Events Records, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandSignificantEventsandSeriousAdverseEventsRecords",
                    "name": "Ensure all staff have read and understand the Significant Events and Serious Adverse Events Records.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveSafeguardingPolicies",
                    "name": "Review and improve Safeguarding Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandSafeguardingPolicies",
                    "name": "Ensure all staff have read and understand the Safeguarding Policies.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveConfidentialityandDataProtectionPolicies",
                    "name": "Review and improve Confidentiality and Data Protection Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandConfidentialityandDataProtectionPolicies",
                    "name": "Ensure all staff have read and understand the Confidentiality and Data Protection Policies.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveConsentPolicies",
                    "name": "Review and improve Consent Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandConsentPolicies",
                    "name": "Ensure all staff have read and understand the Consent Policies.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveWhistleblowingPolicy",
                    "name": "Review and improve Whistleblowing Policy, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandWhistleblowingPolicy",
                    "name": "Ensure all staff have read and understand the Whistleblowing Policy.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveEqualityandDiversityPolicies",
                    "name": "Review and improve Equality and Diversity Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandEqualityandDiversityPolicies",
                    "name": "Ensure all staff have read and understand the Equality and Diversity Policies.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveComplaintsProcedure",
                    "name": "Review and improve Complaints Procedure, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandComplaintsProcedure",
                    "name": "Ensure all staff have read and understand the Complaints Procedure.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveChaperonePolicy",
                    "name": "Review and improve Chaperone Policy, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandChaperonePolicy",
                    "name": "Ensure all staff have read and understand the Chaperone Policy.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveMedicineManagementPolicies",
                    "name": "Review and improve Medicine Management Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandMedicineManagementPolicies",
                    "name": "Ensure all staff have read and understand the Medicine Management Policies.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveRepeatPrescribingProtocols",
                    "name": "Review and improve Repeat Prescribing Protocols, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandRepeatPrescribingProtocols",
                    "name": "Ensure all staff have read and understand the Repeat Prescribing Protocols.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveHealthandSafetyPolicies",
                    "name": "Review and improve Health and Safety Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandHealthandSafetyPolicies",
                    "name": "Ensure all staff have read and understand the Health and Safety Policies.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveFirstAidProcedures",
                    "name": "Review and improve First Aid Procedures, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandFirstAidProcedures",
                    "name": "Ensure all staff have read and understand the First Aid Procedures.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveIsolationRoomPolicies",
                    "name": "Review and improve Isolation Room Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandIsolationRoomPolicies",
                    "name": "Ensure all staff have read and understand the Isolation Room Policies.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImprovePatientFeedbackMechanisms",
                    "name": "Review and improve Patient Feedback Mechanisms, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandPatientFeedbackMechanisms",
                    "name": "Ensure all staff have read and understand the Patient Feedback Mechanisms.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveStaffInductionPrograms",
                    "name": "Review and improve Staff Induction Programs, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandStaffInductionPrograms",
                    "name": "Ensure all staff have read and understand the Staff Induction Programs.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditReviewAndImproveLocumPolicies",
                    "name": "Review and improve Locum Policies, if necessary.",
                    "dueDate": 1733524493221
                },
                {
                    "type": "oneOff",
                    "id": "cqcAuditEnsureStaffUnderstandLocumPolicies",
                    "name": "Ensure all staff have read and understand the Locum Policies.",
                    "dueDate": 1733524493221
                }
            ],
            userInputTasks: []
        }
    ]
}