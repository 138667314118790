import {ElaboratedTask, elaborateTask} from "../model/Tasks";
import {useEffect, useState} from "react";
import {LoadableValue} from "../model/LoadableValue";
import {Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {callAPI} from "../model/API";
import {GetTaskRequest, GetTaskResponse} from "../model/RequestTypes";
import {TaskComponent} from "../components/tasks/TaskComponent";


type ViewTaskPageProps = {
    task: ElaboratedTask
}

const ViewTaskPageComponent = ({task} : ViewTaskPageProps) => {

    return <Container className={'mt-3'}>
        <Row>
            <Col>
                <TaskComponent task={task} today={new Date()} changeTaskCompletionState={() => {}} editTask={() => {}} deleteTask={() => {}} logEventInternal={() => {}} users={[]}/>
            </Col>
        </Row>
    </Container>
}

export const ViewTaskPage = () => {

    const taskId = useParams<{taskId: string}>().taskId;

    const [task, setTask] = useState<LoadableValue<{task : ElaboratedTask}>>({type: 'loading'});

    useEffect(() => {
        const loadTask = async () => {
            if(taskId) {
                try {
                    const response = await callAPI<GetTaskRequest, GetTaskResponse>({type: 'getTask', taskId});
                    const elaboratedTask = elaborateTask(response.task, new Date());
                    setTask({type: 'loaded', task: elaboratedTask});
                } catch (e: any) {
                    setTask({type: 'error', error: e});
                }
            }
        }
        loadTask();
    }, [taskId]);

    if(!taskId){
        return <div>TaskId Not supplied</div>
    }

    if(task.type === 'loading') {
        return <div>Loading...</div>
    }

    if(task.type === 'error') {
        return <div>Error: {task.error.message}</div>
    }



    return (
        <ViewTaskPageComponent task={task.task}/>
    )
}