import {Button, Form, Table} from "react-bootstrap";
import React from "react";
import {RegularEmailSubscriptions} from "../model/RequestTypes";

export type EmailReportsProps = {
    sendRegularEmailNow: (emailId: string) => void
    updateRegularEmailPreferences: (emailId: string, subscription: boolean) => void
    regularEmailPreferences: RegularEmailSubscriptions,
}



export const EmailReports = ({regularEmailPreferences, sendRegularEmailNow, updateRegularEmailPreferences} : EmailReportsProps) => {
    return <Table striped bordered hover>
        <thead>
        <tr>
            <th>Email</th>
            <th>Schedule</th>
            <th>Send Immediately</th>
            <th>Subscribe</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className={'email-setting-name'}>Operations Agenda</td>
            <td>Every Monday</td>
            <td className={'email-setting-sendNow'}><Button onClick={() => sendRegularEmailNow('opsAgenda')}>Send
                Now</Button></td>

            <td className={'align-content-center email-settings-subscribe'}><Form.Check
                checked={regularEmailPreferences.opsAgenda}
                onChange={(e) => updateRegularEmailPreferences('opsAgenda', e.currentTarget.checked)}></Form.Check>
            </td>
        </tr>
        <tr>
            <td className={'email-setting-name'}>Operations Summary</td>
            <td>Every Friday</td>
            <td className={'email-setting-sendNow'}><Button onClick={() => sendRegularEmailNow('opsSummary')}>Send
                Now</Button></td>
            <td className={'align-content-center email-settings-subscribe'}><Form.Check
                checked={regularEmailPreferences.opsSummary}
                onChange={(e) => updateRegularEmailPreferences('opsSummary', e.currentTarget.checked)}></Form.Check>
            </td>
        </tr>
        </tbody>
    </Table>
}