import {FirebaseStorage, getBytes, listAll, ref, getDownloadURL} from "firebase/storage";
import {contentStorage} from "../model/firebaseConnection";

const getStringContent = async (storage: FirebaseStorage, path: string) => {
    const bytesArray = await getBytes(ref(storage, path));

    //convert array buffer to string
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(bytesArray);
}

const getJsonContent = async (storage: FirebaseStorage, path: string) => {
    const stringContent = await getStringContent(storage, path);
    return JSON.parse(stringContent);
}

export type PageMetaData = {
    id: string,
    title: string,
    published: boolean
    otherFiles: {
        [key: string]: string
    }
}

export const getPageContent = async (page: string) : Promise<string | undefined> => {
    try{
        const metaData = (await getJsonContent(contentStorage, `/${page}/meta.json`)) as PageMetaData;
        if (!metaData.published) {
            return undefined;
        }

        const mdContentRef = ref(contentStorage, `/${page}/page.md`);
        const mdContentArray = await getBytes(mdContentRef);

        //convert array buffer to string
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(mdContentArray);
    }
    catch(e){
        return undefined;
    }
}

export type PagesMetaData = {
    pages: {
        [key: string]: PageMetaData
    },
    otherFiles: {
        [key: string]: string
    }
}

export const getPagesMetaData = async () => {
    try {
        const pageEntries = await listAll(ref(contentStorage, `/`));

        const pageMeta : {[key: string]: PageMetaData} = {};

        for (const prefix of pageEntries.prefixes) {
            const pageId = prefix.name;
            try {
                pageMeta[pageId] = await getJsonContent(contentStorage, `/${pageId}/meta.json`);
                pageMeta[pageId].otherFiles = {};
                pageMeta[pageId].id = pageId;

                const specificPageEntries = await listAll(ref(contentStorage, `/${pageId}`));
                for (const file of specificPageEntries.items) {
                    const fileName = file.name.split('/').pop();
                    if (fileName && fileName !== 'meta.json' && fileName !== 'page.md') {
                        pageMeta[pageId].otherFiles[fileName] = await getDownloadURL(file);
                    }
                }
            }
            catch(e){
            }
        }

        const otherFiles : Record<string, string>= {};
        for (const file of pageEntries.items) {
            const fileName = file.name.split('/').pop();
            if (fileName && fileName !== 'meta.json' && fileName !== 'page.md') {
                otherFiles[fileName] = await getDownloadURL(file);
            }
        }

        return {
            pages: pageMeta,
            otherFiles: otherFiles,
        } as PagesMetaData;
    }
    catch(e){
        console.error(e);
        return undefined;
    }
}