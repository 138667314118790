import {Col, Container, Row} from "react-bootstrap";

export const ProcessingSensitiveDataPageComponent = () => {
    return (
        <Container className="mt-3 mb-5">
            <Row>
                <Col xs={0} xl={1} />
                <Col>
                    <div className="ds-panel">
                        <h1 className="ds-panel-header">My Practice Manager and Sensitive Data</h1>
                        <p>
                            Ensuring compliance with GDPR and other data protection laws is your responsibility when using My Practice Manager or any similar tool to process personal data.
                        </p>
                        <p>
                            The information below is designed to help you make informed decisions, but it does not constitute legal or professional advice. We strongly recommend consulting a qualified expert for guidance.
                        </p>
                    </div>

                    <div className="ds-panel mt-3">
                        <h2 className="ds-panel-header">Should You Enter Private Data (e.g., Patient or Employee Data) into My Practice Manager?</h2>
                        <p>
                            <strong>No.</strong> My Practice Manager does not currently have the required controls in place to ensure private data is processed in full compliance with relevant data protection laws.
                        </p>
                    </div>

                    <div className="ds-panel mt-3">
                        <h2 className="ds-panel-header">What Data Controls Does My Practice Manager Have?</h2>
                        <p>
                            My Practice Manager is secure by design. While we do not currently process sensitive data, we’ve implemented robust security measures to protect your information. These include:
                        </p>
                        <ul>
                            <li>Data is encrypted at rest and during transmission.</li>
                            <li>We minimize data storage, retaining only what is necessary. For example:
                                <ul>
                                    <li>We do not store the inputs or outputs of our AI tools, reducing opportunities for data breaches.</li>
                                    <li>We avoid collecting personal identifiers like names, using only business email addresses as necessary for site access.</li>
                                </ul>
                            </li>
                            <li>All processing is conducted on Google Cloud servers located in London, including the AI components.</li>
                            <li>Access to data is restricted to authorized personnel within our organization, only for legitimate purposes.</li>
                        </ul>
                    </div>

                    <div className="ds-panel mt-3">
                        <h2 className="ds-panel-header">What’s Missing for Handling Sensitive Data?</h2>
                        <p>
                            As a rule of thumb, do not input private data into any system that advises against it (including My Practice Manager).
                        </p>
                        <p>
                            For us to handle sensitive data confidently, we would need to introduce additional controls, such as:
                        </p>
                        <ul>
                            <li>Establishing a lawful basis for processing sensitive data.</li>
                            <li>Conducting regular security audits, ideally through third-party experts.</li>
                            <li>Implementing a data retention policy with regular reviews and audits.</li>
                            <li>Ensuring data subjects (e.g., patients, staff) have the right to access, correct, erase, or object to data processing.</li>
                            <li>Maintaining detailed records and audit trails.</li>
                            <li>Adhering to all NHS-specific data processing guidelines.</li>
                            <li>Data Protection Impact Assessment (DPIA)</li>
                            <li>Regular Sensitive Data Training for Staff</li>
                        </ul>
                        <p>
                            At this time, we do not have plans to implement these additional controls but will update you if this changes.
                        </p>
                    </div>

                    <div className="ds-panel mt-3">
                        <h2 className="ds-panel-header">How to Anonymize Data</h2>
                        <p>
                            Before entering any data into My Practice Manager, you must anonymize it to remove any potential for re-identification.
                        </p>
                        <p>
                            You are responsible for ensuring that data is anonymized correctly. Here are some tips to help:
                        </p>
                        <ul>
                            <li>Remove all personal identifiers (e.g., names, addresses, unique reference numbers).</li>
                            <li>Consider whether the information entered could reasonably lead to re-identification. For example:
                                <ul>
                                    <li>Does the data reference a rare condition that only a small number of patients have?</li>
                                    <li>Are job titles or roles mentioned that could uniquely identify the person (e.g., "former prime minister")?</li>
                                    <li>Could the timeline of events make it easy to identify the individual?</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col xs={0} xl={1} />
            </Row>
        </Container>
    );
};


export const ProcessingSensitiveDataPage = () => {

    return <ProcessingSensitiveDataPageComponent/>
}