import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";

export const AssistantHomeComponent = () => {

    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} xl={1}/>
            <Col xs={12} xl={10}>
                <div className={'ds-panel'}>

                    <h1 className={'ds-panel-header'}>My Assistant</h1>

                    <Container fluid className={'mt-4'}>
                        <Row>
                            <Col xs={12} lg={6} xl={4}>
                                <Card>
                                    <i className="bi bi-envelope card-icon-top"></i>
                                    <Card.Body>
                                    <Card.Title>Automated Emails</Card.Title>
                                        <Link to={`./email`}>
                                            <Button className={'w-100 mt-3'}>Setup Automated Emails</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4}>
                                <Card>
                                    <i className="bi bi-envelope-plus card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>AI Generated Emails</Card.Title>
                                        <Link to={`./ai-generated-email`}>
                                            <Button className={'w-100 mt-3'}>Setup AI Generated Emails</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
            <Col xs={0} xl={1}/>
        </Row>
    </Container>
}



export const AssistantHome = () => {
    return <AssistantHomeComponent />
}