import {Button, Form, FormGroup} from "react-bootstrap";
import {useState} from "react";
import './TaskNotesEditor.css';


export type TaskNotesEditorProps = {
    notes: string,
    onSave: (notes: string) => void,
    onCancel: () => void
}


export const TaskNotesEditor = ({notes, onSave, onCancel} : TaskNotesEditorProps) => {

    const [newNotes, setNewNotes] = useState<string>(notes);


    return (
        <Form className={'task-notes-editor'}>
            <FormGroup>
                <Form.Label>Notes</Form.Label>
                <Form.Control as="textarea" rows={15} placeholder="Enter notes here" value={newNotes} onChange={(e) => setNewNotes(e.target.value)}/>
            </FormGroup>
            <div className={'task-notes-editor-buttons'}>
                <Button variant={'primary'} className={'task-notes-editor-button'} onClick={() => onSave(newNotes)}>Save</Button>
                <Button variant={'secondary'} className={'task-notes-editor-button'} onClick={onCancel}>Cancel</Button>
            </div>

        </Form>
    );
}