import {Button, Form, FormGroup} from "react-bootstrap";
import React, {ChangeEvent, useState} from "react";


export type QuickAddFormProps = {
    generateTaskSuggestions: (
        quickAddText: string | null,
        base64String: { data: string, mimeType: string } | null
    ) => void,
}


export const QuickAddForm = ({generateTaskSuggestions}: QuickAddFormProps) => {

    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

    const [base64String, setBase64String] = useState<{ data: string, mimeType: string } | null>(null);
    const [quickAddText, setQuickAddText] = useState<string | null>(null);


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.currentTarget.files?.item(0);
        if (!selectedFile) return;
        setFile(selectedFile);

        if (selectedFile) {
            const fileUrl = URL.createObjectURL(selectedFile);
            setFileUrl(fileUrl);
            const reader = new FileReader();
            reader.onload = function (e) {
                const base64String = ((e.target?.result) as string).split(',')[1];
                const mimeType = selectedFile.type;
                setBase64String({data: base64String, mimeType});
            };
            reader.readAsDataURL(selectedFile);
        }
    }

    const generateTaskSuggestionWrapper = () => {
        generateTaskSuggestions(quickAddText, base64String);
        setFile(null);
        setFileUrl(undefined);
        setBase64String(null);
        setQuickAddText(null);
    }

    return (
        <Form>
            <FormGroup>
                <Form.Label column={false}>Enter your tasks in any format and our AI will generate the relevant tasks for
                    you</Form.Label>
                <Form.Control as={'textarea'} rows={15} placeholder={'Enter your tasks here'}
                              onChange={(e) => setQuickAddText(e.target.value)}/>
            </FormGroup>
            <FormGroup>
                <Form.Label column={false}>Document Upload</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} accept="image/*,.pdf"/>
                {file && file.type.startsWith('image/') && (
                    <img src={fileUrl} alt="Selected" style={{width: '10%', maxWidth: '600px', maxHeight: '100px', marginTop: '1px'}}/>
                )}
                {file && file.type === 'application/pdf' && (

                    <embed src={fileUrl} width="10%" height="100px" style={{marginTop: '20px'}}/>
                )}
            </FormGroup>
            <Button className={'mt-2 w-100'}
                    onClick={() => generateTaskSuggestionWrapper()}
                    disabled={(!quickAddText || quickAddText === '') && (!base64String)}>Generate Tasks</Button>
        </Form>
    )
}