import {Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import './page.css';
import {auth} from "../model/firebaseConnection";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {LoadableValue} from "../model/LoadableValue";
import {
    GetReferralInfoRequest,
    GetReferralInfoResponse,
    GetUserInfoRequest,
    GetUserInfoResponse,
    PracticeSubscription,
    ReferAFriendRequest,
    ReferAFriendResponse,
} from "../model/RequestTypes";
import {callAPI} from "../model/API";
import {AvailableReferral, PracticeReferral} from "../../functions/src/databaseSchema";
import {Referrals} from "./Referrals";
import {PracticeUser} from "../model/databaseSchema";
import {PracticeUsersEditor} from "./PracticeUsersEditor";
import {PracticeUsersLoadable, usePracticeUsers} from "../model/PracticeUsers";

type SettingsProps = {
    email: string
    logout: () => void
    practiceSubscription: LoadableValue<{ sub: PracticeSubscription }>
    referrals: LoadableValue<{
        availableReferrals: AvailableReferral[]
        referralsMade: PracticeReferral[]
    }>
    referAFriend: (email: string, referralType: string) => void
    practiceUsers: PracticeUsersLoadable
    addUser: (email: string) => void
    deleteUser: (user: PracticeUser) => void
}

const SettingsPageComponent = ({
                                   email,
                                   logout, practiceSubscription, referrals,
                                   referAFriend,
                                   practiceUsers,
                                   addUser,
                                   deleteUser
                               }: SettingsProps) => {

    return (
        <Container className={'mt-5'}>
            <Row>
                <Col>
                    <h1 className={'ds-screen-title'}>Settings</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} xl={1} xxl={2}/>
                <Col>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>General Account Settings </h2>
                        <p>{email}</p>
                        <Button onClick={logout}>Logout</Button>
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Practice Users</h2>
                        {practiceUsers.type === 'loaded' &&
                            <PracticeUsersEditor users={practiceUsers.users} deleteUser={deleteUser}
                                                 addUser={addUser}
                                                 currentUserEmail={email}/>}
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Referrals</h2>
                        <Referrals referrals={referrals} referAFriend={referAFriend}/>
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Subscription Details</h2>
                        {practiceSubscription.type === "loading" && <div>Loading...</div>}
                        {practiceSubscription.type === "loaded" && <div>
                            {practiceSubscription.sub.type === "free" && <div>
                                <p>Free</p>
                                <p>{practiceSubscription.sub.reason}</p>
                            </div>}
                            {practiceSubscription.sub.type === "trial" && <div>
                                <p>Trial</p>
                                <p>Ends
                                    on {new Date(practiceSubscription.sub.current_period_end).toLocaleDateString('en-GB')}</p>
                            </div>}
                            {practiceSubscription.sub.type === "inactive" && <div>
                                <p>Inactive</p>
                                <p>{practiceSubscription.sub.last_period_end && `Ended on ${new Date(practiceSubscription.sub.last_period_end).toLocaleDateString('en-GB')}`}</p>
                            </div>}
                            {practiceSubscription.sub.type === "active" && <div>
                                <p>Active</p>
                                <p>Renews
                                    on {new Date(practiceSubscription.sub.current_period_end).toLocaleDateString('en-GB')}</p>
                                {practiceSubscription.sub.foundingCustomer && <p>Founding Customer</p>}
                            </div>}
                        </div>}
                    </div>

                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Cookies</h2>
                        <Button className="termly-display-preferences"> Consent Preferences</Button>
                    </div>

                </Col>
                <Col xs={12} xl={1} xxl={2}/>
            </Row>
        </Container>
    );
}


export function SettingsPage() {

    const [practiceSubscription, setPracticeSubscription] = useState<LoadableValue<{
        sub: PracticeSubscription
    }>>({type: "loading"});
    const [referrals, setReferrals] = useState<LoadableValue<{
        availableReferrals: AvailableReferral[]
        referralsMade: PracticeReferral[]
    }>>({type: "loading"});

    const [practiceUsers, addUser, deleteUser] = usePracticeUsers();

    const navigate = useNavigate();

    const logout = async () => {
        await auth.signOut();
        navigate("/");
    }

    useEffect(() => {
        const f = async () => {
            try {
                const response = await callAPI<GetUserInfoRequest, GetUserInfoResponse>({type: "getUserInfo"});
                setPracticeSubscription({sub: response.practiceSubscription, type: "loaded"});

            } catch (e) {
                console.error(e);
            }
            try {
                const referralInfo = await callAPI<GetReferralInfoRequest, GetReferralInfoResponse>({type: "getReferralInfo"});
                setReferrals({
                    availableReferrals: referralInfo.availableReferrals,
                    referralsMade: referralInfo.referralsMade,
                    type: "loaded"
                });

            } catch (e) {
                console.error(e);
            }
        }
        f();
    }, []);

    const referAFriend = async (email: string, referralType: string) => {
        try {
            const response = await callAPI<ReferAFriendRequest, ReferAFriendResponse>({
                type: "referAFriend",
                email,
                referralType
            });
            setReferrals({
                availableReferrals: response.availableReferrals,
                referralsMade: response.referralsMade,
                type: "loaded"
            });
        } catch (e) {
            console.error(e);
        }
    }

    return <SettingsPageComponent email={auth.currentUser?.email!} logout={logout}
                                  practiceSubscription={practiceSubscription}
                                  referrals={referrals}
                                  referAFriend={referAFriend}
                                  addUser={addUser}
                                  deleteUser={deleteUser}
                                  practiceUsers={practiceUsers}

    />
}