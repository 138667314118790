import {ProgressIndicator} from "../common/ProgressIndicator";
import {ElaboratedTask} from "../../model/Tasks";
import {Task, ToggleTasksDesiredEndState} from "../../../functions/src/RequestTypes";
import React, {useState} from "react";
import {Alert, Button, Modal, Navbar} from "react-bootstrap";
import {TaskColumn} from "../tasks/TaskColumn";
import {QuickAddForm} from "../tasks/QuickAddForm";
import {CommonTasksList} from "../tasks/CommonTasksList";
import {TaskFilterDropdown} from "./TaskFilter";
import {Link} from "react-router-dom";
import "./Dashboard.css";

const ProgressIndicatorWrapped = ({text, maxSeconds}: { text: string, maxSeconds: number }) => {
    return <div className={'progress-indicator-wrapped'}>
        <ProgressIndicator text={text} maxSeconds={maxSeconds}/>
    </div>
}


export type DashboardProps = {
    error: string | undefined,
    practiceName: string | undefined,
    tasks: ElaboratedTask[],
    taskSuggestions: Task[],
    addTasks: (tasks: Task[]) => void
    today: Date,
    changeTasksCompletionState: (tasks: Task[], desiredEndState: ToggleTasksDesiredEndState) => void
    editTask: (task: Task) => void
    deleteTask: (task: Task) => void
    logEventInternal: (name: string, params?: any) => void
    generateTaskSuggestions: (prompt: string | null, base64String: {
        data: string,
        mimeType: string
    } | null) => Promise<void>
    dynamicTaskSuggestions?: Task[],
    loading: boolean
    isFoundingCustomer: boolean
    trialDetails?: {
        endDate: Date
    }
    hideNoPatientDataAlert?: boolean
    users: string[]
}

export const DashboardComponent = ({
                                       error,
                                       tasks,
                                       taskSuggestions,
                                       practiceName,
                                       addTasks,
                                       today,
                                       changeTasksCompletionState,
                                       editTask,
                                       deleteTask,
                                       logEventInternal,
                                       generateTaskSuggestions,
                                       dynamicTaskSuggestions,
                                       loading,
                                       isFoundingCustomer,
                                        trialDetails,
                                       hideNoPatientDataAlert,
    users
                                   }: DashboardProps) => {

    const [showQuickAdd, setShowQuickAdd] = useState(false);
    const [showDynamicSuggestions, setShowDynamicSuggestions] = useState(false);
    const [dynamicSuggestionsLoading, setDynamicSuggestionsLoading] = useState(false);
    const [tagsFilter, setTagsFilter] = useState<string[]>([]);
    const [ownersFilter, setOwnersFilter] = useState<string[]>([]);

    const wrappedGenerateTaskSuggestions = async (prompt: string | null, base64String: {
        data: string,
        mimeType: string
    } | null) => {
        setDynamicSuggestionsLoading(true);
        setShowQuickAdd(false);
        setShowDynamicSuggestions(true);
        await generateTaskSuggestions(prompt, base64String);
        setDynamicSuggestionsLoading(false);
    }

    const addDynamicTask = (tasks: Task[]) => {
        addTasks(tasks);
        setShowDynamicSuggestions(false);
    }

    const changeTaskCompletionState = async (task: Task, desiredEndState: ToggleTasksDesiredEndState) => {
        await changeTasksCompletionState([task], desiredEndState);
    }

    if (loading && !error) {
        return <Modal show={true} size={'lg'}>
            <Modal.Header>
                <Modal.Title>Loading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProgressIndicatorWrapped text={'loading tasks'} maxSeconds={20}/>
            </Modal.Body>
        </Modal>
    }

    const tags : string[] = Array.from(tasks.map(task => task.task.tags ?? []).reduce((acc, tags) => {
        tags.forEach(tag => {
            acc.add(tag)
        });
        return acc;
    }, new Set<string>()).values());

    tags.push("No Tag");

    const filteredTasks =
        tasks.filter(task => tagsFilter.length === 0 || task.task.tags?.some(tag => tagsFilter.includes(tag)) || (tagsFilter.includes("No Tag") && (task.task.tags ?? []).length === 0))
            .filter(task => ownersFilter.length === 0 || (task.task.owner && ownersFilter.includes(task.task.owner)) || (ownersFilter.includes("No Owner") && (task.task.owner ?? '') === ''));


    const weeklyTasks = filteredTasks.filter(task => task.task.type === 'recurring' && task.task.frequency.type === 'weekly');
    const weeklyTaskSuggestions = taskSuggestions.filter(task => task.type === 'recurring' && task.frequency.type === 'weekly');
    const monthlyTasks = filteredTasks.filter(task => task.task.type === 'recurring' && task.task.frequency.type === 'monthly');
    const monthlyTaskSuggestions = taskSuggestions.filter(task => task.type === 'recurring' && task.frequency.type === 'monthly');
    const quarterlyTasks = filteredTasks.filter(task => task.task.type === 'recurring' && task.task.frequency.type === 'quarterly');
    const quarterlyTaskSuggestions = taskSuggestions.filter(task => task.type === 'recurring' && task.frequency.type === 'quarterly');
    const yearlyTasks = filteredTasks.filter(task => task.task.type === 'recurring' && task.task.frequency.type === 'yearly');
    const yearlyTaskSuggestions = taskSuggestions.filter(task => task.type === 'recurring' && task.frequency.type === 'yearly');

    const adhocTasks = filteredTasks.filter(task => task.task.type === 'oneOff');
    const adhocTaskSuggestions = taskSuggestions.filter(task => task.type === 'oneOff');


    return (
        <div className={'home-page-content'}>
            <div className={'home-page-header'}>
                <div className={'d-flex flex-row flex-grow-1 align-items-start'}>
                    <div className={'home-page-header-title'}>
                        <h1>{`Dashboard -   ${practiceName ?? ''}`}</h1>
                    </div>
                    {isFoundingCustomer &&
                        <div className={'ds-nav-bar-founding-customer'}>
                            <div>Founding Customer</div>
                            <div className={'thank-you'}>Thank you</div>
                        </div>
                    }
                    {trialDetails &&
                        <div className={'trial-badge'}>
                            <div>Free Trial</div>
                            <div className={'trial-badge-end-date'}>Ends {trialDetails.endDate.toLocaleDateString("en-GB")}</div>
                        </div>
                    }
                </div>

                <div className={'task-dashboard-header-actions'}>
                    <TaskFilterDropdown nameBase={"Owner "} filters={[...users, "No Owner"]} onFilterChange={setOwnersFilter}/>
                    <TaskFilterDropdown nameBase={"Tag "} filters={tags} onFilterChange={setTagsFilter}/>
                    <Button variant={'primary'} onClick={() => setShowQuickAdd(!showQuickAdd)}>Add with AI</Button>
                    <Link to={'/task/onboarding'} className={"task-dashboard-header-actions-onboarding"}><Button variant={'secondary'}>Onboarding</Button></Link>
                </div>
            </div>

            {!hideNoPatientDataAlert &&
                <Alert variant={'warning'} className={'m-2'} dismissible>
                    <Alert.Heading>No Patient or Personally Identifiable data</Alert.Heading>
                    <p>When using this software you must not enter any patient or personally identifiable data. If you
                        do, you do so at your own risk and your own data breach.
                        See our <Link to={'/data/sensitive-data-guide'}>Sensitive Data Guide</Link> for more information.</p>
                </Alert>
            }

            {error && <Alert variant={'danger'} dismissible>{error}</Alert>}
            <div className={'home-page-task-columns'}>
                <TaskColumn title={"My Tasks"}
                            tasks={adhocTasks}
                            taskSuggestions={adhocTaskSuggestions}
                            addTasks={addTasks}
                            editTask={editTask}
                            frequency={'oneoff'}
                            today={today}
                            changeTaskCompletionState={changeTaskCompletionState}
                            deleteTask={deleteTask}
                            logEventInternal={logEventInternal}
                            users={users}
                />
                <TaskColumn title={"Weekly"}
                            tasks={weeklyTasks}
                            taskSuggestions={weeklyTaskSuggestions}
                            addTasks={addTasks}
                            frequency={'weekly'}
                            today={today}
                            changeTaskCompletionState={changeTaskCompletionState}
                            editTask={editTask}
                            deleteTask={deleteTask}
                            logEventInternal={logEventInternal}
                            users={users}
                />
                <TaskColumn title={"Monthly"}
                            tasks={monthlyTasks}
                            taskSuggestions={monthlyTaskSuggestions}
                            addTasks={addTasks}
                            frequency={'monthly'}
                            today={today}
                            changeTaskCompletionState={changeTaskCompletionState}
                            editTask={editTask}
                            deleteTask={deleteTask}
                            logEventInternal={logEventInternal}
                            users={users}
                />
                <TaskColumn title={"Quarterly"}
                            tasks={quarterlyTasks}
                            taskSuggestions={quarterlyTaskSuggestions}
                            addTasks={addTasks}
                            frequency={'quarterly'}
                            today={today}
                            changeTaskCompletionState={changeTaskCompletionState}
                            editTask={editTask}
                            deleteTask={deleteTask}
                            logEventInternal={logEventInternal}
                            users={users}
                />
                <TaskColumn title={"Yearly"}
                            tasks={yearlyTasks}
                            taskSuggestions={yearlyTaskSuggestions}
                            addTasks={addTasks}
                            frequency={'yearly'}
                            today={today}
                            changeTaskCompletionState={changeTaskCompletionState}
                            editTask={editTask}
                            deleteTask={deleteTask}
                            logEventInternal={logEventInternal}
                            users={users}
                />


            </div>
            <Modal show={showQuickAdd} onHide={() => setShowQuickAdd(false)} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Add With AI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QuickAddForm generateTaskSuggestions={wrappedGenerateTaskSuggestions}/>
                </Modal.Body>
            </Modal>
            <Modal show={showDynamicSuggestions} onHide={() => setShowDynamicSuggestions(false)} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Dynamic Suggestions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {dynamicSuggestionsLoading ?
                        <ProgressIndicatorWrapped text={'Generating, this may take a while - AI at work'}
                                                  maxSeconds={60 * 5}/> :
                        dynamicTaskSuggestions &&
                        <CommonTasksList taskSuggestions={dynamicTaskSuggestions} addTasks={addDynamicTask}
                                         logEventInternal={logEventInternal}/>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}