import React, {ReactNode} from 'react';
import {LoginState} from "../../model/Authentication";
import {VerifyEmail} from "../authentication/VerifyEmail";
import {Authenticate} from "../authentication/Authenticate";
import {Outlet} from "react-router-dom";

export type LoginRequiredWrapperProps = {
    children: ReactNode
    pathname: string
    loginState: LoginState
    verificationRequested: () => void
    registerNewUser: (email: string, password: string) => Promise<void>
    loginUser: (email: string, password: string) => Promise<void>
    authError?: string
    loginWithMagicLink: (email: string) => Promise<void>
    resetPassword: (email: string) => Promise<void>
}

const noLoginPaths = [
    '/_auth',
    '/demo',
    '/data/sensitive-data-guide'
]

const noLoginPathRoots = [
    '/demo',
]

export const LoginRequiredWrapper = ({
                                         children,
                                         pathname,
                                         loginState,
                                         verificationRequested,
                                         registerNewUser,
                                         loginUser,
                                         authError,
                                         loginWithMagicLink,
                                         resetPassword
                                     }: LoginRequiredWrapperProps) => {

    console.log('pathname', pathname);
    if (noLoginPaths.includes(pathname)) {
        return <><Outlet/></>
    }
    if(noLoginPathRoots.some((root) => pathname.startsWith(root))) {
        return <><Outlet/></>
    }

    if (loginState.state === 'unverified') {
        return <VerifyEmail verificationRequested={verificationRequested}/>
    }

    if (loginState.state === 'logged-out') {
        return <Authenticate registerNewUser={registerNewUser}
                             loginUser={loginUser}
                             authError={authError}
                             loginWithMagicLink={loginWithMagicLink}
                             resetPassword={resetPassword}
                             pathname={pathname}
        />

    }

    return (
        <>{children}</>
    )
}