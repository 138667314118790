import React, {useEffect, useState} from "react";
import {ProgressBar, Spinner} from "react-bootstrap";
import './ProgressIndicator.css';

type ProgressIndicatorProps = {
    text: string
    maxSeconds: number
}

export const ProgressIndicator = ({text, maxSeconds}: ProgressIndicatorProps) => {

    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(currentProgress => {
                    if (currentProgress >= 100) {
                        clearInterval(interval);
                        return currentProgress;
                    } else {
                        return currentProgress + 1;
                    }
                }
            );

        }, (maxSeconds * 1000) / 100);
        return () => clearInterval(interval);
    }, [maxSeconds]);

    return (
        <div className={'progress-indicator'}>
            <Spinner animation={'border'} className={'progress-spinner'}/>
            <p className={'mt-3'}>{text}</p>
            <ProgressBar className={'w-100 mt-3'} now={progress}/>
        </div>
    )


}