import {Nav, Navbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ReactNode} from "react";
import {UserState} from "./LayoutState";

const ComingSoonLink = ({children, id}: { children: ReactNode, id: string }) => {
    return (
        <OverlayTrigger placement={'bottom'} overlay={<Tooltip id={'tooltip-' + id}>Coming Soon</Tooltip>}>
            <Nav.Link className={'nav-bar-link-coming-soon'}>{children}</Nav.Link>
        </OverlayTrigger>
    )
}

export type MyPMNavBarProps = {
    pathname: string,
    userState: UserState
}

export const MyPMNavBar = ({pathname, userState}: MyPMNavBarProps) => {
    return (
        <Navbar className={'ds-nav-bar'} expand={'lg'}>
            <Navbar.Brand className={'ds-nav-bar-brand'} href={'/'}>
                <img width={48} height={48} src={'/logo.png'} alt={'GP Practice Manager'}/>
                <Navbar.Text>My Practice Manager</Navbar.Text>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">

                <Nav className="ds-nav-bar-collapse">
                    {pathname !== '/demo' &&
                        (userState.type !== "loaded" || (userState.type === 'loaded' && !userState.userConfig))
                        && <Nav.Link href={'/demo'} active={pathname === '/demo'} className={'nav-bar-link-subscribe-now'}>Live Demo</Nav.Link>}
                    {pathname !== '/demo' && <Nav.Link href={'/'} active={pathname === '/'}>Home</Nav.Link>}
                    {pathname !== '/demo' && <Nav.Link href={'/task/dashboard'} active={pathname === '/task/dashboard'}>Task Manager</Nav.Link>}
                    {userState.type === 'loaded' && userState.userConfig &&
                        <Nav.Link href={'/compliance'} active={pathname.startsWith('/compliance')}>Compliance</Nav.Link>}
                    {userState.type === 'loaded' && userState.userConfig &&
                        <Nav.Link href={'/assistant'} active={pathname.startsWith('/assistant')}>My Assistant</Nav.Link>}
                    {userState.type === 'loaded' && userState.userConfig &&
                        <Nav.Link href={'/settings'} active={pathname.startsWith('/settings')}>Settings</Nav.Link>}
                    {pathname === '/demo' && <Nav.Link href={'/'} className={'nav-bar-link-subscribe-now'}>
                        Subscribe Now
                    </Nav.Link>}
                    <div className={'d-flex flex-column justify-content-center'}>
                        <a className={'ds-nav-bar-help'} href={"mailto:help@mypracticemanager.co.uk"}>Help?</a>
                    </div>
                </Nav>

            </Navbar.Collapse>
        </Navbar>
    )
}