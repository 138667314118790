import {useSearchParams} from "react-router-dom";
import {VerifyEmailAuthOperation} from "./VerifyEmailAuthOperation";
import {ResetPasswordAuthOperation} from "./ResetPasswordAuthOperation";
import {isSignInWithEmailLink} from "firebase/auth";
import {auth} from "../../model/firebaseConnection";
import {FinishMagicLinkSigninAuthOperation} from "./FinishMagicLinkSigninAuthOperation";


export const AuthenticationAction = () => {
    const [searchParams] = useSearchParams();
    const action = searchParams.get('mode');

    if(action === 'resetPassword') {
        return <ResetPasswordAuthOperation></ResetPasswordAuthOperation>
    }
    if(action === 'verifyEmail') {
        return <VerifyEmailAuthOperation></VerifyEmailAuthOperation>
    }
    if(isSignInWithEmailLink(auth, window.location.href))
    {
        return <FinishMagicLinkSigninAuthOperation/>
    }

    return (
        <div>
            Unknown action {action}
        </div>
    )

}