

export const logError = (message: string) => {
    console.error(message);
}

export interface Unsubscribable {
    unsubscribe(): void;
}

export interface UpdateHandler<T> {
    onUpdate(t: T) : void;
}

export class Subscription<T> implements Unsubscribable {
    #handler: WeakRef<UpdateHandler<T>> | undefined;

    constructor(handler: UpdateHandler<T>) {
        this.#handler = new WeakRef(handler);
    }

    unsubscribe() {
        this.#handler = undefined;
    }

    isActive() {
        return !!(this.#handler?.deref());
    }

    trigger(t : T) {
        const handler = this.#handler?.deref();

        if (!handler) {
            return;
        }
        handler.onUpdate(t);
    }
}

export class Subject<T> {
    #subscriptions: Subscription<T>[] = [];

    subscribe(handler: UpdateHandler<T>) {
        const subscription = new Subscription(handler);
        this.#subscriptions.push(subscription);
        return subscription;
    }

    next(t : T) {
        const inactive = this.#subscriptions.filter((s) => !s.isActive());
        inactive.forEach((s) => {
            const index = this.#subscriptions.indexOf(s);
            this.#subscriptions.splice(index, 1);
        });
        this.#subscriptions.forEach((s) => {
                try{
                    s.trigger(t)
                }
                catch(e: any){
                    if(e?.message)
                    {
                        logError(e.message);
                    }
                }
        }

        );
    }

    dispose() {
        this.#subscriptions.forEach((s) => s.unsubscribe());
        this.#subscriptions = [];
    }
}