import {Task} from "../../model/RequestTypes";
import './CommonTasksList.css';
import {Badge, Button, FormCheck} from "react-bootstrap";
import {useState} from "react";
import {addDateNumberSuffix, monthsOfYear3LetterCap} from "./Dates";


const TaskCardFrequency = ({task} : {task: Task}) => {
    if(task.type === 'oneOff') {
        return <div>One Off</div>
    }
    else {
        if(task.frequency.type === 'daily') {
            return <div>Daily</div>
        }
        else if(task.frequency.type === 'weekly') {
            return <div>Weekly on {task.frequency.dayOfWeek}</div>
        }
        else if(task.frequency.type === 'monthly') {
            return <div>Monthly on {addDateNumberSuffix(task.frequency.day)}</div>
        }
        else if(task.frequency.type === 'yearly') {
            return <div>Yearly on {addDateNumberSuffix(task.frequency.day)} of {monthsOfYear3LetterCap[task.frequency.month]}</div>
        }
        else if(task.frequency.type === 'quarterly') {
            return <div>Quarterly on {addDateNumberSuffix(task.frequency.day)} of {addDateNumberSuffix(task.frequency.monthNumber+1)} month</div>
        }
        else {
            return <div>Unknown</div>
        }
    }
}


export type CommonTasksListProps = {
    taskSuggestions: Task[],
    addTasks: (tasks: Task[]) => void,
    logEventInternal: (eventName: string, eventParams: any) => void
}

export const CommonTasksList = ({taskSuggestions, addTasks, logEventInternal} : CommonTasksListProps) => {

    const [selectedTasks, setSelectedTasks] = useState<Record<string,Task>>({});

    const addTaskWrapper = () => {
        addTasks(Object.values(selectedTasks));
    }

    return (
        <div>
            <div className={'w-100 d-flex flex-row justify-content-end flex-wrap'}>
            {taskSuggestions.length > 0 &&
                <Button onClick={() => {
                    if(Object.keys(selectedTasks).length === taskSuggestions.length) {
                        setSelectedTasks({});
                    }
                    else{
                    setSelectedTasks(taskSuggestions.reduce((acc, task) => {
                        acc[task.id] = task;
                        return acc;
                    }, {} as Record<string, Task>))}
                }}>{Object.keys(selectedTasks).length === taskSuggestions.length ? "Uncheck All" : "Check All"}</Button>}
            </div>
        <div className={'common-task-list-wrapper'}>
            {taskSuggestions.map(task => {
                return (
                    <div key={task.id} className={'task-suggestion-card'}>
                        <div>
                            <div className={'task-suggestion-card-name'}>{task.name}</div>
                            <div className={'task-suggestion-card-details'}>{task.details}</div>
                            <div className={'task-suggestion-card-frequency'}>
                                <TaskCardFrequency task={task}/>
                            </div>
                        </div>
                        <FormCheck size={50}
                                   type={'checkbox'}
                                   className={'task-suggestion-card-check'}
                                   checked={!!selectedTasks[task.id]}
                                   onChange={(e) => {
                                       if(e.target.checked) {
                                           setSelectedTasks({...selectedTasks, [task.id]: task})
                                       }
                                        else {
                                             setSelectedTasks(Object.keys(selectedTasks).reduce((acc, key) => {
                                                    if(key !== task.id) {
                                                        acc[key] = selectedTasks[key];
                                                    }
                                                    return acc;
                                                }, {} as Record<string, Task>))
                                        }
                                   }}

                        />
                    </div>
                )
            })}
            {taskSuggestions.length === 0 && <div className={'common-task-list-empty'}>No common tasks available</div>}
        </div>
        <Button className={'w-100 mt-4'} onClick={() => addTaskWrapper()} disabled={Object.keys(selectedTasks).length === 0}>Add Selected <Badge bg={"secondary"}>{Object.keys(selectedTasks).length}</Badge></Button>
        </div>
    )
}