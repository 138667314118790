import * as yup from "yup";
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import React from "react";
import './TaskForm.css';
import {Task} from "../../model/RequestTypes";


type TaskParams = {
    taskName: string;
    taskDetails: string;
}

type FieldOption = {
    value: any;
    label: string;
}

type FieldType =
    { type: 'text' }
    | { type: 'textarea', rows: number }
    | { type: 'number' }
    | { type: 'select', options: FieldOption[] }

type FieldDefinition = {
    name: string;
    label: string;
    type: FieldType;
    required: boolean;
}

type TaskFormCompInternalProps<T extends TaskParams> = {
    submitTask: (task: T) => void;
    initialValues: T;
    fields: FieldDefinition[];
    submitText: string;
    resetOnSubmit?: boolean;
}


const TaskFormCompInternal = <T extends TaskParams>({
                                                        submitTask,
                                                        initialValues,
                                                        fields,
                                                        submitText,
                                                        resetOnSubmit
                                                    }: TaskFormCompInternalProps<T>) => {

    const schema = yup.object().shape({
        taskName: yup.string().required(),
        ...fields.reduce((acc: any, field: FieldDefinition) => {
            if (field.type.type === 'text' || field.type.type === 'textarea') {
                acc[field.name] = yup.string();
                if (field.required) {
                    acc[field.name] = acc[field.name].required();
                }
            } else if (field.type.type === 'number') {
                acc[field.name] = yup.number();
                if (field.required) {
                    acc[field.name] = acc[field.name].required();
                }
            }
            return acc;
        }, {})
    });

    return (
        <Formik

            validationSchema={schema}
            onSubmit={(values, helpers) => {
                submitTask(values);
                if (resetOnSubmit) {
                    helpers.resetForm();
                }
            }}
            initialValues={{
                ...initialValues
            }}
        >
            {({handleSubmit, handleChange, values, touched, errors}) => {
                return (
                    <Form noValidate onSubmit={handleSubmit} className={'d-flex flex-column flex-grow-1'}>
                        {fields.map((field) => {
                            const vAny = values as any;
                            const tAny = touched as any;
                            const eAny = errors as any;
                            return (
                                <Form.Group key={field.name} className="mb-3" controlId={'form' + field.name}>
                                    <Form.Label column={true}>{field.label}</Form.Label>
                                    {field.type.type === 'select' ?
                                        <Form.Select
                                            name={field.name}
                                            value={vAny[field.name]}
                                            onChange={handleChange}
                                            isValid={tAny[field.name] && !eAny[field.name]}
                                            isInvalid={!!eAny[field.name]}>
                                            {
                                                field.type.options.map((option) => {
                                                    return <option key={option.value}
                                                                   value={option.value}>{option.label}</option>
                                                })
                                            }
                                        </Form.Select>
                                        :
                                        <Form.Control
                                            type={field.type.type}
                                            as={field.type.type === 'textarea' ? 'textarea' : undefined}
                                            rows={field.type.type === 'textarea' ? field.type.rows : undefined}
                                            name={field.name}
                                            value={vAny[field.name]}
                                            onChange={handleChange}
                                            isValid={tAny[field.name] && !eAny[field.name]}
                                            isInvalid={!!eAny[field.name]}>
                                        </Form.Control>
                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {eAny[field.name]}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )
                        })}
                        <Button type={'submit'} data-testId={'add-task-button'}>{submitText}</Button>
                    </Form>
                )
            }}
        </Formik>)
}

export type TaskFormProps = {
    initialTask: Task;
    submitTask: (task: Task) => void;
    submitText: string;
    today: Date;
    resetOnSubmit?: boolean;
}


export const TaskForm = ({initialTask, submitTask, submitText, today, resetOnSubmit}: TaskFormProps) => {

    if (initialTask.type === 'oneOff') {
        const nextFriday =
            today.getDay() === 5 ?
                today.getTime() + (7 * 24 * 60 * 60 * 1000) :
                today.getDay() === 6 ?
                    today.getTime() + (6 * 24 * 60 * 60 * 1000) :
                    today.getTime() - (today.getDay() * 24 * 60 * 60 * 1000) + (5 * 24 * 60 * 60 * 1000);
        return <TaskFormCompInternal
            submitTask={(values) => submitTask({
                type: 'oneOff',
                id: initialTask.id,
                name: values.taskName,
                details: values.taskDetails,
                dueDate: nextFriday
            })}
            initialValues={{taskName: initialTask.name, taskDetails: initialTask.details ?? ''}}
            fields={[
                {name: 'taskName', label: 'Task Name', type: {type: 'text'}, required: true},
                {name: 'taskDetails', label: 'Task Details', type: {type: 'textarea', rows: 5}, required: false}
            ]}
            submitText={submitText}
            resetOnSubmit={resetOnSubmit}
        />
    } else {

        if (initialTask.frequency.type === 'daily') {
            return <TaskFormCompInternal
                submitTask={(values) => submitTask({
                    type: 'recurring',
                    id: initialTask.id,
                    frequency: {type: 'daily'},
                    name: values.taskName,
                    details: values.taskDetails
                })}
                initialValues={{taskName: initialTask.name, taskDetails: initialTask.details ?? ''}}
                fields={[
                    {name: 'taskName', label: 'Task Name', type: {type: 'text'}, required: true},
                    {name: 'taskDetails', label: 'Task Details', type: {type: 'textarea', rows: 5}, required: false}
                ]}
                submitText={submitText}
                resetOnSubmit={resetOnSubmit}
            />
        }

        if (initialTask.frequency.type === 'weekly') {
            return <TaskFormCompInternal
                submitTask={(values) => submitTask({
                    type: 'recurring',
                    id: initialTask.id,
                    frequency: {type: 'weekly', dayOfWeek: values.dayOfWeek as any},
                    name: values.taskName,
                    details: values.taskDetails
                })}
                initialValues={{
                    taskName: initialTask.name,
                    dayOfWeek: initialTask.frequency.dayOfWeek,
                    taskDetails: initialTask.details ?? ''
                }}
                fields={[
                    {name: 'taskName', label: 'Task Name', type: {type: 'text'}, required: true},
                    {name: 'taskDetails', label: 'Task Details', type: {type: 'textarea', rows: 5}, required: false},
                    {
                        name: 'dayOfWeek', label: 'Day of the Week', type: {
                            type: 'select', options: [
                                {value: 'sunday', label: 'Sunday'},
                                {value: 'monday', label: 'Monday'},
                                {value: 'tuesday', label: 'Tuesday'},
                                {value: 'wednesday', label: 'Wednesday'},
                                {value: 'thursday', label: 'Thursday'},
                                {value: 'friday', label: 'Friday'},
                                {value: 'saturday', label: 'Saturday'},
                            ]
                        }, required: true
                    },
                ]}
                submitText={submitText}
                resetOnSubmit={resetOnSubmit}
            />
        }

        if (initialTask.frequency.type === 'monthly') {
            return <TaskFormCompInternal
                submitTask={(values) => submitTask({
                    type: 'recurring',
                    id: initialTask.id,
                    frequency: {type: 'monthly', day: values.day},
                    name: values.taskName,
                    details: values.taskDetails
                })}
                initialValues={{
                    taskName: initialTask.name,
                    day: initialTask.frequency.day,
                    taskDetails: initialTask.details ?? ''
                }}
                fields={[
                    {name: 'taskName', label: 'Task Name', type: {type: 'text'}, required: true},
                    {name: 'taskDetails', label: 'Task Details', type: {type: 'textarea', rows: 5}, required: false},
                    {name: 'day', label: 'Day of the Month', type: {type: 'number'}, required: true},
                ]}
                submitText={submitText}
                resetOnSubmit={resetOnSubmit}
            />
        }

        if (initialTask.frequency.type === 'quarterly') {
            return <TaskFormCompInternal
                submitTask={(values) => submitTask({
                    type: 'recurring',
                    id: initialTask.id,
                    frequency: {type: 'quarterly', monthNumber: values.monthNumber as any, day: values.day},
                    name: values.taskName,
                    details: values.taskDetails
                })}
                initialValues={{
                    taskName: initialTask.name,
                    monthNumber: initialTask.frequency.monthNumber,
                    day: initialTask.frequency.day,
                    taskDetails: initialTask.details ?? ''
                }}
                fields={[
                    {name: 'taskName', label: 'Task Name', type: {type: 'text'}, required: true},
                    {name: 'taskDetails', label: 'Task Details', type: {type: 'textarea', rows: 5}, required: false},
                    {
                        name: 'monthNumber',
                        label: 'Month in Quarter',
                        type: {
                            type: 'select', options: [{
                                value: 0,
                                label: 'First (eg Jan/Apr/Jul/Oct)'
                            }, {
                                value: 1,
                                label: 'Second (eg Feb/May/Aug/Nov)'
                            }, {
                                value: 2,
                                label: 'Third (eg Mar/Jun/Sep/Dec)'
                            }]
                        },
                        required: true
                    },
                    {name: 'day', label: 'Day of the Month', type: {type: 'number'}, required: true},
                ]}
                submitText={submitText}
                resetOnSubmit={resetOnSubmit}
            />
        }

        if (initialTask.frequency.type === 'yearly') {
            return <TaskFormCompInternal
                submitTask={(values) => submitTask({
                    type: 'recurring',
                    id: initialTask.id,
                    frequency: {type: 'yearly', month: values.month, day: values.day},
                    name: values.taskName,
                    details: values.taskDetails
                })}
                initialValues={{
                    taskName: initialTask.name,
                    month: initialTask.frequency.month,
                    day: initialTask.frequency.day,
                    taskDetails: initialTask.details ?? ''
                }}
                fields={[
                    {name: 'taskName', label: 'Task Name', type: {type: 'text'}, required: true},
                    {name: 'taskDetails', label: 'Task Details', type: {type: 'text'}, required: false},
                    {
                        name: 'month', label: 'Month', type: {
                            type: 'select',
                            options: [
                                {value: 0, label: 'January'},
                                {value: 1, label: 'February'},
                                {value: 2, label: 'March'},
                                {value: 3, label: 'April'},
                                {value: 4, label: 'May'},
                                {value: 5, label: 'June'},
                                {value: 6, label: 'July'},
                                {value: 7, label: 'August'},
                                {value: 8, label: 'September'},
                                {value: 9, label: 'October'},
                                {value: 10, label: 'November'},
                                {value: 11, label: 'December'},
                            ]
                        }, required: true
                    },
                    {name: 'day', label: 'Day of the Month', type: {type: 'number'}, required: true},
                ]}
                submitText={submitText}
                resetOnSubmit={resetOnSubmit}
            />
        }
    }


    return null;
}

export type TaskCreationFormProps = {
    submitTask: (task: Task) => void;
    submitText: string;
    today: Date;
    defaultTaskFrequency?: 'weekly' | 'monthly' | 'quarterly' | 'yearly' | 'oneOff'
}

export const TaskCreationForm = ({submitTask, submitText, today, defaultTaskFrequency} : TaskCreationFormProps) => {

    const [frequency, setFrequency] = React.useState<'weekly' | 'monthly' | 'quarterly' | 'yearly' | 'oneOff'>(defaultTaskFrequency ?? 'weekly');

    let initialTask : Task;

    if(frequency === 'oneOff'){
        initialTask = {
            type: 'oneOff',
            id: '',
            name: '',
            details: '',
            dueDate: today.getTime()
        }
    }
    else{
        switch(frequency){
            case 'weekly':
                initialTask = {
                    type: 'recurring',
                    id: '',
                    frequency: {type: 'weekly', dayOfWeek: 'monday'},
                    name: '',
                    details: ''
                }
                break;
            case 'monthly':
                initialTask = {
                    type: 'recurring',
                    id: '',
                    frequency: {type: 'monthly', day: 1},
                    name: '',
                    details: ''
                }
                break;
            case 'quarterly':
                initialTask = {
                    type: 'recurring',
                    id: '',
                    frequency: {type: 'quarterly', monthNumber: 0, day: 1},
                    name: '',
                    details: ''
                }
                break;
            case 'yearly':
                initialTask = {
                    type: 'recurring',
                    id: '',
                    frequency: {type: 'yearly', month: 0, day: 1},
                    name: '',
                    details: ''
                }
                break
        }
    }


    return <div>
        <Form.Group className={'mt-3'}>
            <Form.Label column={false}>Frequency</Form.Label>
            <Form.Select value={frequency} onChange={(e) => setFrequency(e.target.value as any)}>
                <option value={'weekly'}>Weekly</option>
                <option value={'monthly'}>Monthly</option>
                <option value={'quarterly'}>Quarterly</option>
                <option value={'yearly'}>Yearly</option>
                <option value={'oneOff'}>One-off</option>
            </Form.Select>
        </Form.Group>
        <TaskForm initialTask={initialTask} submitTask={submitTask} submitText={submitText} today={today} resetOnSubmit={true}/>
    </div>
}


