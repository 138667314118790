import {DocumentLookup, Task} from "../model/RequestTypes";
import "./SearchResultCard.css";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {ElaboratedTask, elaborateTask} from "../model/Tasks";
import {addDateNumberSuffix, monthsOfTheYearFirstCap} from "../components/tasks/Dates";

const convertFrequencyToString = (task : ElaboratedTask) => {
    if(task.task.type === 'oneOff') {
        return 'One-off';
    }
    else{
        switch (task.task.frequency.type) {
            case 'daily':
                return 'Daily';
            case 'weekly':
                return 'Weekly on ' + task.task.frequency.dayOfWeek;
            case 'monthly':
                return 'Monthly on the ' + addDateNumberSuffix(task.task.frequency.day);
            case 'quarterly':
                return 'Quarterly on the ' + addDateNumberSuffix(task.task.frequency.monthNumber + 1) + ' month in the quarter, on the ' + addDateNumberSuffix(task.task.frequency.day) + " day";
            case 'yearly':
                return 'Yearly on the ' + addDateNumberSuffix(task.task.frequency.day) + ' day of ' + monthsOfTheYearFirstCap[task.task.frequency.month];
        }
    }
}


export type SearchResultCardTaskProps = {
    lookup: DocumentLookup
    task: ElaboratedTask
}

export const SearchResultCardTask = ({lookup, task}: SearchResultCardTaskProps) => {
    return (
        <div className={'search-result-card'}>
            <div>
                <i className="bi bi-card-text search-result-type-icon"></i>
                <p className={'text-center'}>Task</p>
            </div>

            <div className={'search-result-card-body'}>
                <p>{task.task.name}</p>
                <p>{task.task.details}</p>
                <p>{convertFrequencyToString(task)}</p>
                <p>Due: {task.dueDate.toLocaleDateString("en-GB")}</p>
                {task.taskState === 'late' && <p className={'text-danger'}>Late</p>}
            </div>
            <div className={'d-flex flex-column justify-content-center'}>
                <Link to={'/task/view/' + task.task.id} ><Button variant={'primary'}>View</Button></Link>
            </div>

        </div>
    )
}


export type SearchResultCardProps = {
    document: DocumentLookup
}

export const SearchResultCard = ({document}: SearchResultCardProps) => {

    const elaboratedTask = elaborateTask(document.document as Task, new Date());

    switch (document.dbLookup.type) {
        case 'task':
            return <SearchResultCardTask lookup={document} task={elaboratedTask} />
        default:
            return <div>Unknown document type</div>
    }
}