import {Col, Container, Row} from "react-bootstrap";
import {EmailReports} from "./EmailReports";
import {useEffect, useState} from "react";
import {LoadableValue} from "../model/LoadableValue";
import {callAPI} from "../model/API";
import {EmailEvents} from "./EmailEvents";
import {
    GetRegularEmailSubscriptionsRequest,
    GetRegularEmailSubscriptionsResponse,
    RegularEmailSubscriptions,
    SetSubscriptionForRegularEmailRequest,
    SetSubscriptionForRegularEmailResponse,
    UpdateCustomEmailRequest, UpdateCustomEmailResponse
} from "../model/RequestTypes";
import {CustomEmailConfig} from "../model/databaseSchema";

export type EmailsPageComponentProps = {
    sendRegularEmailNow: (emailId: string) => void
    updateRegularEmailPreferences: (emailId: string, subscription: boolean) => void
    regularEmailPreferences: RegularEmailSubscriptions,
}


const EmailsPageComponent = ({
                                 regularEmailPreferences,
                                 sendRegularEmailNow,
                                 updateRegularEmailPreferences,
                             }: EmailsPageComponentProps) => {
    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} xl={1}/>
            <Col xs={12} xl={10}>
                <h1 className={'text-center'}>Emails</h1>
                <div className={'ds-panel mt-3'}>
                    <h2 className={'ds-panel-header'}>Reports</h2>
                    <EmailReports sendRegularEmailNow={sendRegularEmailNow}
                                  updateRegularEmailPreferences={updateRegularEmailPreferences}
                                  regularEmailPreferences={regularEmailPreferences}/>
                </div>
                <div className={'ds-panel mt-3'}>
                    <h2 className={'ds-panel-header'}>Events</h2>
                    <EmailEvents sendRegularEmailNow={sendRegularEmailNow}
                                 updateRegularEmailPreferences={updateRegularEmailPreferences}
                                 regularEmailPreferences={regularEmailPreferences}/>
                </div>

            </Col>
        </Row>
    </Container>
}


export const EmailsPage = () => {

    const [regularEmailPreferences, setRegularEmailPreferences] = useState<LoadableValue<{ subscriptions : RegularEmailSubscriptions, customEmails : CustomEmailConfig[] }>>({type: "loading"});

    useEffect(() => {
        const f = async () => {

            try {

                const getEmailPreferences = await callAPI<GetRegularEmailSubscriptionsRequest, GetRegularEmailSubscriptionsResponse>({type: "getRegularEmailSubscriptions"});
                setRegularEmailPreferences({type: "loaded", subscriptions: getEmailPreferences.subscriptions, customEmails: getEmailPreferences.customEmails});

            } catch (e) {
                console.error(e);
            }
        }
        f();
    }, []);

    const sendRegularEmailNow = async (emailId: string) => {
        try {
            await callAPI({type: "sendRegularEmailNow", emailId});
        } catch (e) {
            console.error(e);
        }
    }

    const updateRegularEmailPreferences = async (emailId: string, subscription: boolean) => {
        try {
            const response = await callAPI<SetSubscriptionForRegularEmailRequest, SetSubscriptionForRegularEmailResponse>({
                type: "setSubscriptionForRegularEmail",
                emailId,
                subscription
            });
            if(regularEmailPreferences.type === "loaded"){
                setRegularEmailPreferences({...regularEmailPreferences, subscriptions: response.subscriptions})
            }
            else {
                setRegularEmailPreferences({type: "loaded", subscriptions: response.subscriptions, customEmails: []});
            }
        } catch (e) {
            console.error(e);
        }
    }

    const updateCustomEmailConfig = async (config: CustomEmailConfig) => {
        try {
            await callAPI<UpdateCustomEmailRequest, UpdateCustomEmailResponse>({
                type: "updateCustomEmail",
                email: config
            });
        } catch (e) {
            console.error(e);
        }
    }

    if (regularEmailPreferences.type === "loading") {
        return <div>Loading...</div>
    }

    if (regularEmailPreferences.type === "error") {
        return <div>Error: {regularEmailPreferences.error.message}</div>
    }

    return <EmailsPageComponent regularEmailPreferences={regularEmailPreferences.subscriptions}
                                updateRegularEmailPreferences={updateRegularEmailPreferences}
                                sendRegularEmailNow={sendRegularEmailNow}


    />
}