
import {AgGridReact} from "ag-grid-react";
import React from "react";
import {ColDef} from "ag-grid-community";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import "./MonthlyDataGrid.css";

export type FinanceTrackingDataPoint = {
    source: string;
    data: {
        [month: string]: number | undefined;
    }
}

export type MonthlyDataGridProps = {
    data: FinanceTrackingDataPoint[];
    onDataChange: (source: string, month: string, newValue: number) => void;
}

const months = ['April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', "January", "February", "March"];

type RowData = Record<string, number | string>;


export const MonthlyDataGrid = ({data, onDataChange} : MonthlyDataGridProps) => {

    console.log(data);

    const rowData : RowData[] = [
    ...data.map((dataPoint) : RowData => {
        const row : RowData = months.reduce((acc, month) => {
            acc[month] = dataPoint.data[month] ?? '';
            return acc;
        }, {source: dataPoint.source} as RowData);
        row["Total"] = Object.values(row).filter((cell) => typeof cell === 'number').reduce((sum : number, cell) => sum + (cell as number), 0);
        return row;
    })];

    const totalsRow : RowData = months.reduce((acc, month) => {
        acc[month] = rowData.reduce((sum, row) => sum + (typeof row[month] === 'number' ? row[month] as number : 0), 0);
        acc.source = "Total";
        return acc;
    }, {} as RowData);

    totalsRow["Total"] = Object.values(totalsRow).filter((cell) => typeof cell === 'number').reduce((sum : number, cell) => sum + (cell as number), 0);

    rowData.push(totalsRow);


    const columnDefs : ColDef[] = [
        { headerName: 'Income Source', field: 'source', pinned: 'left', editable: false, cellStyle: {fontWeight: 'bold'}},
        ...months.map((month) => ({
            headerName: month,
            field: month,
            editable: true,
            valueFormatter: (params: any) => (params.value ? `£${params.value}` : ''),
            valueParser: (params: any) => parseFloat(params.newValue) || 0,
            headerClass: 'monthly-data-grid-header',
        })),
        {
            headerName: 'Total',
            field: 'Total',
            valueFormatter: (params: any) => (params.value ? `£${params.value}` : ''),
            editable: false,
            cellStyle: {fontWeight: 'bold'}
        },
    ];

    return <div className="ag-theme-quartz mt-3 monthly-data-grid" style={{width: '100%'}}>
        <AgGridReact
            rowData={rowData}
            onCellValueChanged={(event) => {
                const source : string = event.data.source;
                if (source === 'Total') {
                    return;
                }
                onDataChange(source, event.colDef.field as string, event.newValue);
            }}
            columnDefs={columnDefs}
            domLayout="autoHeight"
            gridOptions={{
                autoSizeStrategy: {
                    type: 'fitGridWidth'
                }
            }}
            getRowStyle={(params) => {
                // Highlight the totals row
                if (params.data.source === 'Total') {
                    return {fontWeight: 'bold', backgroundColor: '#f4f4f4'};
                }
                return undefined;
            }}
        />
    </div>
}