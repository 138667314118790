export const instructionTemplates: Record<string, string> = {
    "Complaint Response": `
### 1. What was the initial complaint

### 2. What did the patient state they wanted as a complaint

### 3. What did you do to investigate the complaint and did you interview anyone

### 4. What are your findings

### 5. What do you believe the outcome is 
`,
    "Supporting Letter": `
### 1. What does the patient want support with

### 2. What has the patient told you to support their request

### 3. What relevant factual information can you provide from the patient's medical records. Remember, avoid offering opinions or advice as this can lead to legal liability

`,
    "Significant Event Analysis": `
### 1. What happened during the event?
### 2. Why did the event occur?
### 3. What was the impact on those involved (e.g., patient, carer, staff)?
### 4. How could the situation have been handled differently?
### 5. What lessons can be learned from this event?
### 6. What actions or changes are needed to improve future outcomes?
### 7. Who is responsible for implementing these changes?
### 8. How will the changes be monitored and assessed over time?
### 9. How did the event make you feel (if reflecting personally)?
### 10. Were there any contributing factors related to people, activities, or the environment?`
}


export const howToTemplates : Record<string, string> = {
    "Inclusivity Checker": `Share your idea below (eg Christmas party), and our AI will provide insights or potential inclusivity concerns. While our AI aims to help, it can sometimes be overly cautious—so feel free to apply your own judgment to its suggestions.`,
}