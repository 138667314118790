import React, {useState} from "react";
import {OnboardingSubject, Task} from "../model/RequestTypes";
import {TemplateTasksList} from "./TemplateTaskList";
import './OnboardingSubjectComponent.css';
import {Accordion, Button} from "react-bootstrap";
import {ProgressIndicator} from "../components/common/ProgressIndicator";
import {TaskTemplate} from "../model/databaseSchema";
import {PracticeUsersLoadable} from "../model/PracticeUsers";
import {TaskTemplateWithAssignee} from "./TaskTemplateWithAssignee";
import {TaskCreationForm, TaskForm} from "../components/tasks/TaskForm";

export type OnboardingSubjectComponentProps = {
    subject: OnboardingSubject,
    addTasks: (tasks: TaskTemplateWithAssignee[]) => Promise<void>,
    next: () => void,
    practiceUsers: PracticeUsersLoadable,
    defaultTaskFrequency?: 'weekly' | 'monthly' | 'quarterly' | 'yearly' | 'oneOff'
}

export const OnboardingSubjectComponent = ({
                                               subject,
                                               next,
                                               addTasks,
                                               practiceUsers,
    defaultTaskFrequency
                                           }: OnboardingSubjectComponentProps) => {

    const [userProvidedTasks, setUserProvidedTasks] = useState<TaskTemplate[]>([]);


    const [selectedTasks, setSelectedTasks] = useState<Record<string, TaskTemplate>>({
        ...subject.fixedTasks.reduce((acc, task) => {
            acc[task.id] = task;
            return acc;
        }, {} as Record<string, TaskTemplate>),
        ...subject.userInputTasks.reduce((acc, task) => {
            acc[task.id] = task;
            return acc;
        }, {} as Record<string, TaskTemplate>),
        ...userProvidedTasks.reduce((acc, task) => {
            acc[task.id] = task;
            return acc;
        }, {} as Record<string, TaskTemplate>)
    });
    const [edits, setEdits] = useState<Record<string, TaskTemplateWithAssignee>>({});

    const [saving, setSaving] = useState<boolean>(false);

    const setSelectedTask = (task: TaskTemplate, selected: boolean) => {
        if (selected) {
            setSelectedTasks({...selectedTasks, [task.id]: task});
        } else {
            const newSelectedTasks = {...selectedTasks};
            delete newSelectedTasks[task.id];
            setSelectedTasks(newSelectedTasks);
        }
    }

    const fixedTasksOverlayedWithEdits: TaskTemplateWithAssignee[] = subject.fixedTasks.map(task => {
        if (edits[task.id]) {
            return edits[task.id];
        }
        return task as TaskTemplateWithAssignee;
    });

    const userInputTasksOverlayedWithEdits: TaskTemplateWithAssignee[] = subject.userInputTasks.map(task => {
        if (edits[task.id]) {
            return edits[task.id];
        }
        return task as TaskTemplateWithAssignee;
    });

    const userProvidedTasksOverlayedWithEdits: TaskTemplateWithAssignee[] = userProvidedTasks.map(task => {
        if (edits[task.id]) {
            return edits[task.id];
        }
        return task as TaskTemplateWithAssignee;
    });

    const editTask = (task: Task) => {
        setEdits({...edits, [task.id]: task});
    };

    const saveAndContinue = async () => {
        setSaving(true);

        const fixedTasks = fixedTasksOverlayedWithEdits.filter(task => !!selectedTasks[task.id]);
        const userInputTasks = userInputTasksOverlayedWithEdits.filter(task => !!selectedTasks[task.id]);
        const upTasks = userProvidedTasksOverlayedWithEdits.filter(task => !!selectedTasks[task.id]);

        await addTasks([...fixedTasks, ...userInputTasks, ...upTasks]);
        if (next) {
            next();
        }
        setSaving(false);
    }

    const addUserProvidedTask = (task: Task) => {
        const taskTemplate = task as TaskTemplate;
        taskTemplate.id = task.name.toLowerCase().replace(/\s/g, '-') + '-' + Math.random().toString(36).substring(7);
        setUserProvidedTasks([...userProvidedTasks, taskTemplate]);
        setSelectedTask(taskTemplate, true);
    }

    return (
        <div className={'ds-panel'}>
            <h2 className={'ds-panel-header'}>{subject.name}</h2>
            {saving ?
                <ProgressIndicator text={'Saving tasks'} maxSeconds={60}/>
                :
                <div>
                    {subject.userInputTasks.length === 0 && subject.fixedTasks.length === 0 &&
                        <div>
                            <h3 className={'onboarding-subject-task-group-title'}>No tasks to add</h3>
                            <p className={'onboarding-subject-task-group-explainer'}>All tasks added during the last
                                onboarding</p>
                        </div>
                    }
                    <TemplateTasksList taskTemplates={userInputTasksOverlayedWithEdits}
                                       selectedTasks={selectedTasks} selectTask={setSelectedTask}
                                       editTask={editTask}
                                       practiceUsers={practiceUsers}
                    />
                    <TemplateTasksList taskTemplates={fixedTasksOverlayedWithEdits}
                                       selectedTasks={selectedTasks}
                                       selectTask={setSelectedTask} editTask={editTask}
                                       practiceUsers={practiceUsers}
                    />
                    <TemplateTasksList taskTemplates={userProvidedTasksOverlayedWithEdits}
                                       selectedTasks={selectedTasks}
                                       selectTask={setSelectedTask} editTask={editTask}
                                       practiceUsers={practiceUsers}
                    />

                    <Accordion className={'my-3 onboarding-add-task-accordion'}>
                        <Accordion.Header>Add More Tasks</Accordion.Header>
                        <Accordion.Body>
                            <TaskCreationForm submitTask={addUserProvidedTask} submitText={"Add Task"} today={new Date()} defaultTaskFrequency={defaultTaskFrequency}/>
                        </Accordion.Body>
                    </Accordion>

                    <div className={'d-flex flex-row gap-2'}>
                        <Button variant={'secondary'} onClick={() => next()} className={'mr-2'}>Back</Button>
                        <Button onClick={saveAndContinue}>Save and Continue</Button>
                    </div>
                </div>
            }
        </div>
    )
}