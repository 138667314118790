import {redirect, useNavigate, useSearchParams} from "react-router-dom";
import {AddTasksRequest, AddTasksResponse, Task} from "../model/RequestTypes";
import {TaskFrequency} from "../model/databaseSchema";
import {daysOfWeek} from "../components/tasks/Dates";
import {TaskForm} from "../components/tasks/TaskForm";
import {Col, Container, Row} from "react-bootstrap";
import {callAPI} from "../model/API";

const getFrequencyFromSearchParams = (searchParams: URLSearchParams) => {
    const newTaskFrequencyType = searchParams.get('frequency')?.toLowerCase() ?? 'yearly';
    let newFrequency: TaskFrequency;
    switch (newTaskFrequencyType) {
        case 'daily': {
            newFrequency = {
                type: 'daily'
            }
            break;
        }
        case 'weekly': {
            const dayStr = searchParams.get('dayOfWeek')?.toLowerCase();
            const newTaskDay = (daysOfWeek.find(d => dayStr && dayStr === d) ?? "monday") as 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
            newFrequency = {
                type: 'weekly',
                dayOfWeek: newTaskDay
            }
            break;
        }
        case 'monthly': {
            const newTaskDay = parseInt(searchParams.get('day') ?? '1');
            newFrequency = {
                type: 'monthly',
                day: newTaskDay
            }
            break;
        }
        case 'quarterly': {
            const newTaskMonth = parseInt(searchParams.get('monthNumber') ?? '0');
            const newTaskDay = parseInt(searchParams.get('day') ?? '1');
            newFrequency = {
                type: 'quarterly',
                monthNumber: newTaskMonth,
                day: newTaskDay
            }
            break
        }
        default: {
            const newTaskMonth = parseInt(searchParams.get('month') ?? '0');
            const newTaskDay = parseInt(searchParams.get('day') ?? '1');
            newFrequency = {
                type: 'yearly',
                month: newTaskMonth,
                day: newTaskDay
            }
        }
    }
    return newFrequency;
}

type AddTaskPageProps = {
    initialTask: Task,
    today: Date,
    addTask: (task: Task) => void
}

export const AddTaskPageComponent = ({initialTask, today, addTask}: AddTaskPageProps) => {

    return (

            <Container>
                <Row>
                    <Col xs={0} xl={1}></Col>
                    <Col xs={12} xl={10}>
                        <div className={'ds-panel mt-2'}>
                            <TaskForm submitTask={addTask} initialTask={initialTask} submitText={"Add Task"} today={today}/>
                        </div>
                    </Col>
                    <Col xs={0} xl={1}></Col>
                </Row>

            </Container>
    )
}


export const AddTaskPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const newTaskName = searchParams.get('name') ?? '';
    const newTaskType = searchParams.get('type')?.toLowerCase() ?? 'recurring';
    const newTaskTags = searchParams.getAll('tags');
    const newTaskDetails = searchParams.get('details') ?? undefined;

    let initialTask: Task;
    switch (newTaskType) {
        case 'recurring': {
            initialTask = {
                id: newTaskName.toLowerCase().replace(/\s/g, '') + '-' + (Date.now()),
                name: newTaskName,
                type: 'recurring',
                tags: newTaskTags,
                details: newTaskDetails,
                frequency: getFrequencyFromSearchParams(searchParams)
            }
            break;
        }
        default: {
            const newDueDateStr = searchParams.get('dueDate');
            const newDueDate = newDueDateStr ? new Date(newDueDateStr).getTime() : Date.now() + (24 * 60 * 60 * 1000);
            initialTask = {
                id: newTaskName.toLowerCase().replace(/\s/g, '') + '-' + (Date.now()),
                name: newTaskName,
                type: 'oneOff',
                tags: newTaskTags,
                details: newTaskDetails,
                dueDate: newDueDate
            }
        }


    }

    const today = new Date(new Date().setHours(0, 0, 0, 0));


    const addTask = async (task: Task) => {
        try {
            await callAPI<AddTasksRequest, AddTasksResponse>({type: 'addTasks', tasks: [task]});
            navigate('/');
        }
        catch (e: any) {
            console.log(e);
        }

    }

    return <AddTaskPageComponent initialTask={initialTask} today={today} addTask={addTask}/>
}