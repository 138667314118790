import {getPagesMetaData, PageMetaData, PagesMetaData} from "./storageFunctions";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import './guidesPage.css';
import {LoadableValue} from "../model/LoadableValue";

type GuidePageCardProps = {
    pageMeta: PageMetaData
}


const GuidePageCard = ({pageMeta}: GuidePageCardProps) => {

    // hero image is hero.png, hero.jpg, hero.jpeg, or hero.webp
    const heroImage = Object.entries(pageMeta.otherFiles).find(([key, value]) => key === 'hero.png' || key === 'hero.jpg' || key === 'hero.jpeg' || key === 'hero.webp')?.[1];

    return (
        <Card className={'guide-card'}>
            {heroImage && <Card.Img variant="top" src={heroImage}/>}
            <Card.Body>
                <Card.Title>{pageMeta.title ?? "Unknown"}</Card.Title>
                <Link to={`./${pageMeta.id}`}>
                    <Button className={'w-100 mt-3'}>Open</Button>
                </Link>
            </Card.Body>
        </Card>
    )
}

type GuidesPageComponentProps = {
    metaData: PagesMetaData
}

const GuidesPageComponent = ({metaData} : GuidesPageComponentProps) => {

    const sortedPageIds = Object.keys(metaData.pages).sort();

    return (
        <div className="d-flex flex-column w-100 align-items-center p-3">
            <Container>
                <Row>
                    <Col xs={0}/>
                    <Col xs={12}>
                        <div className={'ds-panel w-100'}>
                            <h1 className={'ds-panel-header'}>Topics</h1>
                            <div className={'guide-card-container'}>
                                {sortedPageIds.map((pageId) => {
                                    return <GuidePageCard key={pageId} pageMeta={metaData.pages[pageId]}/>
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col xs={0}/>
                </Row>
            </Container>
        </div>
    )

}



export const GuidesPage = () => {
    const navigate = useNavigate();

    const [pagesMetaData, setPagesMetaData] = useState<LoadableValue<PagesMetaData>>({type: 'loading'});

    useEffect(() => {
        const fetchContent = async () => {
            const metaData = await getPagesMetaData();
            if (!metaData) {
               // navigate('/404');
                return
            }
            setPagesMetaData({type: 'loaded', ...metaData!});
        }
        fetchContent();
    }, [navigate]);

    if(pagesMetaData.type === 'loading') {
        return <div>Loading...</div>
    }

    return (
        <GuidesPageComponent metaData={pagesMetaData as PagesMetaData}/>
    )
}