
export function sanitizeFilename(input: string): string {
    // Define a regular expression to match invalid characters
    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;

    // Replace invalid characters with an underscore and trim leading/trailing spaces
    let sanitized = input.replace(invalidChars, "_").trim();

    // Prevent the filename from ending with a period or space (Windows compatibility)
    sanitized = sanitized.replace(/[. ]+$/, "");

    // If the filename is empty after sanitization, provide a default
    if (sanitized.length === 0) {
        sanitized = "download";
    }

    return sanitized;
}

export const b64toBlob = (b64Data : string, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

export const downloadBase64String = (base64String: string,
                                                            mimeType: string,
                                                            filename: string) => {
    //code should download the pdf
    const blob = b64toBlob(base64String, mimeType);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = sanitizeFilename(filename);
    a.click();
}