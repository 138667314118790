export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const monthsOfYear = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
export const monthsOfYear3LetterCap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const monthsOfYearFirstLetter = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
export const monthsOfTheYearFirstCap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const addDateNumberSuffix = (num: number) => {
    const lastDigit = num % 10;
    if (lastDigit === 1) {
        return num + 'st';
    }
    if (lastDigit === 2) {
        return num + 'nd';
    }
    if (lastDigit === 3) {
        return num + 'rd';
    }
    return num + 'th';
}

export const getStartOfWeek = (time: Date) => {
    return new Date(time.getTime() - (time.getDay() * 1000 * 60 * 60 * 24));
}

export const getStartOfMonth = (time: Date) => {
    return new Date(time.getFullYear(), time.getMonth(), 1);
}