import {Accordion, Button, Col, Container, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {LoadableValue} from "../model/LoadableValue";
import {callAPI} from "../model/API";
import {
    DeleteCustomEmailRequest, DeleteCustomEmailResponse,
    GetRegularEmailSubscriptionsRequest,
    GetRegularEmailSubscriptionsResponse,
    RegularEmailSubscriptions, SendRegularEmailNowRequest,
    UpdateCustomEmailRequest, UpdateCustomEmailResponse
} from "../model/RequestTypes";
import {CustomEmailConfig} from "../model/databaseSchema";
import {isEqual} from "lodash";
import "./AIEmailsPage.css";

export type CustomEmailConfigEditorProps = {
    initialEmailConfig: CustomEmailConfig
    updateCustomEmailConfig: (config: CustomEmailConfig) => Promise<void>
    sendRegularEmailNow: (emailId: string, customEmailName: string) => void
    deleteCustomEmailConfig: (config: CustomEmailConfig) => void
}

export const CustomEmailConfigEditor = ({
                                            initialEmailConfig,
                                            updateCustomEmailConfig,
                                            sendRegularEmailNow,
                                            deleteCustomEmailConfig
                                        }: CustomEmailConfigEditorProps) => {
    const [customEmailConfig, setCustomEmailConfig] = useState<CustomEmailConfig>(initialEmailConfig);

    const pendingUpdate = !isEqual(initialEmailConfig, customEmailConfig);

    const wrapUpdate = async () => {
        await updateCustomEmailConfig(customEmailConfig);
    }

    if (customEmailConfig.frequency.type !== 'weekly') {
        return <div>Only weekly emails are supported at the moment</div>
    }


    return <div>
        <Form.Group className={'mt-3'}>
            <Form.Label>Instructions for the AI</Form.Label>
            <Form.Control type={'text'} as={'textarea'} rows={10} value={customEmailConfig.instructions}
                          onChange={(e) => setCustomEmailConfig({
                              ...customEmailConfig,
                              instructions: e.target.value
                          })}/>
        </Form.Group>
        <Form.Group className={'mt-3'}>
            <Form.Label>Subject</Form.Label>
            <Form.Control type={'text'} value={customEmailConfig.subject}
                          onChange={(e) => setCustomEmailConfig({
                              ...customEmailConfig,
                              subject: e.target.value
                          })}/>
        </Form.Group>
        <Form.Group className={'mt-3'}>
            <Form.Label>Day of the week</Form.Label>
            <Form.Select value={customEmailConfig.frequency.dayOfWeek} onChange={(e) => setCustomEmailConfig({
                ...customEmailConfig,
                frequency: {
                    type: 'weekly',
                    dayOfWeek: e.target.value as "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday"
                }
            })}>
                <option value={'monday'}>Monday</option>
                <option value={'tuesday'}>Tuesday</option>
                <option value={'wednesday'}>Wednesday</option>
                <option value={'thursday'}>Thursday</option>
                <option value={'friday'}>Friday</option>
            </Form.Select>
        </Form.Group>
        <div className={'mt-3 d-flex flex-row gap-3'}>
            <Button onClick={wrapUpdate} disabled={!pendingUpdate}>Update{pendingUpdate ? "*" : ""}</Button>
            <Button variant={"secondary"} onClick={() => sendRegularEmailNow('customEmail', customEmailConfig.name)}
                    disabled={pendingUpdate}>Send Now</Button>
            <Button onClick={() => deleteCustomEmailConfig(customEmailConfig)} variant={'danger'}>Delete</Button>
        </div>
    </div>

}

export type EmailsPageComponentProps = {
    sendRegularEmailNow: (emailId: string, customEmailName: string) => void
    updateCustomEmailConfig: (config: CustomEmailConfig) => Promise<void>
    customEmailConfigs: CustomEmailConfig[]
    deleteCustomEmailConfig: (config: CustomEmailConfig) => void
}


const EmailsPageComponent = ({
                                 sendRegularEmailNow,
                                 updateCustomEmailConfig,
                                 customEmailConfigs,
                                 deleteCustomEmailConfig
                             }: EmailsPageComponentProps) => {
    const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
    const [showNewEmail, setShowNewEmail] = useState<boolean>(false);
    const [newEmailName, setNewEmailName] = useState<string>('');

    const createNewEmail = async (name: string) => {
        const newEmail: CustomEmailConfig = {
            name: name,
            instructions: '',
            subject: '',
            frequency: {
                type: 'weekly',
                dayOfWeek: 'monday'
            },
            enabled: true
        }

        await updateCustomEmailConfig(newEmail);
        setActiveKey(newEmail.name);
    }

    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} xl={1}/>
            <Col xs={12} xl={10}>
                <h1 className={'text-center'}>AI Generated Emails</h1>
                <div className={'ds-panel mt-3'}>
                    <Accordion>
                        <Accordion.Header>
                            How to use AI Generated Emails
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>AI generated Emails allow you to generate a regular email completely customised to your
                                needs</p>
                            <p>Tell the AI below what you'd like it to contain, and our AI will generate that email
                                regularly and send it to your inbox. The more precise and detailed you are with your
                                instructions, the more accurate your results.</p>
                            <div className={'ai-email-example'}>
                                <h2>Example 1: Tasks Requiring Significant Lead Time</h2>
                                <p className={'ai-email-example-desired-outcome'}><strong>Desired Email:</strong> Heads
                                    up once a week on upcoming tasks that will require a lot of lead time</p>
                                <h3>Instructions for AI</h3>
                                <p className={'ai-email-example-prompt'}>Look at the tasks coming up in the next few
                                    months. Create a list of tasks that might take significant lead time to complete and
                                    any actions that we'll need to do now.</p>
                            </div>
                            <div className={'ai-email-example mt-3'}>
                                <h2>Example 2: Custom Operations Agenda Email</h2>
                                <p className={'ai-email-example-desired-outcome'}><strong>Desired Email:</strong> You
                                    want to customise the standard weekly operations agenda email</p>
                                <h3>Instructions for AI</h3>
                                <p className={'ai-email-example-prompt'}>
                                    Create an operations agenda meeting using the following template:

                                    <ol>
                                        <li>Outstanding tasks from last week</li>
                                        <li>Tasks due this week</li>
                                        <li>Common Operations Themes Last Week (leave space for us to add these during
                                            the meeting)
                                        </li>
                                        <li>Craig's update on broom road site</li>
                                    </ol>

                                </p>
                            </div>

                            <p className={'mt-3'}>If you're trying to automate generate an email, but don't know how,
                                email us on help@mypracticemanager.co.uk -
                                you might be trying something we didn't consider and we'd love to learn and evolve our
                                system to cover your needs</p>
                        </Accordion.Body>
                    </Accordion>

                    <Button variant={'primary'} className="mt-3" onClick={() => setShowNewEmail(true)}>Create New AI
                        Generated
                        Email</Button>
                </div>

                <div className={'ds-panel mt-3'}>
                    <h2 className={'ds-panel-header'}>Edit Existing AI Generated Emails</h2>
                    <Tabs className="mb-3" activeKey={activeKey} onSelect={(k) => setActiveKey(k ?? undefined)}>
                        {customEmailConfigs.map((tab) => (
                            <Tab eventKey={tab.name} key={tab.name} title={tab.name}>
                                <CustomEmailConfigEditor updateCustomEmailConfig={updateCustomEmailConfig}
                                                         initialEmailConfig={tab}
                                                         sendRegularEmailNow={sendRegularEmailNow}
                                                         deleteCustomEmailConfig={deleteCustomEmailConfig}

                                />
                            </Tab>
                        ))}

                    </Tabs>
                </div>
                <Modal show={showNewEmail} onHide={() => setShowNewEmail(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New AI Generated Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className={'mt-3'}>
                            <Form.Label>Email Name</Form.Label>
                            <Form.Control type={'text'} value={newEmailName}
                                          onChange={(e) => setNewEmailName(e.target.value)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowNewEmail(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setShowNewEmail(false);
                            createNewEmail(newEmailName);
                            setNewEmailName('');
                        }}>
                            Create
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Col>
        </Row>
    </Container>
}


export const AIGeneratedEmailsPage = () => {

    const [regularEmailPreferences, setRegularEmailPreferences] = useState<LoadableValue<{
        subscriptions: RegularEmailSubscriptions,
        customEmails: CustomEmailConfig[]
    }>>({type: "loading"});

    useEffect(() => {
        const f = async () => {

            try {

                const getEmailPreferences = await callAPI<GetRegularEmailSubscriptionsRequest, GetRegularEmailSubscriptionsResponse>({type: "getRegularEmailSubscriptions"});
                setRegularEmailPreferences({
                    type: "loaded",
                    subscriptions: getEmailPreferences.subscriptions,
                    customEmails: getEmailPreferences.customEmails
                });

            } catch (e) {
                console.error(e);
            }
        }
        f();
    }, []);

    const sendRegularEmailNow = async (emailId: string, customEmailName: string) => {
        try {
            await callAPI<SendRegularEmailNowRequest, UpdateCustomEmailResponse>({
                type: "sendRegularEmailNow",
                emailId,
                customEmailName
            });
        } catch (e) {
            console.error(e);
        }
    }

    const updateCustomEmailConfig = async (config: CustomEmailConfig) => {
        try {
            const response = await callAPI<UpdateCustomEmailRequest, UpdateCustomEmailResponse>({
                type: "updateCustomEmail",
                email: config
            });
            setRegularEmailPreferences({
                type: "loaded",
                subscriptions: response.subscriptions,
                customEmails: response.customEmails
            });
        } catch (e) {
            console.error(e);
        }
    }

    const deleteCustomEmailConfig = async (config: CustomEmailConfig) => {
        try {
            const response = await callAPI<DeleteCustomEmailRequest, DeleteCustomEmailResponse>({
                type: "deleteCustomEmail",
                name: config.name
            });
            setRegularEmailPreferences({
                type: "loaded",
                subscriptions: response.subscriptions,
                customEmails: response.customEmails
            });
        } catch (e) {
            console.error(e);
        }
    }

    if (regularEmailPreferences.type === "loading") {
        return <div>Loading...</div>
    }

    if (regularEmailPreferences.type === "error") {
        return <div>Error: {regularEmailPreferences.error.message}</div>
    }

    return <EmailsPageComponent sendRegularEmailNow={sendRegularEmailNow}
                                updateCustomEmailConfig={updateCustomEmailConfig}
                                customEmailConfigs={regularEmailPreferences.customEmails}
                                deleteCustomEmailConfig={deleteCustomEmailConfig}


    />
}