import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";


export const VerifyEmail = ({verificationRequested} : {verificationRequested : () => void}) => {
    const [sending, setSending] = React.useState<boolean>(false);

    const verificationRequestedWrapper =  () => {
        setSending(true);
        verificationRequested();
        setTimeout(() => {
            setSending(false);
        }, 10000);
    }

    return <Container className={'py-3'}>
        <Row>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
            <Col xs={12} md={10} lg={8} xl={6}>
                <div className={'ds-panel'}>
                    <h2 className={'ds-panel-header'}>Verify Your Email</h2>
                    <Button onClick={verificationRequestedWrapper} disabled={sending}>Send Verification Email</Button>
                </div>
            </Col>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
        </Row>
    </Container>
}