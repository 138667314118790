import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {auth} from "../../model/firebaseConnection";
import * as yup from "yup";
import {Formik} from "formik";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {signInWithEmailLink} from "firebase/auth";

type LoginWithMagicLinkProps = {
    loginWithMagicLink: (email: string) => void
}

const LoginWithMagicLink = ({loginWithMagicLink}: LoginWithMagicLinkProps) => {
    const schema = yup.object().shape({
        email: yup.string().email().required(),
    });

    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
            <Col xs={12} md={10} lg={8} xl={6}>
                <div className={'ds-panel'}>
                    <h2 className={'ds-panel-header'}>Finish Login</h2>
                    <p>Please confirm your email below to login</p>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => {
                            loginWithMagicLink(values.email);
                        }}
                        initialValues={{
                            email: ''
                        }}
                    >
                        {({handleSubmit, handleChange, values}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="loginWithMagicLinkEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        data-testid="login-email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Button onClick={() => handleSubmit()}>Finish Login</Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Col>
            <Col xs={0} md={1} lg={2} xl={3}></Col>
        </Row>

    </Container>


}


export const FinishMagicLinkSigninAuthOperation = () => {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const oobCode = searchParams.get('oobCode');

    const loginWithMagicLink = async (email: string) => {
        if (!auth.currentUser) {
            try {
                await signInWithEmailLink(auth, email, window.location.href);
                if (localStorage) {
                    localStorage.removeItem('emailForSignIn');
                }
                navigate('/');
            } catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        const f = async () => {
            if (localStorage) {
                const email = localStorage.getItem('emailForSignIn');
                if (email) {
                    await loginWithMagicLink(email);
                }
            }
        }
        f();
    }, [oobCode]);

    return (
        <LoginWithMagicLink loginWithMagicLink={loginWithMagicLink}/>
    )
}