import React, {useEffect, useState} from 'react';
import {httpsCallable} from "firebase/functions";
import {functions} from "../../model/firebaseConnection";
import {GetSessionStatusRequest, GetSessionStatusResponse} from "../../model/RequestTypes";
import {Link, useSearchParams} from "react-router-dom";
import {Button, Col, Container, Row} from "react-bootstrap";

export const PaymentReturn = () => {
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const f = async () => {
            const sessionId = searchParams.get('session_id');
            const paymentAPIFunc = httpsCallable<GetSessionStatusRequest, GetSessionStatusResponse>(functions, 'paymentAPI');
            const response = await paymentAPIFunc({
                type: "getSessionStatus",
                sessionId: sessionId!
            });
            setStatus(response.data.status);
        }
        f();
    }, [searchParams]);

    const continueUrl = searchParams.get('continue_url') ?? '/';


    return (
        <Container>
            <Row>
                <Col xs={0} md={1} lg={2} xl={3}></Col>
                <Col xs={12} md={10} lg={8} xl={6}>
                    <div className={'ds-panel mt-3'}>
                        {
                            status === 'open' &&
                            <div>
                                <p>
                                    Something went wrong with your payment. Please try again. If you have any questions,
                                    please email <a
                                    href="mailto:help@mypracticemanager.co.uk">help@mypracticemanager.co.uk</a>.
                                </p>
                                <Button href={continueUrl}>Try Again</Button>
                            </div>

                        }
                        {
                            status === 'complete' &&
                            <div>
                                <h1 className={'ds-panel-header'}>Payment Successful</h1>
                                <p>
                                    Thank you for joining us! A confirmation email will be sent to your email.

                                    If you have any questions, please email <a
                                    href="mailto:help@mypracticemanager.co.uk">help@mypracticemanager.co.uk</a>.
                                </p>
                                <Button href={continueUrl}>Go to Dashboard</Button>
                            </div>
                        }
                        {
                            status !== 'open' && status !== 'complete' &&
                            <div>
                                <p>
                                    Something went wrong with your payment. Please try again. If you have any questions,
                                    please email <a
                                    href="mailto:help@mypracticemanager.co.uk">help@mypracticemanager.co.uk</a>.
                                </p>
                                <Button href={continueUrl}>Try Again</Button>
                            </div>
                        }
                    </div>
                </Col>
                <Col xs={0} md={1} lg={2} xl={3}></Col>
            </Row>
        </Container>
    )
};
