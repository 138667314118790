import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {logEvent} from "firebase/analytics";
import {googleAnalytics} from "../model/firebaseConnection";
import "./DemoCTAStress.css";
import {Link} from "react-router-dom";

export const TextHighlight = ({children}: { children: string }) => {
    return <span className="text-highlight-wrapper">
                        <span className="text-highlighter" aria-hidden="true"></span>
                        <span className="text-highlight-content">{children}</span>
                      </span>
}


export type DemoCTAStressProps = {
    timeout: number
}

export const DemoCTAStress = ({timeout}: DemoCTAStressProps) => {

    const [showCTA, setShowCTA] = useState(false);
    const [ctaShown, setCtaShown] = useState(false);

    useEffect(() => {
        if (!ctaShown) {
            setTimeout(() => {
                    setShowCTA(true)
                    setCtaShown(true);
                    logEvent(googleAnalytics, 'demo_cta_shown', {});
                }
                , timeout);
        }
    }, []);


    return <Modal show={showCTA} onHide={() => {
    }} centered size={"lg"} contentClassName={'demo-cta-stress'}>
        <div>
            <h2 className={'mb-4'}>Are
                you <TextHighlight>stressed</TextHighlight> or <TextHighlight>overwhelmed</TextHighlight>?</h2>
            <p>Practice managers spend hours worrying about their practices, often <TextHighlight>at
                night</TextHighlight>, stopping them from sleeping.</p>
            <p>With MyPM, all tasks are captured in one easy-to-use dashboard. Nothing is forgotten and you will receive email reminders as soon as a task
                is overdue, to stay on top of everything.</p>
            <p>Save hours with <TextHighlight>AI-powered</TextHighlight> compliance tools - complete risk assessments
                in just 5 minutes.</p>
            <p>The average practice manages a budget exceeding £1 million annually. By saving time, you can first focus
                on sleeping better and regaining your headspace. Then, use this clarity to optimise your
                practice — reducing costs, optimising income, and ultimately delivering better patient outcomes.</p>
            <p className={'demo-cta-stress-cost'}>Only £12/month</p>
            <p className={'demo-cta-stress-cost-sp'}>For the typical practice, with a 70% early adopter discount</p>


            <div className={'d-flex flex-column gap-3 align-content-center align-items-center'}>
                <Link to="/" className={'btn btn-primary w-75'}>Start Managing My Practice</Link>
                <Button variant={'light w-75'} onClick={() => setShowCTA(false)}>Continue Demo</Button>
            </div>
        </div>

    </Modal>
}