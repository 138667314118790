import {Alert, Button, Col, Container, Modal, Row} from "react-bootstrap";
import {PracticeAutoComplete} from "../../onboarding/PracticeAutoComplete";
import React, {useEffect, useState} from "react";
import {PracticeNameIdLookup, PracticeNameIdPair} from "../../model/databaseSchema";
import {UserState} from "./LayoutState";

export type PracticeSetupIsRequiredWrapperProps = {
    children: React.ReactNode,
    userState: UserState,
    error?: string
    setupUserPractice: (practiceId: string) => void
    practiceNameIdLookup: PracticeNameIdLookup,
    pathName: string
}

const escapeUrls = [
    '/settings'
]

export const PracticeSetupIsRequiredWrapper = ({
                                                   children,
                                                   userState,
                                                   error,
                                                   setupUserPractice,
                                                   practiceNameIdLookup,
                                                   pathName
                                               }: PracticeSetupIsRequiredWrapperProps) => {

    const [showPracticeConfirmation, setShowPracticeConfirmation] = useState<boolean>(false);
    const [potentialPractice, setPotentialPractice] = useState<PracticeNameIdPair | null>(null);
    const [triedToAutoSetupDental, setTriedToAutoSetupDental] = useState<boolean>(false);

    useEffect(() => {
        if(pathName === '/dental' && !triedToAutoSetupDental) {
            setTriedToAutoSetupDental(true);
            setupUserPractice('Dental')
        }
    }, []);

    if (escapeUrls.includes(pathName)) {
        return <>{children}</>
    }

    if (userState.type === 'loading') {
        // if the user is not logged in, we don't need to worry about practice
        return <>{children}</>
    }

    if (userState.type === 'loaded' && userState.practiceMetadata) {
        return <>{children}</>
    }

    return <Container className={'mt-4'}>
        {error && <Alert variant={'danger'} dismissible={false}>{error}</Alert>}
        <Row>
            <Col xs={0} md={1} lg={2} xl={3}/>
            <Col xs={12} md={10} lg={8} xl={6}>
                <div className={'ds-panel'}>
                    <h2 className={'ds-panel-header'}>Practice Setup</h2>
                    <p>Start Typing your practice name in the search box below</p>
                    <PracticeAutoComplete practiceNameIdLookup={practiceNameIdLookup}
                                          practiceSelected={(practiceId) => {
                                              setPotentialPractice(practiceId)
                                              setShowPracticeConfirmation(true)
                                          }}/>
                </div>
                <Modal show={showPracticeConfirmation} onHide={() => {
                    setPotentialPractice(null);
                    setShowPracticeConfirmation(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Setup Practice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You are about to setup {potentialPractice?.name && potentialPractice.name} as your
                            practice</p>
                        <p>If this is incorrect, please close this window and select another practice</p>
                        <div className={'w-100 d-flex flex-row justify-content-evenly'}>
                            <Button className={'w-100 mx-2'} onClick={() => {
                                setupUserPractice(potentialPractice?.id ?? '');
                                setShowPracticeConfirmation(false);
                            }}>Confirm</Button>
                            <Button className={'w-100 mx-2'} variant={'danger'} onClick={() => {
                                setPotentialPractice(null);
                                setShowPracticeConfirmation(false)
                            }}>Cancel</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </Col>
            <Col xs={0} md={1} lg={2} xl={3}/>
        </Row>
    </Container>
}