import {useSearchParams} from "react-router-dom";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {DocumentLookup, DocumentSearchRequest, DocumentSearchResponse} from "../model/RequestTypes";
import {useEffect, useState} from "react";
import {callAPI} from "../model/API";
import {SearchResultCard} from "./SearchResultCard";

type SearchResultsPageProps = {
    query: string | null
    updateQuery: (query: string) => void
    results: DocumentLookup[]
    search: (query: string) => void
}


export const SearchResultsPageComponent = ({query, updateQuery, results, search} : SearchResultsPageProps) => {

    return (
        <Container className={'my-3'}>
            <Row>
                <Col xs={0} xl={1}/>
                <Col xs={12} xl={10}>
                    <div className={'ds-panel'}>
                        <h1 className={'ds-panel-header'}>Search</h1>
                        <div className={'d-flex flex-row w-100'}>
                            <Form.Control
                                type="text"
                                value={query ?? ''}
                                onChange={(e) => updateQuery(e.target.value)}
                                className={'flex-grow-1'}
                            />
                            <Button onClick={() => search(query ?? '')} className={'mx-3'}>Search</Button>
                        </div>
                    </div>
                    <div className={'d-flex flex-column gap-3 mt-3'}>
                        {results.map((result) => (
                            <SearchResultCard key={result.dbLookup.name} document={result} />
                        ))}
                    </div>
                </Col>
                <Col xs={0} xl={1}/>
            </Row>
        </Container>
    )

}





export const SearchResultsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get('query');

    const [results, setResults] = useState<DocumentLookup[]>([]);

    const updateQuery = (query: string) => {
        setSearchParams({query})
    }

    const search = async (query: string) => {
        const response = await callAPI<DocumentSearchRequest, DocumentSearchResponse>({type: 'documentSearch', query});
        setResults(response.documents);
    }


    return <SearchResultsPageComponent query={query} updateQuery={updateQuery} results={results} search={search} />
}