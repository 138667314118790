import {initializeApp} from "firebase/app";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getAnalytics, setAnalyticsCollectionEnabled} from "firebase/analytics";
import {getDatabase, connectDatabaseEmulator} from "firebase/database";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {getPerformance} from "firebase/performance";
import {getStorage, connectStorageEmulator} from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebasePerformance = getPerformance(app);
export const googleAnalytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getDatabase(app);
export const functions = getFunctions(app, 'europe-west2');
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const contentStorage = getStorage(app, 'gs://gp-practice-manager-guides');
export const practiceFilesStorage = getStorage(app, 'gs://' + process.env.REACT_APP_FIREBASE_STORAGE_PRACTICE_FILES);


if (process.env.NODE_ENV === 'development') {
    const current_domain_name = process.env.DOMAIN_NAME ?? "localhost";
    connectAuthEmulator(auth, `http://${current_domain_name}:9099`, {disableWarnings: true});
    connectDatabaseEmulator(db, current_domain_name, 9000);
    connectFunctionsEmulator(functions, current_domain_name, 5001);
    connectFirestoreEmulator(firestore, current_domain_name, 8080);
    connectStorageEmulator(storage, current_domain_name, 9199);
    connectStorageEmulator(contentStorage, current_domain_name, 9199);
    connectStorageEmulator(practiceFilesStorage, current_domain_name, 9199);
    setAnalyticsCollectionEnabled(googleAnalytics, false);
}

export const init = () => {
};

