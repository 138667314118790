import React, {ReactNode} from "react";
import "./LabelledGroup.css";
import {Badge} from "react-bootstrap";

export type LabelledGroupProps = {
    label: string,
    children: ReactNode,
    className?: string
    itemCount?: number
    collapsed?: boolean,
    logEventInternal: (eventName: string, eventParams: any) => void
}

export const LabelledGroup = ({
                                  label,
                                  children,
                                  className,
                                  itemCount,
                                  collapsed,
                                  logEventInternal
                              }: LabelledGroupProps) => {
    const [open, setOpen] = React.useState(!collapsed);

    const setOpenWrapper = (f : ((prev : boolean) => boolean)) => {
        setOpen((prevState) => {
                logEventInternal('labelledGroupToggle', {label: label, prevState: prevState});
                return f(prevState);
            }
        );
    }


    return (
        <div className={"labelled-group " + className + (collapsed ? " joyride-dashboard-first-step" : "")}>
            <div className={"labelled-group-label-wrapper"}>
                <div className="labelled-group-label" onClick={() => setOpenWrapper((prev) => !prev)}>
                    {label} {(!open && itemCount) ? <Badge bg={'secondary'}>{itemCount}</Badge> :
                    <span style={{display: "inline-block", width: "22px"}}></span>}

                    {open ? <i className="bi bi-chevron-down"></i> : <i className="bi bi-chevron-up"></i>}
                </div>

            </div>

            <div className={"labelled-group-content " + (open ? 'expanded' : 'collapsed')}>
                {open && children}
            </div>
        </div>
    );
}

