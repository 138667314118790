import {Task} from "./RequestTypes";
import {daysOfWeek, getStartOfWeek} from "../components/tasks/Dates";
import {DBTask} from "./databaseSchema";

export type TaskState = 'pending' | 'late' | 'complete';

export type ElaboratedTask = {
    task: Task,
    taskState: TaskState,
    dueDate: Date
}

export const elaborateTask = (task: Task, today : Date) : ElaboratedTask => {
    if(task.type === 'recurring') {
        if(task.frequency.type === 'weekly') {
            const startOfWeek = getStartOfWeek(today);
            const dueDate = new Date(startOfWeek.getTime() + (daysOfWeek.indexOf(task.frequency.dayOfWeek) * 1000 * 60 * 60 * 24));
            const completedThisWeek = !!task.lastCompleted && task.lastCompleted > startOfWeek.getTime();

            const lateByDays = completedThisWeek ? -1 : Math.floor((today.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24));

            const taskState = completedThisWeek ? 'complete' : (lateByDays > 0) ? 'late' : 'pending';
            return {
                task,
                taskState,
                dueDate
            }
        }
        else if(task.frequency.type === 'daily') {
            const completedToday = !!task.lastCompleted && task.lastCompleted > today.getTime();
            const taskState = completedToday ? 'complete' : 'pending';
            return {
                task,
                taskState,
                dueDate: today
            }
        }
        else if(task.frequency.type === 'monthly') {
            const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const dueDate = new Date(startOfMonth.getTime() + (task.frequency.day * 1000 * 60 * 60 * 24));
            const completedThisMonth = !!task.lastCompleted && task.lastCompleted > startOfMonth.getTime();

            const lateByDays = completedThisMonth ? -1 : Math.floor((today.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24));

            const taskState = completedThisMonth ? 'complete' : (lateByDays > 0) ? 'late' : 'pending';
            return {
                task,
                taskState,
                dueDate
            }
        }
        else if(task.frequency.type === 'yearly') {
            const startOfYear = new Date(today.getFullYear(), 0, 1);
            const dueDate = new Date(today.getFullYear(), task.frequency.month, task.frequency.day);
            const completedThisYear = !!task.lastCompleted && task.lastCompleted > startOfYear.getTime();

            const lateByDays = completedThisYear ? -1 : Math.floor((today.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24));

            const taskState = completedThisYear ? 'complete' : (lateByDays > 0) ? 'late' : 'pending';
            return {
                task,
                taskState,
                dueDate
            }
        }
        else if (task.frequency.type === 'quarterly'){
            const startOfQuarter = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1);
            const dueDate = new Date(startOfQuarter.getFullYear(), Math.floor(startOfQuarter.getMonth()) + Math.floor(task.frequency.monthNumber), task.frequency.day);

            const completedThisQuarter = !!task.lastCompleted && task.lastCompleted > startOfQuarter.getTime();

            const lateByDays = completedThisQuarter ? -1 : Math.floor((today.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24));
            const taskState = completedThisQuarter ? 'complete' : (lateByDays > 0) ? 'late' : 'pending';
            return {
                task,
                taskState,
                dueDate
            }
        }
    }
    else{
        const completed = !!task.completionDate;
        const taskState = completed ? 'complete' : 'pending';
        return {
            task,
            taskState,
            dueDate: new Date(task.dueDate)
        }
    }
    return {
        task,
        taskState: 'pending',
        dueDate: new Date()
    }
}

export const elaborateTasks = (tasks: Task[], today : Date) => {
    return tasks.map(task => elaborateTask(task, today));
}

export const convertDBTaskToAddURL = (dbTask: DBTask, additionalDetails : string) => {
    const details = (dbTask.details ? ` - ${dbTask.details}` : '') + (additionalDetails ? ` - ${additionalDetails}` : '');
    let addTaskUrl = `/task/add?name=${encodeURIComponent(dbTask.name)}`;
    addTaskUrl += `&details=${encodeURIComponent(details)}`;
    if (dbTask.type === 'oneOff') {
        addTaskUrl += `&type=oneOff`;
        addTaskUrl += `&dueDate=${encodeURIComponent(dbTask.dueDate)}`;
    }
    if (dbTask.type === 'recurring') {
        addTaskUrl += `&type=recurring`;
        addTaskUrl += `&frequency=${encodeURIComponent(dbTask.frequency.type)}`;

        if (dbTask.frequency.type === 'weekly') {
            addTaskUrl += `&dayOfWeek=${encodeURIComponent(dbTask.frequency.dayOfWeek)}`;
        }
        if (dbTask.frequency.type === 'monthly') {
            addTaskUrl += `&day=${encodeURIComponent(dbTask.frequency.day)}`;
        }
        if (dbTask.frequency.type === 'quarterly') {
            addTaskUrl += `&day=${encodeURIComponent(dbTask.frequency.day)}`;
            addTaskUrl += `&month=${encodeURIComponent(dbTask.frequency.monthNumber)}`;
        }
        if (dbTask.frequency.type === 'yearly') {
            addTaskUrl += `&day=${encodeURIComponent(dbTask.frequency.day)}`;
            addTaskUrl += `&month=${encodeURIComponent(dbTask.frequency.month)}`;
        }
    }


    return addTaskUrl;
}