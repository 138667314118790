import {Button, Form, Spinner} from "react-bootstrap";
import {useState} from "react";
import * as yup from "yup";
import {Formik} from "formik";


export type RegisterProps = {
    register: (email: string, password: string) => Promise<void>
    pathname: string
}

export const Register = ({register, pathname}: RegisterProps) => {

    const [registering, setRegistering] = useState(false);

    const registerUser = async (email: string, password: string) => {
        setRegistering(true);
        await register(email, password);
        setRegistering(false);
    }

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required().min(8),
        rePassword: yup.string().required().min(8).oneOf([yup.ref('password')], 'Passwords must match'),
        agreeToTerms: yup.boolean().oneOf([true], 'You must agree to the terms and conditions')
    });

    return (
        <Formik
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={(values) => {
                registerUser(values.email, values.password);
            }}
            initialValues={{
                email: '',
                password: '',
                rePassword: '',
                agreeToTerms: false
            }}
        >
            {({handleSubmit, handleChange, values, touched, errors, setFieldValue}) => {
                return (
                    <Form noValidate>
                        <Form.Group className="mb-3" controlId="registerEmail">
                            <Form.Label>Email {pathname === '/dental' ? "" : "(only nhs emails supported)"}</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                data-testid="register-email"
                                autoComplete={'email'}
                                value={values.email}
                                onChange={handleChange}
                                isValid={touched.email && !errors.email}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type={'valid'}>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                data-testid="register-password"
                                autoComplete={'new-password'}
                                value={values.password}
                                onChange={handleChange}
                                isValid={touched.password && !errors.password}
                                isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type={'valid'}>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerPasswordRe">
                            <Form.Label>Re-enter Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="rePassword"
                                data-testid="register-password-re"
                                autoComplete={'new-password'}
                                value={values.rePassword}
                                onChange={handleChange}
                                isValid={touched.rePassword && !errors.rePassword}
                                isInvalid={!!errors.rePassword}
                            />
                            <Form.Control.Feedback type={'valid'}>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.rePassword}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerAgreetoterms">
                            <Form.Check
                                type="checkbox"
                                name="agreeToTerms"

                                checked={values.agreeToTerms}
                            >
                                <Form.Check.Input data-testid="register-terms" isInvalid={!!errors.agreeToTerms}
                                                  isValid={!errors.agreeToTerms && values.agreeToTerms === true}
                                                  onChange={(e) =>
                                                      setFieldValue('agreeToTerms', e.target.checked)
                                                  }/>
                                <Form.Check.Label>I agree to the <a
                                    href={'https://www.mypracticemanager.co.uk/terms-and-conditions'} target="_blank"
                                    rel="noopener noreferrer">Terms and Conditions</a></Form.Check.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.agreeToTerms}
                                </Form.Control.Feedback>
                            </Form.Check>

                        </Form.Group>
                        <Button onClick={() => {
                            handleSubmit()
                        }
                        }
                                disabled={registering}
                        >Create Account {registering && <Spinner size={'sm'}/>}</Button>
                    </Form>
                )
            }}
        </Formik>
    );
}