import {Outlet, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";

const uppercaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function GuidesLayout() {
    const path = useLocation().pathname;


    return <div className={'w-100'}>
        <Breadcrumb className={'m-2'}>
            {path.split('/').map((part, index) => {
                if (index === 0) {
                    return <Breadcrumb.Item key={'/'} href={'/'}>Home</Breadcrumb.Item>
                }
                const href = path.split('/').slice(0, index + 1).join('/');
                return <Breadcrumb.Item key={href}
                                        href={href}>{uppercaseFirstLetter(part)}</Breadcrumb.Item>
            })}
        </Breadcrumb>
        <Outlet/>
    </div>;
}