import {Alert, Col, Container, Row, Tab, Tabs, Table} from "react-bootstrap";
import {Login, LoginWithMagicLink} from "./Login";
import {Register} from "./Register";
import './Authenticate.css';
import {useEffect, useState} from "react";
import {LoadableValue} from "../../model/LoadableValue";
import {GetPriceTableRequest, GetPriceTableResponse, PriceTable} from "../../model/RequestTypes";
import {callAPI} from "../../model/API";


const WelcomeBackMessage = () => {
    return <div>
        <h2>Welcome back!</h2>
        <p>Your Tasks are waiting for you</p>
    </div>
}

const PreRegisterMessage = () => {

    const [priceTable, setPriceTable] = useState<LoadableValue<PriceTable>>({type: "loading"});
    const [priceTableFull, setPriceTableFull] = useState<LoadableValue<PriceTable>>({type: "loading"});

    useEffect(() => {
        const f = async () => {
            const response = await callAPI<GetPriceTableRequest, GetPriceTableResponse>({type: 'getPriceTable', pricingType: 'earlyadopter'});
            const fullPriceResponse = await callAPI<GetPriceTableRequest, GetPriceTableResponse>({type: 'getPriceTable', pricingType: 'regular'});
            setPriceTable({type: "loaded", ...response.priceTable});
            setPriceTableFull({type: "loaded", ...fullPriceResponse.priceTable});
        }
        f();
    }, []);


    return <div>
        <h2>What happens after you create an account?</h2>
        <p>You'll be invited to join us as a Early Adopter <span className={'price-table-footnote-label'}>1</span>.</p>
        <p>
            As an early adopter, you'll get a <strong>70% discount</strong> on our regular price, for the first year, to
            say thank you for joining us early.
        </p>

        <p>If you'd like to try our live demo first, please <a href={'/demo'}>click here</a></p>

        <h3>Pricing</h3>
        <p>Transparent pricing base on practice size</p>
        <Table size={'sm'} striped bordered hover className={'text-center'}>
            <thead>
            <tr>
                <th style={{width: "40%"}}>Number of Patients</th>
                <th>Early Adopter Discount Price (per month) <span className={'price-table-footnote-label'}>1,2</span>
                </th>
                <th>Regular Price (per month)</th>
            </tr>
            </thead>
            <tbody>
            {priceTable.type === 'loaded' && priceTable.elements.map((element, i) => {
                const priceTableFullElement = priceTableFull.type === 'loaded' ? priceTableFull.elements.find(e => e.condition === element.condition) : undefined;
                return <tr key={i}>
                    <td>{element.condition}</td>
                    <td className={'price-table-discounted-price'}>{(element.price / 12).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    })}</td>
                    <td className={'price-table-regular-price'}>{priceTableFullElement?.price ? (priceTableFullElement.price / 12).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    }) : ""}</td>
                </tr>
            })}
            {priceTable.type === 'loading' &&
                <>
                    <tr>
                        <td>1 to 3,000</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>3,000 to 6,000</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>6,000 to 9,000</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>9,000 to 12,000</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>12,000 to 15,000</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>15,000 to 18,000</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>18,000 to 21,000</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>21,000+</td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                        <td>
                            <span className={'placeholder-glow'}>
                                <span className='placeholder placeholder-lg w-50 bg-primary'></span></span>
                        </td>
                    </tr>
                </>
            }
            </tbody>
        </Table>
        <p><strong>No hassle cancellation policy</strong> - cancel anytime, and we'll refund your the remaining months.
        </p>
        <p className={'signup-founding-price-cutoff'}>1) early adopter places are limited, but without a fixed end date,
            once filled, we'll open up to the general population at a higher price point
        </p>
        <p className={'signup-founding-price-cutoff'}>2) invoiced annually in advance
        </p>
    </div>
}

const PreRegisterMessageDental = () => {

    return <div>
        <h2>What happens after signup?</h2>
        <p>You'll be invited to join us as a Founding Dental Customer *</p>

        <p>We're looking for customers that want to help My Practice Manager succeed by helping their practice
            succeed</p>
        <h3>Pricing</h3>
        <p>Simple, transparent pricing - <strong>£120 per year</strong>, per practice - paid in advance</p>
        <p><strong>No hassle cancellation policy</strong> - cancel anytime, and we'll refund your the remaining months.</p>
        <p className={'signup-founding-price-cutoff'}>* Founding Dental Customer places are limited.  Once filled, we'll open up to the general population at a higher price point </p>
    </div>
}


export type AuthenticateProps = {
    registerNewUser: (email: string, password: string) => Promise<void>
    loginUser: (email: string, password: string) => Promise<void>
    loginWithMagicLink: (email: string) => Promise<void>
    authError?: string
    resetPassword: (email: string) => Promise<void>
    pathname: string
}


export const Authenticate = ({registerNewUser, loginUser, authError, loginWithMagicLink, resetPassword, pathname}: AuthenticateProps) => {
    const [key, setKey] = useState('register');

    return <Container className={'authentication-panel'} fluid>
        <Row className={'d-flex flex-row flex-grow-1'}>
            <Col xs={12} xl={6} className={'authentication-left'}>
                <Container fluid>
                    <Row>
                        <Col xs={0} lg={0} xl={1} xxl={2}/>
                        <Col xs={12} lg={12} xl={10} xxl={8}>
                            <div className={'ds-panel'}>
                                {authError &&
                                    <Alert variant={"danger"}>
                                        {authError}
                                    </Alert>}

                                <Tabs id="uncontrolled-tab-example" activeKey={key}
                                      onSelect={(k) => setKey(k!)}>
                                    <Tab title={"Create Account"} eventKey={"register"}>
                                        <Register register={registerNewUser} pathname={pathname}/>
                                    </Tab>
                                    <Tab title={"Login"} eventKey={"login"}>
                                        <Login login={loginUser} resetPassword={resetPassword}/>
                                    </Tab>
                                    <Tab title={"Login with Magic Link"} eventKey={"magic-link"}>
                                        <LoginWithMagicLink loginWithMagicLink={loginWithMagicLink} />
                                    </Tab>

                                </Tabs>
                            </div>
                        </Col>
                        <Col xs={0} lg={0} xl={1} xxl={2}/>
                    </Row>
                </Container>


            </Col>
            <Col xs={12} xl={6} className={'authentication-right'}>
                <Container fluid>
                    <Row>
                        <Col xs={0} lg={0} xl={1} xxl={2}/>
                        <Col xs={12} lg={12} xl={10} xxl={8}>
                            <div className={'w-100 d-flex flex-row justify-content-center mb-3'}>
                                <img src={'/logo.png'} className={'authentication-logo'}/>
                            </div>
                            <div className={'ds-panel'}>
                                {key === 'login' ?
                                    //welcome message
                                    <WelcomeBackMessage/>
                                    : (pathname === '/dental' ? <PreRegisterMessageDental/> : <PreRegisterMessage/>)}
                            </div>
                        </Col>
                        <Col xs={0} lg={0} xl={1} xxl={2}/>
                    </Row>
                </Container>

            </Col>
        </Row>

    </Container>
}