import React, {useState} from 'react';
import {Dropdown, ButtonGroup, DropdownButton, DropdownItem} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TaskFilter.css';

export type TaskFilterProps = {
    nameBase: string;
    filters: string[];
    onFilterChange: (selectedFilters: string[]) => void;
}

export const TaskFilterDropdown = ({nameBase, filters, onFilterChange}: TaskFilterProps) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    const handleSelectionChange = (filter: string)=> {
        setSelectedFilters(current => {

            if (current.includes(filter)) {
                const newFilters = current.filter((t) => t !== filter);
                onFilterChange(newFilters);
                return newFilters;
            }
            else{
                const newFilters = [...current, filter]
                if(newFilters.length === filters.length){
                    onFilterChange([])
                    return [];
                }
                else{
                    onFilterChange(newFilters);
                    return newFilters;
                }

            }
        });
    }


    const handleShowAll = () => {
        setSelectedFilters([]);
        onFilterChange([]);
    };

    // limit the name to 20 chars and if it is longer, add '...' at the end
    const limitName = (name: string) => {
        if (name.length > 20) {
            return name.slice(0, 20) + '...';
        }
        return name;
    }

    return (
        <DropdownButton
            title={nameBase + " Filter: " + (selectedFilters.length === 0 ? "Show All" : limitName(selectedFilters.join(', ')))}
            as={ButtonGroup} className={'task-filter'}
            variant={'outline-dark'}
            align={'end'}
        >
            <Dropdown.Item   onClick={handleShowAll} eventKey='showAll' onSelect={handleShowAll}>
                <div className={"task-filter " + (selectedFilters.length ===0 ? "task-filter-selected" : "")}>Show All</div>
            </Dropdown.Item>
            <Dropdown.Divider/>
            {filters.map((filter) => (
                <Dropdown.Item eventKey={filter} key={filter} onClick={() => handleSelectionChange(filter)} >
                    <div className={"task-filter " + (selectedFilters.includes(filter) ? "task-filter-selected" : "")}>{filter}</div>
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};
