import React, {useState} from "react";
import {
    AIDocumentEditor,
    GenerateDocumentProps,
    ModifyExistingDocProps
} from "../components/compliance/AIDocumentEditor";
import {downloadBase64String} from "../components/compliance/fileDownload";
import {Alert} from "react-bootstrap";
import {
    GeneratePDFRequest, GeneratePDFResponse,
    GenerateTemplateDocumentRequest,
    GenerateTemplateDocumentResponse,
    ModifyTemplateDocumentRequest, ModifyTemplateDocumentResponse
} from "../model/RequestTypes";
import {callAPI} from "../model/API";


export type AIComplianceToolPageProps = {
    toolType: string,
    hideNameInput?: boolean
}

export const AIComplianceToolPage = ({toolType, hideNameInput} : AIComplianceToolPageProps) => {
    const [error, setError] = useState<string | undefined>(undefined);


    const generateDocument = async ({documentName, documentTemplate, input} : GenerateDocumentProps) => {
        setError(undefined);
        try {
            const response = await callAPI<GenerateTemplateDocumentRequest, GenerateTemplateDocumentResponse>({
                type: 'generateTemplateDocument',
                templateName: documentTemplate,
                documentName: documentName,
                userInput: input
            });
            return response.documentMD;
        } catch (e: any) {
            setError(e.message);
            return ""
        }
    }

    const modifyDocument = async ({documentName, documentTemplate, input, originalDocument} : ModifyExistingDocProps) => {
        setError(undefined);
        try {
            const response = await callAPI<ModifyTemplateDocumentRequest, ModifyTemplateDocumentResponse>({
                type: 'modifyTemplateDocument',
                templateName: documentTemplate,
                documentName: documentName,
                modificationRequest: input,
                documentMD: originalDocument
            });
           return response.documentMD;
        } catch (e: any) {
            setError(e.message);
            return ""
        }
    }

    const downloadPDF = async (documentMD: string,
                               fileName: string,
                               templateName: string) => {

        setError(undefined);
        try {
            const response = await callAPI<GeneratePDFRequest, GeneratePDFResponse>({
                type: 'generatePDF',
                markdown: documentMD,
                templateName
            });
            downloadBase64String(response.pdfBase64, 'application/pdf', fileName + '.pdf');
        } catch (e: any) {
            setError(e.message);
        }
    }


    return <div className={'w-100 mb-5'}>
        {error &&
            <Alert variant={'danger'}>ERROR: {error}</Alert>
        }
        <AIDocumentEditor generateDocument={generateDocument}
                          downloadPDF={downloadPDF}
                          templateName={toolType}
                          modifyDocument={modifyDocument}
                          hideNameInput={hideNameInput}
        />
    </div>
}