import { Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {cqcAuditTemplate} from "./AuditTemplate";
import React from "react";

type CQCAreComingPageComponentProps = {
    subjects: string[]
}


const CQCAreComingPageComponent = ({subjects} : CQCAreComingPageComponentProps ) => {

    return <Container className={'mt-3 mb-5'}>
        <Row>
            <Col>
                <div className={'ds-panel'}>
                    <h1 className={'ds-panel-header'}>The CQCs are coming</h1>
                    <p>The following plan should help you get prepared:</p>
                    <ol>
                        <li>Go through each of the Areas below and add tasks.  Don't spend a lot of time here, you're just trying to document everything you need to review</li>
                        <li>Remember to delegate and assign tasks as you go, so you reduce the amount you personally have to review.</li>
                        <li>Go to the <Link
                            to={'/task/dashboard'}>task manager</Link> and use the filters in the top right of the screen to filter for each section (eg "CQC - General")</li>
                        <li>Complete each task, and mark as complete when done</li>
                        <li><Link to={'/compliance'}>Compliance tools</Link> are available to help with risk assessments and policies</li>
                    </ol>
                    <div className={'mt-3'}>
                        {subjects.map((sectionName) => {
                            return <Row className={'mt-2'}>
                                <Col xs={0} xl={3}></Col>
                                <Col xs={12} xl={6}>
                                    <Link to={`./${sectionName}`} className={'w-100 align-self-center btn btn-primary'}>Review {sectionName}</Link>
                                </Col>
                                <Col xs={0} xl={3}></Col>
                            </Row>
                        })}

                    </div>

                </div>
            </Col>
        </Row>

    </Container>
}


export const CQCAreComingPage = () => {

    const subjects = cqcAuditTemplate.subjects.map(s => s.name);

    return <CQCAreComingPageComponent subjects={subjects} ></CQCAreComingPageComponent>
}
